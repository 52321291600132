import React, {useEffect, useRef} from "react";
import restButton from "../../icons/ResetArrowsGray.svg";
import DisplayTooltip from "../../client-data/DisplayTooltip";
import {OverlayTrigger, Tooltip} from "react-bootstrap";

const PLACEMENT_OF_TOOLTIP = "bottom";
const TOOLTIP_TXT = "resetview";

const ResetToInitialInfo = (props) => {
    const aRef = useRef(null);
    const [showTooltip, setShowTooltip] = React.useState(false);

    const onClickHandler = () => {
        props.onClick();
    }

    useEffect(() => {
        const timeoutID = setTimeout(() => {setShowTooltip(false)}, 1000);

        return () => {
            clearTimeout(timeoutID);
        }
    }, [showTooltip])

    return (
        <OverlayTrigger
            // delay={{show: 150, hide: 180}}
            show={showTooltip}
            overlay={<DisplayTooltip ref={aRef} tooltip_type={TOOLTIP_TXT}/>}
            placement={PLACEMENT_OF_TOOLTIP}
        >
            <button className="three-d-reset-icon-button left-flex-box" onClick={onClickHandler}
                    onMouseEnter={()=> setShowTooltip(true)}
            >
                <img src={restButton} className="three-d-reset-icon"/>
            </button>
        </OverlayTrigger>
    )

}

export default ResetToInitialInfo;

// <button className="three-d-icon-button" onClick={onClickHandler}>