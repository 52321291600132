import {ON_SLIDER_UPDATE_RENDERER, SLIDER_RADIUS} from "./MultiViewTypes";
import {MultiViewSingleScene} from "./MultiViewSingleScene";
import * as THREE from "three"

export class MultiViewSlider extends THREE.EventDispatcher {

    private sliderPos = 0;

    constructor(private _slider: HTMLElement, private mainContainer: HTMLElement, private useForMouse: HTMLElement, private modalBody: HTMLElement, private container3dModal: HTMLElement, private sceneRight: MultiViewSingleScene, private sceneLeft: MultiViewSingleScene) {

        super();

        const containerSie = mainContainer.getBoundingClientRect();
        this.sliderPos = ((containerSie.width) / 2) - SLIDER_RADIUS;


       this.slider.style.touchAction = 'none'; // disable touch scroll
        this.slider.addEventListener('pointerdown', this.onPointerDown);
    }

    get containerSize(): DOMRect {
        return this.mainContainer.getBoundingClientRect();
    }

    get modalBodySize(): DOMRect {
        return this.modalBody.getBoundingClientRect();
    }

    onPointerDown = (e: MouseEvent) => {

        console.log("Onpointer down 1")

        console.log("Onpointer down 2 pageX", e.pageX, "slider rect=", this.slider.getBoundingClientRect())

        this.sceneRight.orbitControls!.enabled = false;
        this.sceneLeft.orbitControls!.enabled = false;


        this.useForMouse.addEventListener("pointermove", this.onPointerMove);
        this.useForMouse.addEventListener('pointerup', this.onPointerUp);
    }

    onPointerUp = (e: MouseEvent) => {

        console.log("Onpointer up")

        this.sceneRight.orbitControls!.enabled = true;
        this.sceneLeft.orbitControls!.enabled = true;

        // // @ts-ignore
        this.useForMouse.removeEventListener('pointermove', this.onPointerMove);
        this.useForMouse.removeEventListener('pointerup', this.onPointerUp);


        this.dispatchEvent({type: ON_SLIDER_UPDATE_RENDERER, render: false});

    }
    onPointerMove = (e: MouseEvent) => {

        // console.log("Onpointer move")

        this.sliderPos = Math.max(this.containerSize.x + 50, Math.min(this.containerSize.width + this.containerSize.x - SLIDER_RADIUS - 50, e.pageX)) - this.modalBodySize.x;
        ;
        this.slider.style.left = this.sliderPos + 'px';

        const sliderPer = ((this.sliderPos + SLIDER_RADIUS + this.modalBodySize.x - this.containerSize.x) * 100) / this.containerSize.width;

        // console.log("slider pos", this.sliderPos, "- width=", this.containerSize.width, "- pagex=", e.pageX,"- clientx=", e.clientX, "sliderPer=", sliderPer)
        // console.log("continer size:", this.containerSize, "slider rect=",this.slider.getBoundingClientRect());

        this.container3dModal.style.gridTemplateColumns = `${sliderPer}% ${(100 - sliderPer)}%`;
        this.sceneRight.onWindowResize();
        // this.sceneRight.offsetX = this.slider.getBoundingClientRect().x - this.containerSize.x +  SLIDER_RADIUS ;
        this.resetScreenRightOffset();
        this.sceneLeft.onWindowResize();
        this.dispatchEvent({type: ON_SLIDER_UPDATE_RENDERER, render: true});
    }

    resetScreenRightOffset = () => {
        this.sceneRight.offsetX = this.slider.getBoundingClientRect().x - this.containerSize.x + SLIDER_RADIUS;

        //this.slider.style.left = (this.sceneLeft.size.right - SLIDER_RADIUS) + 'px';
    }

    get slider(): HTMLElement {
        return this._slider;
    }

    dispose = () => {
        this.slider.removeEventListener('mousedown', this.onPointerDown);
        // @ts-ignore
        this.sceneLeft = null;

        // @ts-ignore
        this.sceneRight = null;
    }

    set backgroundColor(color: string) {
        this.slider.style.backgroundColor = color;
    }

    set opacity(value: number) {
        this.slider.style.opacity = value.toString();
    }
}
