import React, {useEffect, useState} from "react";

export const ThreeDAudioPlayer = (props) => {
    return (
        <>
            {
                props.audioInfo
                    ?
                    <audio className="three-d-audio" controls src={props.audioInfo}>
                        Your Browser doesn't support this audio tag.
                    </audio>
                    :
                    null
            }
        </>

    )
}