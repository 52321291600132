import * as THREE from "three";
import {CameraNavMeshHandler} from "../../components/three-gltf-loader/ts/CameraNavMeshHandler";
import {ColliderXContainer} from "../../components/colliders/ts/ColliderXContainer";
import {ColliderX} from "../../components/colliders/ts/ColliderX";
import CameraLocationsDataController from "../../components/zones/CameraLocationsDataController";

const SHOW_WIRE_FRAME = false;

enum COLLIDER_TYPE {
    top,
    bottom,
    none
}

export class NewcastleStairsCollider {

    private readonly _colliderXContainer: ColliderXContainer;
    //bottomStairsCollider
    //topStairsCollider
     private topCameraLocation: THREE.Object3D;
     private bottomCameraLocation: THREE.Object3D;
    private previousCollider = COLLIDER_TYPE.none;
    private ignoreColliderEnter = false;

    constructor(
        private scene: THREE.Scene,
        private camera: THREE.Camera,
        private colliders: Array<THREE.Object3D>,
        private cameraNavMeshHandler: CameraNavMeshHandler,
    ) {

        const colliderXs: Array<ColliderX> = [];
        this.colliders.forEach((collider) => {

            colliderXs.push(new ColliderX(collider, SHOW_WIRE_FRAME));
        })

        this._colliderXContainer = new ColliderXContainer(this.camera, new THREE.Vector3(1, 1, 1), colliderXs, this.colliderEnter, this.colliderStay, this.colliderExit, SHOW_WIRE_FRAME);


        const topCameraLocationRec = CameraLocationsDataController.populateCameraLocationRecordById("topStairsCameraLocation_id");
        const bottomCameraLocationRec = CameraLocationsDataController.populateCameraLocationRecordById("bottomStairsCameraLocation_id");

        console.log("NewcastleStairsCollider rec top=", topCameraLocationRec);
        console.log("NewcastleStairsCollider rec bottom=", bottomCameraLocationRec);

        const topCameraLocation_id = topCameraLocationRec!.ObjectId as number;
        const bottomCameraLocation_id = bottomCameraLocationRec!.ObjectId as number;

        console.log("NewcastleStairsCollider objectId top=", topCameraLocation_id);
        console.log("NewcastleStairsCollider ObjectId bottom=", bottomCameraLocation_id);


         this.topCameraLocation = this.scene.getObjectById(topCameraLocation_id)!
         this.bottomCameraLocation = this.scene.getObjectById(bottomCameraLocation_id)!

          console.log("NewcastleStairsCollider top=",this.topCameraLocation);
          console.log("NewcastleStairsCollider bottom=",this.bottomCameraLocation);
    }

    colliderEnter = (collider: ColliderX): void => {
        console.log("colliderEnter-", collider)
        if (this.ignoreColliderEnter) {
            console.log("enter collider ignored - ",collider.colliderName);
            return;
        }
        if (collider.colliderName === "topStairsCollider") {
            this.previousCollider = COLLIDER_TYPE.top;
            this.ignoreColliderEnter = true;
            this.cameraNavMeshHandler.navigateToTargetPos(this.bottomCameraLocation);
            // this.cameraNavMeshHandler.navigateToNanMeshPoint(new THREE.Vector3(-12.3588,0.025,-2.30706));
            // this.cameraNavMeshHandler.navigateToNanMeshPoint(new THREE.Vector3(-12.3588,0.5,-2.30706));
        }
        else {
            this.previousCollider = COLLIDER_TYPE.bottom;
            this.ignoreColliderEnter = true;
            this.cameraNavMeshHandler.navigateToTargetPos(this.topCameraLocation);
            // this.cameraNavMeshHandler.navigateToNanMeshPoint(new THREE.Vector3(-14.82,3.65293,-1.1047));
            // this.cameraNavMeshHandler.navigateToNanMeshPoint(new THREE.Vector3(-14.82,4,-1.1047));
        }





    }

    colliderStay = (collider: ColliderX): void => {
        // console.log("colliderStay -",collider.colliderName)
    }

    colliderExit = (collider: ColliderX): void => {

        console.log("colliderExit-", collider)

        if (!this.ignoreColliderEnter) {
            console.log("exit collider ignored - ",collider.colliderName);
            return;
        }

        if (collider.colliderName === "topStairsCollider" && this.previousCollider === COLLIDER_TYPE.bottom ) {
            this.previousCollider = COLLIDER_TYPE.none;
            this.ignoreColliderEnter = false;
        }
        else if (collider.colliderName === "bottomStairsCollider" && this.previousCollider === COLLIDER_TYPE.top) {

            this.previousCollider = COLLIDER_TYPE.none;
            this.ignoreColliderEnter = false;

        }

      /*  if (collider.colliderName === 'door_closer') {

        } else if (collider.colliderName === "video_play") {

        }*/

    }

    get colliderXContainer(): ColliderXContainer {
        return this._colliderXContainer;
    }
}
