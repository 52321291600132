import React, {Component} from 'react';
import './App.css';
import ThreeCubeSample from "./components/three-cube-sample"
import ThreeBuildingSample from "./components/three-building-sample"
import ThreeGLTFLoader from "./components/three-gltf-loader"
import CircleJoystick from './components/joystick/CircleJoystick'
import EnterAppModal from "./components/modals/EnterAppModal";
import AnnotationPage from "./components/annotations/AnnotationPage";
import GalleryEntry from "./components/Main/GalleryEntry";
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {thisIsMobile} from "./client-data/GlobalConstants";

import ReactGa from 'react-ga4';
import BSStreamPage from "./externalPages/BSStreamPage";



class App extends Component {
    constructor(props) {
        super(props);
        this.isMobile();
        this.state = {
            enterApp: false
        }

        // This code adds Google Analytics Module to our app
        ReactGa.initialize('G-PXWE4N0HEL');
        ReactGa.send("pageview");
    }

    isMobile() {

        window.isMobile = thisIsMobile;

    }

    enterApp = (evt) => {
        this.setState({
            enterApp: true
        })
    }

    render() {

        return (
            <main>
                    <Routes>
                        <Route path="/" element={<GalleryEntry />}/>
                        <Route path="BSStreamPage/:id" element={<BSStreamPage />}/>
                    </Routes>
            </main>
        )
    }
}

export default App;
