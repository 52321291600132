import React from "react";
import {Toast} from "react-bootstrap"
import {isMobile, isTablet, isMobileOnly} from "react-device-detect";

const ToastOverlayWithBtn = (props) => {
    const [doesItShow, setDoesItShow] = React.useState(false);

    // console.log("[ToastOverlayWithBtn] is called!", doesItShow);

    return (
        <>
            {isMobileOnly
                ?
                <Toast show={props.show} className="three-d-toast">
                    <Toast.Body><div className="three-d-toast-text" >{props.text}</div></Toast.Body>
                </Toast>
                :
                null
            }
        </>

    )
}

export default ToastOverlayWithBtn;