import AnnotationContainer from '../../annotations/AnnotationContainer'
import VideoContainer from '../../annotations/VideoContainer'
import CircleJoystick from '../../joystick/CircleJoystick'
import {MeshContainer} from "../../annotations/ts/MeshContainer";
import {CameraNavMeshHandler} from "../../three-gltf-loader/ts/CameraNavMeshHandler";
import {VideoMeshButtonContainer} from "../../annotations/ts/VideoMeshButtonContainer";
import FPSNavigationControl from "../fps-navigation-control";
import {MeshButtonContainer} from "../../annotations/ts/MeshButtonContainer";
import * as THREE from "three";
import {ANNOTATION_LAYER_CHANEL} from "../../../client-data/GlobalConstants";

enum MouseClickTarget {
    NONE,
    Annotation,
    Video,
    Joystick,
    FPSNavigation,
    PaintingMesh,
    NAVMESH,
    VideoMeshBtn,
    MeshButton
}

class NavigationManager {

    private mouseTarget: MouseClickTarget = MouseClickTarget.NONE;
    private _isLocked: boolean = false;
    private readonly isTouchScreen: boolean;
    private readonly hammerManager?: HammerManager;
    private  _raycaster = new THREE.Raycaster();

    constructor(private annotationContainer: AnnotationContainer,
                private videoContainer: VideoContainer,
                private meshContainer: MeshContainer,
                private joystick: CircleJoystick,
                private cameraNavMeshHandler: CameraNavMeshHandler,
                private videoMeshButtonContainer: VideoMeshButtonContainer,
                private fpsController: FPSNavigationControl,
                private meshButtonContainer: MeshButtonContainer,
                private scene: THREE.Scene,
                private camera: THREE.Camera
    ) {
        window.addEventListener("mousedown", this.onDocumentMouseDown, {passive: false});
        window.addEventListener("dblclick", this.onDocumentMouseDbClick, {passive: false});
        window.addEventListener("mousemove", this.onDocumentMouseMove, {passive: false});

        this.isTouchScreen = 'ontouchstart' in window;

        if (this.isTouchScreen) {
            this.hammerManager = new Hammer.Manager(document);
            this.hammerManager.add(new Hammer.Tap({event: 'doubletap', taps: 2}));
            this.hammerManager.add(new Hammer.Tap({event: 'singletap'}));
            this.hammerManager.get('doubletap').recognizeWith('singletap');
            this.hammerManager.get('singletap').requireFailure('doubletap');

            this.hammerManager.on("doubletap", (event => {
                this.onDocumentMouseDbClick(<MouseEvent>event.srcEvent);
            }));
        }
    }


    onDocumentMouseMove = (evt: MouseEvent) => {
        const intersect = this.getIntersects(evt.x, evt.y);
        if (intersect && intersect.length > 0) {
            let intersectObj = intersect[0];
            if (!intersectObj.object.visible) {
                if (intersect.length > 1) {
                    intersectObj = intersect[1];
                }

            }
            const resVideoMeshBtn = this.videoMeshButtonContainer.onDocumentMouseMove(intersectObj);
            if (resVideoMeshBtn.callBack) {
                resVideoMeshBtn.callBack();
            }
            const resAnnotation = this.annotationContainer.onDocumentMouseMove(intersectObj);
            if (resAnnotation.callBack) {
                resAnnotation.callBack();
            }
            const resMeshButt = this.meshButtonContainer.onDocumentMouseMove(intersectObj);
            if (resMeshButt.callBack) {
                resMeshButt.callBack();
            }

        }

    }


    onDocumentMouseDbClick = (evt: MouseEvent) => {
        if (this.isLocked) {
            return;
        }
        else {
            const intersect = this.getIntersects(evt.x, evt.y);

            if (intersect && intersect.length > 0) {
                console.log("onDocumentMouseDbClick intersects=", intersect)
                console.log("onDocumentMouseDbClick navmeshId=", this.cameraNavMeshHandler.navmeshId)
                let intersectObj = intersect[0];
                if (!intersectObj.object.visible && intersectObj.object.id !== this.cameraNavMeshHandler.navmeshId) {
                    if (intersectObj.object.userData.painting_id) { //for paintings placeholders, because they are not visible, but they contain the painting_id
                        intersectObj = intersect[0];
                    }
                    else {
                        if (intersect.length > 1) {
                            intersectObj = intersect[1];
                        } else {
                            return;
                        }
                    }

                }

                const navMeshHitIntersect = this.cameraNavMeshHandler.onMouseDbClick(intersectObj);

                if (navMeshHitIntersect) {

                    this.cameraNavMeshHandler.navigateToNanMeshPoint(intersectObj.point);
                } else {
                    const meshContainerIntersect = this.meshContainer.onDocumentMouseDBClick(intersectObj);
                    if (meshContainerIntersect) {
                        this.meshContainer.meshSelected();
                    }
                }
            }







           /* if (navMeshHitPoint) {
                if (!this.isLocked) {
                    this.mouseTarget = MouseClickTarget.NAVMESH;
                    this.cameraNavMeshHandler.navigateToNanMeshPoint(navMeshHitPoint);

                }
            }
            else {

                if (this.meshContainer.onDocumentMouseDBClick(evt)) {
                    if (!this.isLocked) {
                        this.mouseTarget = MouseClickTarget.PaintingMesh;
                    }
                }
            }*/
        }
    }

    onDocumentMouseDown = (evt: MouseEvent) => {

        if (this.isLocked) {
            return;
        }

        const intersect = this.getIntersects(evt.x, evt.y);

        if (intersect && intersect.length > 0) {
            let intersectObj = intersect[0];
            if (!intersectObj.object.visible) {
                if (intersect.length > 1) {
                    intersectObj = intersect[1];
                }
                else {
                    return;
                }

            }

            const res = this.videoMeshButtonContainer.onDocumentMouseDown(intersectObj);
            if (res.result) {
                if (res.callBack) {
                    res.callBack();
                }
                return;
            }
            const resAnnotation = this.annotationContainer.onDocumentMouseDown(intersectObj);
            if (resAnnotation.result) {
                if (!this.isLocked) {  //in case onDocumentMouseDown set the _isLocked to true or false before it returns
                    this.mouseTarget = MouseClickTarget.Annotation;
                    this.lockNavigations();
                }
                if (resAnnotation.callBack) {
                    resAnnotation.callBack();
                }
                return;
            }

            const resMeshButt = this.meshButtonContainer.onDocumentMouseDown(intersectObj);
            if (resMeshButt.result) {
                if (resMeshButt.callBack) {
                    resMeshButt.callBack();
                }
                return;
            }


        }

       /* if (this.annotationContainer.onDocumentMouseDown(evt)) {
            if (!this.isLocked) {  //in case onDocumentMouseDown set the _isLocked to true or false before it returns
                this.mouseTarget = MouseClickTarget.Annotation;
                this.lockNavigations();
            }

        } else*/
        if (this.videoContainer.onDocumentMouseMove(evt)) {
            if (!this.isLocked) {
                this.mouseTarget = MouseClickTarget.Video;
                this.lockUnlockWithDelay();

            }
       /* } else if (this.videoMeshButtonContainer.onDocumentMouseDown(evt)) {
            if (!this.isLocked) {
                this.mouseTarget = MouseClickTarget.VideoMeshBtn;
                this.lockUnlockWithDelay();
            }*/
        } /*else if (this.meshButtonContainer.onDocumentMouseDown(evt)) {
            if (!this.isLocked) {
                this.mouseTarget = MouseClickTarget.MeshButton;
                this.lockUnlockWithDelay();
            }
        }*/
    }

    public blockEvents = () => {
        this._isLocked = true;
        this.lockNavigations();
    }

    public unBlockEvents = () => {
        this._isLocked = false;
        this.unlockNavigations();
    }

    get isLocked() {
        return this._isLocked;
    }

    lockNavigations = () => {
        this.joystick.resetMouseThumb();
        this.fpsController.lockByNavigationManager();
    }

    unlockNavigations = () => {
        this.fpsController.unlockByNavigationManager();
    }

    lockUnlockWithDelay = (delay: number = 1000) => {
        this.lockNavigations();
        setTimeout(args => {
            this.unlockNavigations();
        }, delay);
    }

    getIntersects = (x: number, y: number) => {


        const mouseVector = new THREE.Vector3();
        this._raycaster.layers.enable(ANNOTATION_LAYER_CHANEL);


        x = (x / window.innerWidth) * 2 - 1;
        y = -(y / window.innerHeight) * 2 + 1;

        mouseVector.set(x, y, 0.5);
        this._raycaster.setFromCamera(mouseVector, this.camera);

        return this._raycaster.intersectObject(this.scene, true);

    }
}

export default NavigationManager;
