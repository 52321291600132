import * as THREE from "three";


export class MeshObjectContainer {

    private meshesObjDict: Record<string, THREE.Mesh> = {};

    constructor(private meshObjects: Array<THREE.Mesh>) {
        meshObjects.forEach( mesh => {
            this.meshesObjDict[mesh.userData.meshObject_id] = mesh;
        })
    }

    getMesh = (meshObject_id: string):THREE.Mesh | null => {
        const meshObj = this.meshesObjDict[meshObject_id];
        if (meshObj) {
            return meshObj;
        }
        return null;

    }

}
