import * as THREE from "three";
import {MaterialPropsType} from "../../components/Viewer3DModal/types/ViewerTypes";

export const MODAL3D_MATERIAL_PROPS_DB: Array<MaterialPropsType> = [
    {
        materialName: "GlassMat",
        glass: true,
        glassMatFunc: (envMap) => {
            return new THREE.MeshPhysicalMaterial({
                name: `GlassMat-MPM`,
                color: new THREE.Color(0xffffff),
                emissive: new THREE.Color(0.20969663085937498, 0.21999999999999997, 0.21999999999999997),
                metalness: .01,
                roughness: 0,
                // alphaMap: texture,
                // alphaTest: 0.5,
                //envMap: envMap, //this.backgroundMap,
                envMapIntensity: .572,
                //depthWrite: false,
                transmission: 1, // use material.transmission for glass materials
                opacity: .34, // set material.opacity to 1 when material.transmission is non-zero
                transparent: true,
                //ior: 1.5,
                //specularIntensity: 1,
                //specularColor: 0xffffff,
                //  side: THREE.DoubleSide,
                //alphaMap: texture,
                toneMapped: false,
            });
        },
    },
    {
        materialName: "WallLightGlass",
        glass: true,
        glassMatFunc: (envMap) => {
            return new THREE.MeshPhysicalMaterial({
                name: `WallLightGlass-MPM`,
                color: new THREE.Color(0xffffff),
                //emissive :new THREE.Color( 0.20969663085937498, 0.21999999999999997, 0.21999999999999997 ),
                metalness: .001,
                roughness: 0,
                // alphaMap: texture,
                // alphaTest: 0.5,
                //envMap: envMap, //this.backgroundMap,
                envMapIntensity: 7,
                //depthWrite: false,
                transmission: 1, // use material.transmission for glass materials
                opacity: 0.5, // set material.opacity to 1 when material.transmission is non-zero
                transparent: true,
                //ior: 1.5,
                //specularIntensity: 1,
                //specularColor: 0xffffff,
                //  side: THREE.DoubleSide,
                //alphaMap: texture,
                toneMapped: false,
            });
        },
    },


   /* {
        materialName:"byronbust_thorvaldsen_murrays_mtl",
        aOIntensity:0.9823465059092082,

       // envMapIntensity:.2,

    },*/

    {
        materialName:"byronbust_thorvaldsen_murrays_mtl",
        toneMapped:'N',

    },
    {
        materialName:"byronbust_bartolini_ly_mtl",
        toneMapped:'N',
    },
    {
        materialName:"bartolini_academia_mtl",
        toneMapped:'N',
    },
    {
        materialName:"byronbust_bartolini_npg_mtl",
        toneMapped:'N',
    },
    {
        materialName:"thorvaldsen_keats_shelley_mtl",
        toneMapped:'N',
    },


]
