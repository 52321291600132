import React, {Component} from "react";
import {Col, Row} from "react-bootstrap";


class ZoneLinksSubLevel extends Component {

    render() {
        let zoneLinksSubLevelComponents = null;
        // console.log("[ZoneLinksSubLevel]", this.props.zoneCameraSubLevelLocations);
        if (this.props.zoneCameraSubLevelLocations && this.props.zoneCameraSubLevelLocations.length > 0) {

                    zoneLinksSubLevelComponents = this.props.zoneCameraSubLevelLocations.map((subLevelObj) => {
                        return (

                                <Col key={"col_" + subLevelObj.cameraLocationId.toString()} xs={12}>
                                    <a key={"a_" + subLevelObj.cameraLocationId.toString()}
                                       // style={{color: "black"}}
                                       className="programme-hyperlink"
                                       href="#"
                                       onClick={() => this.props.onClick(subLevelObj)}
                                    >
                                        {subLevelObj.locationName}
                                    </a>
                                </Col>

                            )
                })

        return (
            <Col>
                <Row>
                    {zoneLinksSubLevelComponents}
                </Row>
            </Col>

        )


            // console.log("[ZoneLinksSubLevel] inside if...", this.props.zoneCameraSubLevelLocations);
            // return (
            //     // <Row key={"row_" + this.props.zoneCameraSubLevelLocations.cameraLocationId.toString()}>
            //     <Row>
            //         {/*{zoneLinksSubLevelComponents}*/}
            //         <Col>
            //             Test
            //         </Col>
            //     </Row>


        }
        return null;

    }
}

export default ZoneLinksSubLevel;