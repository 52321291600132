import {MeshButtonContainer} from "../../components/annotations/ts/MeshButtonContainer";
import {ColliderTriggerContainer} from "../../components/colliders/ts/ColliderTriggerContainer";
import VideoContainer from "../../components/annotations/VideoContainer";
import VideoDisplay from "../../components/annotations/VideoDisplay";
import {ColliderTriggerBase} from "../../components/colliders/ts/ColliderTriggerBase";
import * as THREE from 'three';
import {RenderingValidationSingleton} from "../../components/Rendering/ts/RenderingValidationSingleton";
import {CameraNavMeshHandler} from "../../components/three-gltf-loader/ts/CameraNavMeshHandler";
import {MeshButtonBase} from "../../components/annotations/ts/MeshButtonBase";
import CameraLocationsDataController from "../../components/zones/CameraLocationsDataController";
import {MeshObjectContainer} from "../../components/annotations/ts/MeshObjectContainer";
import * as TWEEN from "@tweenjs/tween.js";

//INT_INT_V150
//GEN_GEN_V213
//CollI_NT_INT_V150
//CollI_GEN_GEN_V213
//ciniplexDoorButton
//cineplexDoor
//CollI_ANTOINE
//TAN_TAN_V316




enum PLAYER_POSITION {
    OutsideTrigger,
    InTrigger
}

enum DOOR_STATUS {
    OPEN,
    Opening,
    CLOSED
}

export class ECCBProcessor {

    private INT_INT_V150: VideoDisplay;
    private GEN_GEN_V213: VideoDisplay;
    private TAN_TAN_V316: VideoDisplay;

    private CollI_NT_INT_V150: ColliderTriggerBase;
    private CollI_GEN_GEN_V213: ColliderTriggerBase;
    //private CollI_ANTOINE: ColliderTriggerBase;

    private meshButton_StairsUpButton01: MeshButtonBase;
    private meshButton_StairsDownButton01: MeshButtonBase;
    private meshButton_ciniplexDoorButton: MeshButtonBase;

    private cineplexDoor: THREE.Mesh | null;

    private closedCiniplexDoorPo: THREE.Vector3;

    private doorStatus = DOOR_STATUS.CLOSED;

    constructor(  private scene:THREE.Scene, private meshButtonContainer: MeshButtonContainer, private cameraNavMeshHandler: CameraNavMeshHandler, private videoContainer: VideoContainer,
                 private colliderContainer: ColliderTriggerContainer,  private meshObjectContainer: MeshObjectContainer,private animatedMeshArray: Array<THREE.Object3D>, externalLoop: Array<(dt:number) => void>  ) {

        this.INT_INT_V150 = this.videoContainer.getVideoDisplay("INT_INT_V150");
        this.GEN_GEN_V213 = this.videoContainer.getVideoDisplay("GEN_GEN_V213");
        this.TAN_TAN_V316 = this.videoContainer.getVideoDisplay("TAN_TAN_V316");


        const collI_NT_INT_V150 = this.colliderContainer.getColliderXById("CollI_NT_INT_V150");
        if (collI_NT_INT_V150) {

            this.CollI_NT_INT_V150 = new ColliderTriggerBase(collI_NT_INT_V150, this.colliderEnter, this.colliderStay, this.colliderExit, false,true);
            this.colliderContainer.add(this.CollI_NT_INT_V150);
        }
        else {
            throw new Error('Cannot find collI_NT_INT_V150');

        }

        const collI_GEN_GEN_V213 = this.colliderContainer.getColliderXById("CollI_GEN_GEN_V213");
        if (collI_GEN_GEN_V213) {

            this.CollI_GEN_GEN_V213 = new ColliderTriggerBase(collI_GEN_GEN_V213, this.colliderEnter, this.colliderStay, this.colliderExit, false,true);
            this.colliderContainer.add(this.CollI_GEN_GEN_V213);
        }
        else {
            throw new Error('Cannot find collI_GEN_GEN_V213');

        }

      /*  const collI_ANTOINE = this.colliderContainer.getColliderXById("CollI_ANTOINE");
        if (collI_ANTOINE) {

            this.CollI_ANTOINE = new ColliderTriggerBase(collI_ANTOINE, this.colliderEnter, this.colliderStay, this.colliderExit, false,true);
            this.colliderContainer.add(this.CollI_ANTOINE);
        }
        else {
            throw new Error('Cannot find CollI_ANTOINE');

        }*/

        if (this.animatedMeshArray[0]) {
            externalLoop.push(this.externalLoopFunc);
            RenderingValidationSingleton.getInstance().incVideoRenderingFlag(this.animatedMeshArray[0] as THREE.Mesh)
        }

        this.meshButton_StairsUpButton01 = this.meshButtonContainer.getMeshButton("StairsUpButton01")!;
        this.meshButton_StairsUpButton01.onClickCallback = this.buttonClicked_StairsUpButton01;

        this.meshButton_StairsDownButton01 = this.meshButtonContainer.getMeshButton("StairsDownButton01")!;
        this.meshButton_StairsDownButton01.onClickCallback = this.buttonClicked_StairsDownButton01;

        this.meshButton_ciniplexDoorButton = this.meshButtonContainer.getMeshButton("ciniplexDoorButton")!;
        this.meshButton_ciniplexDoorButton.onClickCallback = this.buttonClicked_ciniplexDoorButton;

        this.cineplexDoor = this.meshObjectContainer.getMesh("cineplexDoor");
        if (!this.cineplexDoor) {
            throw new Error('Cannot find cineplexDoor');
        }
        this.cineplexDoor!.matrixAutoUpdate = true;
        this.cineplexDoor!.updateMatrix();
        this.closedCiniplexDoorPo = this.cineplexDoor.position.clone();
    }

    externalLoopFunc = (dt: number) => {

           // console.log("Rotate 3d Text");
            this.animatedMeshArray[0].rotateY(dt * 0.25);

    }

    private colliderEnter = (colliderTrigger: ColliderTriggerBase) => {

        console.log("Collider enter-", colliderTrigger);

        if (colliderTrigger.colliderName === 'CollI_NT_INT_V150') {
            this.playBackgroundAudio(this.INT_INT_V150);
        }
        else {
            if (colliderTrigger.colliderName === 'CollI_GEN_GEN_V213') {
                this.playBackgroundAudio(this.GEN_GEN_V213);
            }
            else {
                if (colliderTrigger.colliderName === 'CollI_ANTOINE') {
                    this.playBackgroundAudio(this.TAN_TAN_V316);
                }
            }
        }


    }

    private colliderStay = (colliderTrigger: ColliderTriggerBase) => {

        //console.log("Collider stay-", colliderTrigger);

    }

    private colliderExit = (colliderTrigger: ColliderTriggerBase) => {

         console.log("Collider exit-", colliderTrigger);

        if (colliderTrigger.colliderName === 'CollI_NT_INT_V150') {
            this.pauseAudio(this.INT_INT_V150);
        }
        else {
            if (colliderTrigger.colliderName === 'CollI_GEN_GEN_V213') {
                this.pauseAudio(this.GEN_GEN_V213);
            }
            else {
                if (colliderTrigger.colliderName === 'CollI_ANTOINE') {
                    this.pauseAudio(this.TAN_TAN_V316);
                }
            }
        }

    }

    playBackgroundAudio =(video: VideoDisplay) => {
        //need to add the settimeout so the audio has time to pause or play

            if (!video.isPlaying) {
                video.playVideo();

            }
            else {
                setTimeout(() => {
                    video.playVideo();
                }, 1000);
            }
    }

    pauseAudio = (video: VideoDisplay)=> {
        if (video.isPlaying) {
            video.PauseVideo();
        }
    }

    buttonClicked_StairsUpButton01 = (buttonId: string): void => {

        const cameraLocationRec = CameraLocationsDataController.populateCameraLocationRecordById("UpStairsLoc");
        if (cameraLocationRec && cameraLocationRec.ObjectId ) {
            const meshToNavigate = this.scene.getObjectById(cameraLocationRec.ObjectId);
            // this.cameraNavMeshHandler.navigateToNanMeshPoint(new THREE.Vector3(-5.01957 , 4, -16), () => {
            this.cameraNavMeshHandler.navigateToTargetPos(meshToNavigate!, false, () => {

            });

        }
    }

    buttonClicked_StairsDownButton01 = (buttonId: string): void => {

        const cameraLocationRec = CameraLocationsDataController.populateCameraLocationRecordById("DownStairsLoc");
        if (cameraLocationRec && cameraLocationRec.ObjectId ) {
            const meshToNavigate = this.scene.getObjectById(cameraLocationRec.ObjectId);
            // this.cameraNavMeshHandler.navigateToNanMeshPoint(new THREE.Vector3(-5.01957 , 4, -16), () => {
            this.cameraNavMeshHandler.navigateToTargetPos(meshToNavigate!, false, () => {

            });

        }
    }

    private buttonClicked_ciniplexDoorButton= (buttonId: string): void => {

        if (this.doorStatus === DOOR_STATUS.Opening) return;
        const self = this;
        if (this.doorStatus === DOOR_STATUS.CLOSED) {
            const openPos = new THREE.Vector3(this.closedCiniplexDoorPo.x, this.closedCiniplexDoorPo.y, this.closedCiniplexDoorPo.z - 3);
            console.log("The door is opening =", this.closedCiniplexDoorPo, openPos);
            this.moveMeshToPos(this.cineplexDoor as THREE.Object3D, openPos, 5000, () => {
                self.doorStatus = DOOR_STATUS.OPEN;
            });
        }
        else {
            const closePos = this.closedCiniplexDoorPo.clone();
            console.log("The door is closing =", this.closedCiniplexDoorPo, closePos);
            this.moveMeshToPos(this.cineplexDoor as THREE.Object3D, closePos, 5000, () => {
                self.doorStatus = DOOR_STATUS.CLOSED;
            });
        }
    }

    moveMeshToPos = (obj: THREE.Object3D, endPos: THREE.Vector3, period: number, onCompleteCB?: () => void) => {
        const startPos = obj.position.clone();
        // console.log("Start pos=", startPos);
        // console.log("end pos=", endPos);

        new TWEEN.Tween(startPos).to(endPos, period).onUpdate(newPos => {
                obj.position.copy(newPos);
            }
        ).onComplete(() => {
                if (onCompleteCB) {
                    onCompleteCB();
                }
            }
        ).start();
    }
}
