import React from "react";
// import returnArrow from "../../icons/return-arrow-white.svg";
import returnArrow from "../../icons/return-arrow-gray.svg";

export const BackToObjectView = (props) => {

    const goBack = () => {
        console.log("BackToObjectViewJa is called!");
        props.onClickBack();
    }

    return (

        <button className="three-d-icon-button" onClick={() => {
            goBack()
        }}>
            <img src={returnArrow} className="three-d-icon"/>
            {/*<img src={returnArrow} className="three-d-icon"/>*/}
        </button>
    );

}

// <button className="v21-button-menu-item" onClick={() => {