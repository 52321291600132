import * as THREE from 'three';
import * as YUKA from "yuka"

export function yukaCreateConvexRegionHelper(navMesh: YUKA.NavMesh ): THREE.Mesh {

    const regions = navMesh.regions;

    const geometry = new THREE.BufferGeometry();
    const material = new THREE.MeshBasicMaterial( { vertexColors: true } );

    const mesh = new THREE.Mesh( geometry, material );

    const positions = [];
    const colors = [];

    const color = new THREE.Color();

    for ( let region of regions ) {

        // one color for each convex region

        color.setHex( Math.random() * 0xffffff );

        // count edges

        let edge = region.edge;
        const edges = [];

        do {

            edges.push( edge );

            edge = edge!.next;

        } while ( edge !== region.edge );

        // triangulate

        const triangleCount = ( edges.length - 2 );

        for ( let i = 1, l = triangleCount; i <= l; i ++ ) {

            const v1 = edges[ 0 ]!.vertex;
            const v2 = edges[ i + 0 ]!.vertex;
            const v3 = edges[ i + 1 ]!.vertex;

            positions.push( v1.x, v1.y, v1.z );
            positions.push( v2.x, v2.y, v2.z );
            positions.push( v3.x, v3.y, v3.z );

            colors.push( color.r, color.g, color.b );
            colors.push( color.r, color.g, color.b );
            colors.push( color.r, color.g, color.b );

        }

    }

    geometry.setAttribute( 'position', new THREE.Float32BufferAttribute( positions, 3 ) );
    geometry.setAttribute( 'color', new THREE.Float32BufferAttribute( colors, 3 ) );

    return mesh;

}


export function yukaNavmeshParseGeometry( data: THREE.BufferGeometry ): Array<YUKA.Polygon> {

    const index = (data.index! as THREE.BufferAttribute).array as Array<number>;
    const position = (data.attributes.position as THREE.BufferAttribute).array as Array<number>

    const vertices = new Array<YUKA.Vector3>();
    const polygons = new Array<YUKA.Polygon>();

    // vertices

    for ( let i = 0, l = position.length; i < l; i += 3 ) {

        const v = new YUKA.Vector3();

        v.x = position[ i  ];
        v.y = position[ i + 1 ];
        v.z = position[ i + 2 ];

        vertices.push( v );

    }

    // polygons

    if ( index ) {

        // indexed geometry

        for ( let i = 0, l = index.length; i < l; i += 3 ) {

            const a = index[ i  ];
            const b = index[ i + 1 ];
            const c = index[ i + 2 ];

            const contour = [ vertices[ a ], vertices[ b ], vertices[ c ] ];

            const polygon = new YUKA.Polygon().fromContour( contour );

            polygons.push( polygon );

        }

    } else {

        // non-indexed geometry //todo test

        for ( let i = 0, l = vertices.length; i < l; i += 3 ) {

            const contour = [ vertices[ i + 0 ], vertices[ i + 1 ], vertices[ i + 2 ] ];

            const polygon = new YUKA.Polygon().fromContour( contour );

            polygons.push( polygon );

        }

    }

    return polygons;

}

export function yukaCreateNavmeshFromPolygons(polygons: Array<YUKA.Polygon>): YUKA.NavMesh {

    const navmesh = new YUKA.NavMesh();
    return navmesh.fromPolygons(polygons);


}

export function createYukaNavMesh(data: THREE.BufferGeometry) :YUKA.NavMesh {

    return yukaCreateNavmeshFromPolygons(yukaNavmeshParseGeometry(data));

}

export function  yukaVector3ToThree(yukaVec3: YUKA.Vector3): THREE.Vector3 {
    const vec3 = new THREE.Vector3(yukaVec3.x,yukaVec3.y,yukaVec3.z);
    return vec3;
}

export function  threeVector3ToYuka(yukaVec3: THREE.Vector3): YUKA.Vector3 {
    const vec3 = new YUKA.Vector3(yukaVec3.x,yukaVec3.y,yukaVec3.z);
    return vec3;
}

export function yukaArrayVec3ToThree( yukaArray: Array<YUKA.Vector3>): Array<THREE.Vector3> {

    // const threeArray = new Array<THREE.Vector3>();
    const threeArray = yukaArray.map(yukaVector3ToThree);
    return threeArray;
}
