import React, {useEffect, useRef, useState} from 'react';
import {SCROLLBAR_ORIENTATION} from "../../client-data/GlobalConstants";
import {Tooltip} from "react-bootstrap/";
import DisplayTooltip from "../../client-data/DisplayTooltip";
import OverlayTriggerForwardRef from "../../util/OverlayTriggerForwardRef";
import {map} from "react-bootstrap/ElementChildren";

export default function ThumbnailImages(props) {
    const aRef = useRef(null);
    const overlayRef = useRef(null);
    // const tooltipRef = useRef(null);

    function getOverlayMap() {
        if (!overlayRef.current) {
            // Initialize the Map on first usage.
            overlayRef.current = new Map();
        }
        return overlayRef.current;
    }

    // function getTooltipMap() {
    //     if (!tooltipRef.current) {
    //         // Initialize the Map on first usage.
    //         tooltipRef.current = new Map();
    //     }
    //     return tooltipRef.current;
    // }


    const onImageClickHandler = (index) => {
        // console.log("[ThumbnailImages] onClick!",props.prefix, index);
        styleActiveComponent(index);
        props.onClick(index);
    }

    const horizontalMargin = (index) => {
        // console.log("[ThumbnailImages]/horizontalMargin", index);
        if (props.scrollbarOrientaion === SCROLLBAR_ORIENTATION.HORIZONTAL_SCROLLBAR) {
            if (index === 0) {
                return {marginLeft: "0"};
            } else if (index === (props.thumbnailImages.length - 1)) {
                return {marginRight: "0"};
            }
        }
    }

    /* Add a square around the thumbnail that is clicked! */
    const styleActiveComponent = (index) => {

        const thumbnail = document.getElementById(`${props.prefix}_thumbnail_${index}`);
        if (thumbnail) {
            // console.log("[ThumbnailImages] Activating", props.prefix, index);
            thumbnail.classList.add("active");
        }
        /* Deactivate the rest of the thumbnails */
        if (props.thumbnailImages) {
            props.thumbnailImages.map((data) => {
                if (data.id !== index) {
                    const otherThumbnail = document.getElementById(`${props.prefix}_thumbnail_${data.id}`);
                    if (otherThumbnail) {
                        otherThumbnail.classList.remove("active");
                    }
                }
            })
        }
    }

    const mouseEnterHandler = (id) => {
        const node = getOverlayMap(id);
        // console.log("[ThumbnailImages/mouseEnterHandler] id/node/tooltipNode", id, node);
        if(node) {
            node.show = props.showTooltip ? true : false;
            // node.set();
        }
    }

    const mouseLeaveHandler = (id) => {
        const node = getOverlayMap(id);
        // console.log("[ThumbnailImages/mouseLeaveHandler] id/node/tooltipNode", id, node);
        if(node) {
            node.show = false;
        }
    }

    /* This method is only called once, when the component is mounted */
    useEffect(() => {

        // console.log("[ThumbnailImages] UseEffect ", props.prefix, props.itemIndex);
        styleActiveComponent(props.itemIndex);
    }, []);

    // useEffect(() => {
    //     const timeoutID = setTimeout(() => {
    //         setShowTooltip(false)
    //     }, 1000);
    //
    //     return () => {
    //         clearTimeout(timeoutID);
    //     }
    //
    // }, []);


    return (
        <>
            {props.thumbnailImages ?
                props.thumbnailImages.map((data, index) => {
                    // console.log("thumbnailImages", data);
                    return (
                        <div
                            style={horizontalMargin(index)}
                            className="thumbnail" key={`thumbnail_${data.id}`}
                            id={`${props.prefix}_thumbnail_${data.id}`}
                        >
                            <OverlayTriggerForwardRef
                                overlay={
                                    <DisplayTooltip tooltip_type={data.tooltipTxt}
                                                    ref={aRef}
                                    />
                                }
                                ref={(node) => {
                                    // console.log("ThumbnailImagesWithTooltips: node", node);
                                    const map = getOverlayMap();
                                    if (node) {
                                        map.set(data.id, node);
                                    } else {
                                        map.delete(data.id);
                                    }
                                }}
                                placement={"top"}
                                key={`trigger_${data.id}`}
                            >
                                <div>
                                    <img src={data.imageURL} key={`image_${data.id}`}
                                         alt={`Thumbnail Slide ${data.id + 1}`}
                                         onClick={() => onImageClickHandler(data.id)}
                                         onMouseEnter={() => mouseEnterHandler(data.id)}
                                         onMouseLeave={() => mouseLeaveHandler(data.id)}

                                    />
                                </div>
                            </OverlayTriggerForwardRef>
                        </div>
                    )
                })
                :
                null
            }
        </>
    )
}