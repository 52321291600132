import * as THREE from "three";
import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";
import {MainCamera} from "./MainCamera";
import {ViewerOptionsTypes} from "./types/ViewerTypes";

export class MultiViewSingleScene {
    private _scene: THREE.Scene
    private _sceneElement: HTMLElement;
    private _orbitControls: OrbitControls | null = null;
    private _size: DOMRect;
    private _sceneCamera: MainCamera | null = null;

    constructor(private containerElement: HTMLElement, private modal3dOptions:ViewerOptionsTypes,backColor: THREE.Color = new THREE.Color(0xFFFFFF), private _offsetX = 0, private _offsetY = 0) {
        this._scene = new THREE.Scene();
        this._scene.background = backColor;
        this._sceneElement = containerElement;
        this._size = this.getElementSize();
    }

    dispose = () => {

        this.scene.environment?.dispose();
        this.scene.environment = null;
        this.scene.background = null;


        this.scene.clear();
        this.orbitControls?.dispose();
        this.sceneCamera?.dispose();
        this.sceneCamera = null;
        this.scene.removeFromParent();


        // @ts-ignore
        this._scene = null;
    }

    getElementSize(): DOMRect {

        const rect = this.sceneElement.getBoundingClientRect();
        return rect;
    }

    onWindowResize() {
        if (this.sceneCamera) {
            const vec = this.getElementSize();
            this.size = vec
            // console.log("onWindowResize", vec)
            this.sceneCamera.camera.aspect = vec.width / vec.height;
            this.sceneCamera.camera.updateProjectionMatrix()
        }
    }

    get scene(): THREE.Scene {
        return this._scene;
    }


    get sceneElement(): HTMLElement {
        return this._sceneElement;
    }

    get orbitControls(): OrbitControls | null {
        return this._orbitControls;
    }

    set orbitControls(value: OrbitControls | null) {
        if (value) {
            this._orbitControls = value;
            this._orbitControls.enableDamping = true;
            //this._orbitControls.minDistance = .25;

            /*this._orbitControls.maxPolarAngle = Math.PI;
            this._orbitControls.minPolarAngle = Math.PI;*/
            // this._orbitControls.enableZoom = true;

        } else {
            this._orbitControls = value;
        }
    }

    get sceneCamera(): MainCamera | null {
        return this._sceneCamera;
    }

    set sceneCamera(value: MainCamera | null) {

        if (value) {
            this._scene.add(value.camera);
            this.orbitControls = new OrbitControls(value.camera, this._sceneElement);
            /*  this._orbitControls.enableDamping = true;
              this._orbitControls.maxPolarAngle = Math.PI/2;*/
            this._sceneCamera = value;
        }
    }

    get size(): DOMRect {
        return this._size;
    }

    set size(value: DOMRect) {
        this._size = value;
    }


    get offsetX(): number {
        return this._offsetX;
    }

    set offsetX(value: number) {
        this._offsetX = value;
    }

    get offsetY(): number {
        return this._offsetY;
    }

    set offsetY(value: number) {
        this._offsetY = value;
    }
}
