import * as THREE from "three";
import {CompletePlayerCallBack, UpdatePlayerCallBack} from "./NavMeshUtils";
import {INavMeshPlayer} from "./INavMeshPlayer";
import {Pathfinding} from "../pathfinding";
import {DEFAULT_NAVMESH_RAYTRACE_HEIGHT} from "../../../client-data/clientOptions";

export abstract class INavMeshContainer {

    protected _navMesh: THREE.Mesh;
    public _rayCaster: THREE.Raycaster = new THREE.Raycaster(new THREE.Vector3(),new THREE.Vector3(),0,DEFAULT_NAVMESH_RAYTRACE_HEIGHT);
    protected _oldNavmeshId:number;

    constructor(private _theMesh:THREE.Mesh) {

        if (!_theMesh || !_theMesh.isMesh) {
            throw new Error("The Navmesh must be Mesh");
        }

        this._oldNavmeshId = this._theMesh.id;
        this._navMesh = _theMesh;
    }

    rayTraceNaveMesh(origin: THREE.Vector3,direction:THREE.Vector3 = new THREE.Vector3(0,-1,0)):Array<THREE.Intersection>  {
        this._rayCaster.set(origin,direction);
        return this._rayCaster.intersectObject(this._navMesh,false);
    }

    abstract createPlayer(player: THREE.Object3D,onInitCallBack:UpdatePlayerCallBack ,onStartPlayerCallBack:UpdatePlayerCallBack,
                   onUpdatePlayerCallBack:UpdatePlayerCallBack,
                   onCompletePlayerCallBack:CompletePlayerCallBack): INavMeshPlayer;

    get navMesh(): THREE.Mesh {
        return this._navMesh;
    }

    get oldNavmeshId(): number {
        return this._oldNavmeshId;
    }

}
