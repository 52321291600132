import * as THREE from "three";
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader.js'

const gltfLoader = new GLTFLoader()

export class GLTFImporter {

    static loadScene = (path: string): Promise<THREE.Group> => {

        return new Promise((resolve, reject) => {
            gltfLoader.load(
                path,
                (gltf) => {
                    console.log('success');
                    //console.log(gltf);
                    resolve(gltf.scene);
                },
                (progress) => {
                   /* console.log('progress');
                    console.log(progress);*/
                },
                (error) => {
                    console.log('error')
                    console.log(error)
                    reject(error);
                }
            )


        })
    }
}
