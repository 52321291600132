export const environments = [
  {
    id: '',
    name: 'None',
    path: null,
    format: 'none'
  },
  {
    id: 'gardenHdrX40962K.hdr',
    name: 'gardenHdrX40962K.hdr',
    path: '/assets/environment/gardenHdrX40962K.hdr',
    format: 'hdr'
  },
  {
    id: 'greenwich_park_03_02k.hdr',
    name: 'greenwich_park_03_02k.hdr',
    path: '/assets/environment/greenwich_park_03_02k.hdr',
    format: 'hdr'
  },
  {
    id: 'venice_sunset_4k.hdr',
    name: 'venice_sunset_4k.hdr',
    path: '/assets/environment/venice_sunset_4k.hdr',
    format: 'hdr'
  },
  {
    id: 'hansaplatz_02k.hdr',
    name: 'hansaplatz_02k.hdr',
    path: '/assets/environment/hansaplatz_02k.hdr',
    format: 'hdr'
  },

  {
    id: 'vignaioli_night_02k.hdr',
    name: 'vignaioli_night_02k.hdr',
    path: '/assets/environment/vignaioli_night_02k.hdr',
    format: 'hdr'
  },
  {
    id: 'adams_place_bridge_02k.hdr',
    name: 'adams_place_bridge_02k.hdr',
    path: '/assets/environment/adams_place_bridge_02k.hdr',
    format: 'hdr'
  },


  {
    id: 'wave_grey_white_1K_X1000_08.hdr',
    name: 'wave_grey_white_1K_X1000_08.hdr',
    path: '/assets/environment/wave_grey_white_1K_X1000_08.hdr',
    format: 'hdr'
  },

  {
    id: 'circus_maximus_2_02k.hdr',
    name: 'circus_maximus_2_02k.hdr',
    path: '/assets/environment/circus_maximus_2_02k.hdr',
    format: 'hdr'
  },

  {
    id: 'piazza_bologni_02k.hdr',
    name: 'piazza_bologni_02k.hdr',
    path: '/assets/environment/piazza_bologni_02k.hdr',
    format: 'hdr'
  },

















];


