import * as THREE from "three";
import {MeshButton } from "./MeshButton";
import {VideoMeshButton} from "./VideoMeshButton";
import {ButtonClickResultType, MeshButtonCallback} from "./ButtonsTypes";
import {AnimatedMeshButton} from "./AnimatedMeshButton";
import {MeshButtonBase} from "./MeshButtonBase";
import {NCDissertationButton} from "../../../client-data/ts/NCDissertationButton";
import {MeshButtonController} from "./MeshButtonController";
import {OutlineEffect} from "postprocessing";

export class MeshButtonContainer {

    private raycaster = new THREE.Raycaster();
    private mouseVector = new THREE.Vector3();
    private meshesObj: Record<string, MeshButtonBase> = {};
    private selectedButtonId: string | null = null;

    constructor(private scene: THREE.Scene ,private camera: THREE.Camera, private buttons: Array<THREE.Mesh>,onClickCallback: MeshButtonCallback,
                onHoverOverCallback: MeshButtonCallback,
                onHoverOutCallback: MeshButtonCallback,
                outlineEffect:OutlineEffect | null) {

        buttons.forEach(btn => {
            // this.meshesObj[btn.userData.button_id] = new MeshButton(btn, btn.userData.button_id, onClickCallback, onHoverOverCallback, onHoverOutCallback );
            //this.meshesObj[btn.userData.button_id] = new AnimatedMeshButton(this.scene, btn, btn.userData.button_id, onClickCallback, onHoverOverCallback, onHoverOutCallback );
            // this.meshesObj[btn.userData.button_id] = new NCDissertationButton(btn, btn.userData.button_id, onClickCallback, onHoverOverCallback, onHoverOutCallback );
           // this.meshesObj[btn.userData.button_id].disabled = false;
            this.meshesObj[btn.userData.button_id] = MeshButtonController.createMeshButton(this.scene, btn, btn.userData.button_id,onClickCallback, onHoverOverCallback, onHoverOutCallback, outlineEffect );

        })
    }

    getMeshButton = (buttonId: string):MeshButtonBase | null => {
        const btnRec = this.meshesObj[buttonId];
        if (btnRec) {
            return btnRec;
        }
        return null;

    }


    buttonClicked = (buttonId: string): void => {

        const btnRec = this.getMeshButton(buttonId)

        if (btnRec) {
            btnRec.buttonClicked();
        }
    }

    hoverOver = (buttonId: string): void => {

        const btnRec = this.getMeshButton(buttonId)

        if (btnRec) {
            btnRec.hoverOver();
        }
    }

    hoverOut = (buttonId: string): void => {

        const btnRec = this.getMeshButton(buttonId)

        if (btnRec) {
            btnRec.hoverOut();
        }
    }

    onDocumentMouseMove = (res: THREE.Intersection): ButtonClickResultType=> {

            const buttonId = res.object.userData.button_id;
            if (buttonId && (buttonId in this.meshesObj)) {
                if (this.selectedButtonId) {
                    if (this.selectedButtonId !== buttonId) {
                        const selId = this.selectedButtonId;
                        this.selectedButtonId = buttonId;
                        return {
                            result: true,
                            callBack: () => {
                                this.hoverOut(selId);
                                this.hoverOver(buttonId);
                            }
                        }
                    }
                    else {
                        return {
                            result: true,
                            callBack: null
                        }
                    }

                } else {

                    this.selectedButtonId = buttonId;
                    return {
                        result: true,
                        callBack: () => {
                            this.hoverOver(buttonId);
                        }
                    }
                }


            } else {

                if (this.selectedButtonId) {
                    const selId = this.selectedButtonId;
                    this.selectedButtonId = null;
                    return {
                        result: false,
                        callBack: () => {
                            this.hoverOut(selId);
                        }
                    }

                }
                return {
                    result: false,
                    callBack: null
                }
            }



    }

    onDocumentMouseDown = (res: THREE.Intersection): ButtonClickResultType => {

            const buttonId = res.object.userData.button_id;
            if (buttonId && (buttonId in this.meshesObj)) {

                return {
                    result: true,
                    callBack: () => {
                        this.buttonClicked(buttonId);
                    }
                }
            }
            else {
                return {
                    result: false,
                    callBack: null
                }
            }




    }

    getIntersects = (x: number, y: number): THREE.Intersection[] => {

        x = (x / window.innerWidth) * 2 - 1;
        y = -(y / window.innerHeight) * 2 + 1;

        this.mouseVector.set(x, y, 0.5);
        this.raycaster.setFromCamera(this.mouseVector, this.camera);

        return this.raycaster.intersectObjects(this.buttons, true);

    }
}
