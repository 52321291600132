import React from 'react';

const LOADING_TEXT = 'Loading...';

const NewLoadingProgressComponent = () => {
    return (
        <div id="" className="loader-module">
            <AnimationLoader/>
            <LoadingText />
        </div>
    );
}

const AnimationLoader = () => {
    return <div id="animator" className="loader-module loader"></div>
}

const LoadingText = () => {
    return (
        <h2 id="loading-text" className="loading-txt">{LOADING_TEXT}</h2>
    )
}

export default NewLoadingProgressComponent;