import React from "react";
import {OverlayTrigger} from "react-bootstrap/";


const OverlayTriggerForwardRef =
    React.forwardRef((props,ref) => {
    return (
        <OverlayTrigger
            target={ref.current}
            {...props}
        >
        </OverlayTrigger>
    )
});

export default OverlayTriggerForwardRef;