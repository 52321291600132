import React, {useEffect, useRef} from "react";
import {SCROLLBAR_ORIENTATION} from "../../client-data/GlobalConstants";
import OverlayTriggerForwardRef from "../../util/OverlayTriggerForwardRef";
import DisplayTooltip from "../../client-data/DisplayTooltip";

const ThumbnailImagesWithTooltips = (props) => {
    const aRef = useRef(null);

    const imageClickHandler = (index) => {
        // console.log("[ThumbnailImages] onClick!",props.prefix, index);
        styleActiveComponent(index);
        props.onClick(index);
    }

    const horizontalMargin = (index) => {
        // console.log("[ThumbnailImages]/horizontalMargin", index);
        if (props.scrollbarOrientaion === SCROLLBAR_ORIENTATION.HORIZONTAL_SCROLLBAR) {
            if (index === 0) {
                return {marginLeft: "0"};
            } else if (index === (props.thumbnailImages.length - 1)) {
                return {marginRight: "0"};
            }
        }
    }

    /* Add a square around the thumbnail that is clicked! */
    const styleActiveComponent = (index) => {

        const thumbnail = document.getElementById(`${props.prefix}_thumbnail_${index}`);
        if (thumbnail) {
            // console.log("[ThumbnailImages] Activating", props.prefix, index);
            thumbnail.classList.add("active");
        }
        /* Deactivate the rest of the thumbnails */
        if (props.thumbnailImages) {
            props.thumbnailImages.map((data) => {
                if (data.id !== index) {
                    const otherThumbnail = document.getElementById(`${props.prefix}_thumbnail_${data.id}`);
                    if (otherThumbnail) {
                        otherThumbnail.classList.remove("active");
                    }
                }
            })
        }
    }

    useEffect(() => {

        // console.log("[ThumbnailImages] UseEffect ", props.prefix, props.itemIndex);
        styleActiveComponent(props.itemIndex);
    }, []);

    return (
        <>
            {props.thumbnailImages ?
                props.thumbnailImages.map((data, index) => {
                    // console.log("thumbnailImages", data);
                    return (
                        <div
                            style={horizontalMargin(index)}
                            className="thumbnail" key={`thumbnail_${data.id}`}
                            id={`${props.prefix}_thumbnail_${data.id}`}
                        >
                            <div>
                                <img src={data.imageURL} key={`image_${data.id}`}
                                     alt={`Thumbnail Slide ${data.id + 1}`}
                                     onClick={() => imageClickHandler(data.id)}
                                />
                            </div>
                        </div>
                    )

                })
                :
                null
            }
        </>
    )
}

export default ThumbnailImagesWithTooltips;