

const ExternalLinksDB = [

    // {
    //     linkId: '',
    //     linkTitle: '',
    //     linkHref: ``,
    // },
    // {
    //     linkId: 'D01CHR01',
    //     linkTitle: 'Google Test!',
    //     linkHref: `https://www.google.com/`,
    // },
];

export {ExternalLinksDB};