import * as THREE from "three";

class GenerateMaterialDBCode {

    materialsChangeInGui = {};
    scene;
    constructor(scene) {

        this.scene = scene;
    }


    copyMaterialCodeToClipboard = () => {

        let strToClipboard = "[ \n";
        this.changedMaterialsInGui().forEach(item => {
            strToClipboard = strToClipboard.concat(item);
        });
        strToClipboard = strToClipboard.concat("\n ]");
        GenerateMaterialDBCode.copyToClipboard(strToClipboard);

    }

    materialGuiCallback = (material) => {
        //console.log('materialGuiCallback=',material);
        if (!(material.id in  this.materialsChangeInGui)) {
            this.materialsChangeInGui[material.id] = material;
        }
    }

    changedMaterialsInGui = () => {
        //console.log("\n [");
        const materialProcessed = {};
        const materialsArray = [];
        this.traverseMaterials(this.scene, (material) => {
            if (material.id in this.materialsChangeInGui) {
                // console.log('printMaterialsChangeInGui found material')
                if (!(material.id in materialProcessed)) {
                    const materialText = this.printMaterialChangeInGui(material);
                    materialsArray.push(materialText);
                    materialProcessed[material.id] = material;
                }
                //console.log(materialText);

            }
        });
        return materialsArray;
        //console.log("\n ]");
    }

    printMaterialChangeInGui =(material) => {
        const MATERIAL_PROPS = [
            {
                inAppName: 'aOIntensity',
                in3JSName: 'aoMapIntensity'
            },
            {
                inAppName: 'emmisiveColor',
                in3JSName: 'emissive'
            },
            {
                inAppName: 'emmissiveIntensity',
                in3JSName: 'emissiveIntensity'
            },
            {
                inAppName: 'envMapIntensity',
                in3JSName: 'envMapIntensity'
            },
            {
                inAppName: 'lightMapIntensity',
                in3JSName: 'lightMapIntensity'
            },
            {
                inAppName: 'roughness',
                in3JSName: 'roughness'
            },
            {
                inAppName: 'metalness',
                in3JSName: 'metalness'
            },
            {
                inAppName: 'toneMapped',
                in3JSName: 'toneMapped'
            },
            {
                inAppName: 'color',
                in3JSName: 'color'
            },
        ];
        let propStr = '';
        propStr = `{ \n materialName:"${material.name}",`;
        MATERIAL_PROPS.forEach((entry) => {
            if (material[entry.in3JSName] instanceof THREE.Color) {
                const color = material[entry.in3JSName];
                const colorStr = `\n new Color( ${color.r}, ${color.g}, ${color.b} )`;
                propStr = propStr.concat(`\n${entry.inAppName}:${colorStr},`);
            }
            else {
                propStr = propStr.concat(`\n${entry.inAppName}:${material[entry.in3JSName]},`);
            }

        });
        propStr = propStr.concat("\n }, \n");

        return propStr;

    }

    traverseMaterials = (object, callback, ignoreNodesCallback = (node) => {return true}) => {
        object.traverse((node) => {
            if (!node.isMesh) return;
            if (!node.visible) return;
            if (!ignoreNodesCallback(node)) {
                return;
            }
            const materials = Array.isArray(node.material)
                ? node.material
                : [node.material];
            materials.forEach(callback);
        });
    }

    static copyToClipboard = str => {
        const el = document.createElement('textarea');
        el.value = str;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
    };
}

export default GenerateMaterialDBCode;
