import * as THREE from "three";
import * as TWEEN from "@tweenjs/tween.js";
import {
    ButtonMaterials,
    HoverState,
    PAUSE_MATERIAL_COLOR,
    PLAY_MATERIAL_COLOR,
    TRANS_MATERIAL_COLOR, VIDEO_BUTTON_Y_OFFSET
} from "./ButtonsTypes";
import {DEFAULT_VIDEO_PAUSE_IMAGE_PATH} from "../../../client-data/GlobalConstants";

export enum ButtonState {
    PLAY,
    PAUSE,
    TRANSISION,
    STOPPED
}

type VideoCallback = (() => void) | null;

const TEXTURE_LOADER = new THREE.TextureLoader();
const DEFAULT_PAUSE_IMAGE =  TEXTURE_LOADER.load( process.env.PUBLIC_URL + DEFAULT_VIDEO_PAUSE_IMAGE_PATH);

export class VideoMeshButton {
    private _onClickCallback: VideoCallback = null;
    private VIDEO_BUTTON_PLAY_MATERIAL: THREE.MeshPhysicalMaterial;
    private VIDEO_BUTTON_PAUSE_MATERIAL: THREE.MeshPhysicalMaterial;
    private VIDEO_BUTTON_TRANS_MATERIAL: THREE.MeshPhysicalMaterial;
    static buttonMaterialsRecords: Record<number, ButtonMaterials> = {};
    private hoverState = HoverState.HOVER_OUT;
    private buttonState: ButtonState = ButtonState.STOPPED;



    constructor(protected scene: THREE.Scene, public button: THREE.Mesh, pauseImage: THREE.Texture) {

        // console.log("meshbutton pause texture =", pauseImage);
        let meshMat: THREE.MeshStandardMaterial;
        if (Array.isArray(this.button.material)) {
            meshMat = <THREE.MeshStandardMaterial>this.button.material[0]
        } else {
            meshMat = <THREE.MeshStandardMaterial>this.button.material;
        }

        const materialId = meshMat.id;

        if (materialId in VideoMeshButton.buttonMaterialsRecords) {
            this.VIDEO_BUTTON_PLAY_MATERIAL = VideoMeshButton.buttonMaterialsRecords[materialId].playMaterial;
            this.VIDEO_BUTTON_PAUSE_MATERIAL = VideoMeshButton.buttonMaterialsRecords[materialId].pauseMaterial;
            this.VIDEO_BUTTON_TRANS_MATERIAL = VideoMeshButton.buttonMaterialsRecords[materialId].transMaterial;
        } else {
            this.VIDEO_BUTTON_PLAY_MATERIAL = new THREE.MeshPhysicalMaterial({
                name: 'VideoSwitchMeshBasicMatPlay',
                color: PLAY_MATERIAL_COLOR,
                metalness: 0.6,
                roughness: 0.4,
                clearcoat: 0.05,
                clearcoatRoughness: 0.05,
                envMapIntensity: 3.5,
                toneMapped: false,
            });
            this.VIDEO_BUTTON_PLAY_MATERIAL.envMap = this.scene.environment;
            this.VIDEO_BUTTON_PLAY_MATERIAL.map = meshMat.map;
            this.VIDEO_BUTTON_PLAY_MATERIAL.needsUpdate = true;

            this.VIDEO_BUTTON_PAUSE_MATERIAL = new THREE.MeshPhysicalMaterial({
                name: 'VideoSwitchMeshBasicMatPause',
                color: PAUSE_MATERIAL_COLOR,
                metalness: 0.6,
                roughness: 0.4,
                clearcoat: 0.05,
                clearcoatRoughness: 0.05,
                envMapIntensity: 3.5,
                map:pauseImage ? pauseImage : DEFAULT_PAUSE_IMAGE,
                toneMapped: false,
            });
            this.VIDEO_BUTTON_PAUSE_MATERIAL.envMap = this.scene.environment;
            this.VIDEO_BUTTON_PAUSE_MATERIAL.needsUpdate = true;

            this.VIDEO_BUTTON_TRANS_MATERIAL = new THREE.MeshPhysicalMaterial({
                name: 'VideoSwitchMeshBasicMatPause',
                color: TRANS_MATERIAL_COLOR,
                metalness: 0.6,
                roughness: 0.4,
                clearcoat: 0.05,
                clearcoatRoughness: 0.05,
                envMapIntensity: 3.5
            });

            this.VIDEO_BUTTON_TRANS_MATERIAL.envMap = this.scene.environment;
            this.VIDEO_BUTTON_TRANS_MATERIAL.toneMapped = false;
            this.VIDEO_BUTTON_TRANS_MATERIAL.needsUpdate = true;

            const btnMaterial: ButtonMaterials = {
                playMaterial: this.VIDEO_BUTTON_PLAY_MATERIAL,
                pauseMaterial: this.VIDEO_BUTTON_PAUSE_MATERIAL,
                transMaterial: this.VIDEO_BUTTON_TRANS_MATERIAL,
            }
            VideoMeshButton.buttonMaterialsRecords[materialId] = btnMaterial;
        }


        button.material = this.VIDEO_BUTTON_PLAY_MATERIAL;
        button.matrixAutoUpdate = true;
        button.updateMatrix();
    }

    public set onClickCallback(value: VideoCallback) {
        this._onClickCallback = value;
    }

    buttonClicked = () => {
        if (this.buttonState === ButtonState.TRANSISION) return;

        if (this._onClickCallback) {
            this._onClickCallback();
        }
    }


    public setStatePlay = (paintingId: string) => {
        this.animateButton(this.button, ButtonState.PLAY, null);
    }

    public setStatePause = (paintingId: string) => {
        this.animateButton(this.button, ButtonState.PAUSE, null);
    }


    animateButton = (theButton: THREE.Mesh, state: ButtonState, callBack: VideoCallback) => {

        if (this.buttonState === ButtonState.TRANSISION) return;

        this.buttonState = ButtonState.TRANSISION;
        const xPos = theButton.position.x;
        const yPos = theButton.position.y;

        const buttonPos1 = theButton.position.clone();
        const buttonPos2 = buttonPos1.clone();
        buttonPos2.z -= VIDEO_BUTTON_Y_OFFSET;

        theButton.material = <THREE.Material>this.VIDEO_BUTTON_TRANS_MATERIAL;

        const t1 = new TWEEN.Tween(buttonPos1).to({
            x: xPos,
            y: yPos,
            z: buttonPos1.z - VIDEO_BUTTON_Y_OFFSET

        }, 1000)
            .onUpdate(pos => {
                theButton.position.copy(pos);
            }).onComplete(() => {

                if (state == ButtonState.PAUSE) {
                    theButton.material = <THREE.Material>this.VIDEO_BUTTON_PAUSE_MATERIAL;
                } else {
                    theButton.material = <THREE.Material>this.VIDEO_BUTTON_PLAY_MATERIAL;
                }


            });

        const t2 = new TWEEN.Tween(buttonPos2).to({
            x: xPos,
            y: yPos,
            z: buttonPos2.z + VIDEO_BUTTON_Y_OFFSET
        }, 500)
            .onUpdate(pos => {
                theButton.position.copy(pos);
            }).onComplete(() => {

                if (callBack) {

                    callBack();
                }
                this.buttonState = state;
            });

        t1.chain(t2);
        t1.start()

    }

    hoverOver = () => {
        if (this.hoverState === HoverState.HOVER_OVER) {
            return;
        }

        document.body.style.cursor = "pointer";

        this.hoverState = HoverState.HOVER_OVER;
    }

    hoverOut = () => {

        if (this.hoverState === HoverState.HOVER_OUT) {
            return;
        }

        document.body.style.cursor = "default";


        this.hoverState = HoverState.HOVER_OUT;
    }
}
