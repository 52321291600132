import VideoContainer from "../../components/annotations/VideoContainer";
import VideoDisplay from "../../components/annotations/VideoDisplay";
import VideoRecord from "../../components/videos/ts/VideoRecord";
import {ColliderTriggerContainer} from "../../components/colliders/ts/ColliderTriggerContainer";
import {ColliderTriggerBase} from "../../components/colliders/ts/ColliderTriggerBase";


enum PLAYER_POSITION {
    OutsideHouse,
    InHouse
}

export class LincolnVideoController {

    private GBG_V008: VideoDisplay;
    private HLR_V009: VideoDisplay;
    private HB2_V011: VideoDisplay;

    private SWK_V007: VideoDisplay;
    private GHI_A033: VideoDisplay;

    private CollK01_kitchenColl: ColliderTriggerBase;
    private CollGB02_GardernBColl: ColliderTriggerBase;
    private CollGF03_GardernFColl: ColliderTriggerBase;
    private CollH04_HallColl: ColliderTriggerBase;

    private playerRegin = PLAYER_POSITION.OutsideHouse;


    constructor(private videoContainer: VideoContainer, private colliderContainer: ColliderTriggerContainer) {
        this.GBG_V008 = this.videoContainer.getVideoDisplay("GBG_V008");
        this.HLR_V009 = this.videoContainer.getVideoDisplay("HLR_V009");
        // console.log("LincolnVideoController - HLR_V009:" + this.HLR_V009);
        this.HB2_V011 = this.videoContainer.getVideoDisplay("HB2_V011");
        this.SWK_V007 = this.videoContainer.getVideoDisplay("SWK_V007");
        this.GHI_A033 = this.videoContainer.getVideoDisplay("GHI_A033");

        setTimeout( ()=> {
            this.GHI_A033.playVideo();
        }, 3000)

        const colliderX_CollK01 = this.colliderContainer.getColliderXById("CollK01");
        if (colliderX_CollK01) {

            this.CollK01_kitchenColl = new ColliderTriggerBase(colliderX_CollK01, this.colliderEnter, this.colliderStay, this.colliderExit, false,true);
            this.colliderContainer.add(this.CollK01_kitchenColl);
        }
        else {
            throw new Error('Cannot find colliderX_CollK01');

        }
        const colliderX_CollGB02 = this.colliderContainer.getColliderXById("CollGB02");
        if (colliderX_CollGB02) {

            this.CollGB02_GardernBColl = new ColliderTriggerBase(colliderX_CollGB02, this.colliderEnter, this.colliderStay, this.colliderExit, false,true);
            this.colliderContainer.add(this.CollGB02_GardernBColl);
        }
        else {
            throw new Error('Cannot find colliderX_CollGB02');

        }

        const colliderX_CollGF03 = this.colliderContainer.getColliderXById("CollGF03");
        if (colliderX_CollGF03) {

            this.CollGF03_GardernFColl = new ColliderTriggerBase(colliderX_CollGF03, this.colliderEnter, this.colliderStay, this.colliderExit, false,true);
            this.colliderContainer.add(this.CollGF03_GardernFColl);
        }
        else {
            throw new Error('Cannot find colliderX_CollGF03');

        }

        const colliderX_CollH04 = this.colliderContainer.getColliderXById("CollH04");
        if (colliderX_CollH04) {

            this.CollH04_HallColl = new ColliderTriggerBase(colliderX_CollH04, this.colliderEnter, this.colliderStay, this.colliderExit, false,true);
            this.colliderContainer.add(this.CollH04_HallColl);
        }
        else {
            throw new Error('Cannot find colliderX_CollH04');

        }

    }

    public onSelectVideoThumbNail = (videoRecord: VideoRecord, meshBtnId: string) => {
        console.log("onSelectVideoThumbNail=", videoRecord, meshBtnId);
        let vDisplay: VideoDisplay | null = null;

        if (meshBtnId === 'GBG_V008_BTN') {
            vDisplay = this.GBG_V008;
        } else if(meshBtnId === 'HLR_V009_BTN') {
            vDisplay = this.HLR_V009;
        } else if(meshBtnId === 'HB2_V011_BTN') {
            vDisplay = this.HB2_V011;
        } else if (meshBtnId === 'SWK_V007_BTN') {
            vDisplay = this.SWK_V007;
        }

        if (vDisplay) {
            vDisplay.PauseVideo();

            setTimeout(() => {
                if (vDisplay) {
                    vDisplay.resetVideoSource(videoRecord.videoUrl);
                    vDisplay.playVideo();
                }
            }, 2000);
        }
    }

    playBackgroundAudio =() => {
        //need to add the settimeout so the audio has time to pause or play

        if (this.playerRegin === PLAYER_POSITION.InHouse) {
            if (this.GHI_A033.isPlaying) {
                this.GHI_A033.PauseVideo();

            }
            else {
                  setTimeout(() => {
                      if (this.playerRegin === PLAYER_POSITION.InHouse)  this.GHI_A033.PauseVideo()
               }, 1000);
            }
        }
        else {
            if (!this.GHI_A033.isPlaying) {
                this.GHI_A033.playVideo();

            }
            else {
                setTimeout(() => {
                    if (this.playerRegin === PLAYER_POSITION.OutsideHouse)  this.GHI_A033.playVideo();
                }, 1000);
            }

        }
    }

    private colliderEnter = (colliderTrigger: ColliderTriggerBase) => {

      //  console.log("Collider enter-", colliderTrigger);
        if (colliderTrigger.colliderName === 'CollK01' || colliderTrigger.colliderName === 'CollH04') {
           this.inHouseStatus = true;
        }
        else {
            this.inHouseStatus = false;

        }



    }

    private colliderStay = (colliderTrigger: ColliderTriggerBase) => {

        //console.log("Collider stay-", colliderTrigger);

    }

    private colliderExit = (colliderTrigger: ColliderTriggerBase) => {

       // console.log("Collider exit-", colliderTrigger);

    }

    set inHouseStatus(value:boolean) {
        if (value) {
            if (this.playerRegin !== PLAYER_POSITION.InHouse) {
                this.playerRegin = PLAYER_POSITION.InHouse;
                this.playBackgroundAudio();

            }
        }
        else {
            if (this.playerRegin !== PLAYER_POSITION.OutsideHouse) {
                this.playerRegin = PLAYER_POSITION.OutsideHouse;
                this.playBackgroundAudio();

            }
        }
    }


}
