import {MeshButtonContainer} from "../../components/annotations/ts/MeshButtonContainer";
import {MeshObjectContainer} from "../../components/annotations/ts/MeshObjectContainer";
import {MeshButtonBase} from "../../components/annotations/ts/MeshButtonBase";
import {ParticleSysRenderer} from "../../components/particlesSystem/particles/ParticleSysRenderer";
import {ParticleSystem} from "../../components/particlesSystem/particles/ParticleSystem";
import * as THREE from "three";
import {createFireParticles} from "../../components/particlesSystem/particles/FireSystem";
import {createSmokeParticles} from "../../components/particlesSystem/particles/SmokeSystem";
import {createWaterParticles} from "../../components/particlesSystem/particles/CreateWaterParticle";
import * as TWEEN from "@tweenjs/tween.js";
import VideoContainer from "../../components/annotations/VideoContainer";
import VideoDisplay from "../../components/annotations/VideoDisplay";


enum PARTICLE_STATUS {
    PLAYING,
    PAUSED,
}

enum MESH_STATUS {
    OPEN,
    TRANSITION,
    CLOSED,
}

const ROTATION_SEAT_OPEN = 80*Math.PI/180.0;
const COAL_LID_POSITION_OPEN = 0.33;

// 1.58133  closed angle of the seats
// -1.58133
// -1.58133

export class CabShelterProcessor {

    private W01P01BT: MeshButtonBase;
    private W03P05BT: MeshButtonBase;
    private W03P06BT: MeshButtonBase;
    private fireBT: MeshButtonBase;
    private smokeBT: MeshButtonBase;
    private waterBT: MeshButtonBase;
    private coalLidBT: MeshButtonBase;

    private particleSysFire: ParticleSystem;
    private particleSysSmoke: ParticleSystem;
    private particleSysWater: ParticleSystem;

    private W01P01_status = MESH_STATUS.CLOSED;
    private W03P05_status = MESH_STATUS.CLOSED;
    private W03P06_status = MESH_STATUS.CLOSED;
    private coalLid_status = MESH_STATUS.CLOSED;

    private W01P01_mesh: THREE.Mesh;
    private W03P05_mesh: THREE.Mesh;
    private W03P06_mesh: THREE.Mesh;
    private coalLid_mesh: THREE.Mesh;

    /*private W01P01_mesh_closed_x: number;
    private W03P05_mesh_closed_x: number;
    private W03P06_mesh_closed_x: number;*/

    private W01P01_mesh_closed: THREE.Euler;
    private W03P05_mesh_closed: THREE.Euler;
    private W03P06_mesh_closed: THREE.Euler;
    private coalLid_mesh_closed: THREE.Vector3;

    private W01P01_mesh_open: THREE.Euler;
    private W03P05_mesh_open: THREE.Euler;
    private W03P06_mesh_open: THREE.Euler;
    private coalLid_mesh_open: THREE.Vector3;

    private W04A13_fire_audio:VideoDisplay;
    private W04A14_smoke_audio:VideoDisplay;
    private W04A15_water_audio:VideoDisplay;

    constructor(private meshButtonContainer: MeshButtonContainer,
                private meshObjectContainer: MeshObjectContainer,
                private particleSysRenderer: ParticleSysRenderer,
                private videoContainer: VideoContainer,
                private scene: THREE.Scene,
                private camera: THREE.Camera) {

        console.log("Begin CabShelterProcessor");

        this.W04A13_fire_audio = this.videoContainer.getVideoDisplay("W04A13");
        this.W04A14_smoke_audio = this.videoContainer.getVideoDisplay("W04A14");
        this.W04A15_water_audio = this.videoContainer.getVideoDisplay("W04A15");


        const m1 = this.meshObjectContainer.getMesh("W01P01MO");
        console.log("CabShelterProcessor m1=", m1);
        this.W01P01_mesh = m1!;
        this.W01P01_mesh.matrixAutoUpdate = true;
        this.W01P01_mesh.updateMatrix();
        // this.W01P01_mesh_closed_x = this.W01P01_mesh.rotation.x;
        this.W01P01_mesh_closed = this.W01P01_mesh.rotation.clone();
        this.W01P01_mesh_open = this.W01P01_mesh.rotation.clone();
        this.W01P01_mesh_open.x = ROTATION_SEAT_OPEN;


        this.W03P05_mesh = this.meshObjectContainer.getMesh("W03P05MO")!;
        this.W03P05_mesh.matrixAutoUpdate = true;
        this.W03P05_mesh.updateMatrix();
        // this.W03P05_mesh_closed_x = this.W03P05_mesh.rotation.x;
        this.W03P05_mesh_closed = this.W03P05_mesh.rotation.clone();
        this.W03P05_mesh_open = this.W03P05_mesh.rotation.clone();
        this.W03P05_mesh_open.x = -ROTATION_SEAT_OPEN;

        this.W03P06_mesh = this.meshObjectContainer.getMesh("W03P06MO")!;
        this.W03P06_mesh.matrixAutoUpdate = true;
        this.W03P06_mesh.updateMatrix();
        // this.W03P06_mesh_closed_x = this.W03P06_mesh.rotation.x;
        this.W03P06_mesh_closed = this.W03P06_mesh.rotation.clone();
        this.W03P06_mesh_open = this.W03P06_mesh.rotation.clone();
        this.W03P06_mesh_open.x = -ROTATION_SEAT_OPEN;

        this.coalLid_mesh = this.meshObjectContainer.getMesh("coalLidMO")!;
        this.coalLid_mesh.matrixAutoUpdate = true;
        this.coalLid_mesh.updateMatrix();
        // this.W03P06_mesh_closed_x = this.W03P06_mesh.rotation.x;
        this.coalLid_mesh_closed = this.coalLid_mesh.position.clone();
        this.coalLid_mesh_open = this.coalLid_mesh.position.clone();
        this.coalLid_mesh_open.y = COAL_LID_POSITION_OPEN;


        this.W01P01BT = this.meshButtonContainer.getMeshButton("W01P01BT")!;
        this.W01P01BT.onClickCallback = this.buttonClicked_W01P01BT;

        this.W03P05BT = this.meshButtonContainer.getMeshButton("W03P05BT")!;
        this.W03P05BT.onClickCallback = this.buttonClicked_W03P05BT;

        this.W03P06BT = this.meshButtonContainer.getMeshButton("W03P06BT")!;
        this.W03P06BT.onClickCallback = this.buttonClicked_W03P06BT;

        this.fireBT = this.meshButtonContainer.getMeshButton("fireBT")!;
        this.fireBT.onClickCallback = this.buttonClicked_fireBT;

        this.smokeBT = this.meshButtonContainer.getMeshButton("smokeBT")!;
        this.smokeBT.onClickCallback = this.buttonClicked_smokeBT;

        this.waterBT = this.meshButtonContainer.getMeshButton("waterBT")!;
        this.waterBT.onClickCallback = this.buttonClicked_waterBT;

        this.coalLidBT = this.meshButtonContainer.getMeshButton("coalLidBT")!;
        this.coalLidBT.onClickCallback = this.buttonClicked_coalLidBT;


        this.particleSysFire = createFireParticles(scene, camera);
        this.particleSysFire.pause = true;
        this.particleSysSmoke = createSmokeParticles(scene, camera);
        this.particleSysSmoke.pause = true;
        this.particleSysWater = createWaterParticles(scene, camera);
        this.particleSysWater.pause = true;

        this.particleSysRenderer.addParticle("Fire", this.particleSysFire);
        this.particleSysRenderer.addParticle("Smoke", this.particleSysSmoke);
        this.particleSysRenderer.addParticle("Water", this.particleSysWater);
    }

    buttonClicked_W01P01BT = (buttonId: string): void => {

        if (this.W01P01_status === MESH_STATUS.TRANSITION) return;

        if (this.W01P01_status === MESH_STATUS.CLOSED) {
            this.rotateMesh(this.W01P01_mesh,
                this.W01P01_mesh_open,
                3000,
                ()=> { this.W01P01_status = MESH_STATUS.TRANSITION},
                ()=> { this.W01P01_status = MESH_STATUS.OPEN}
            )
        }
        else {
            if (this.W01P01_status === MESH_STATUS.OPEN) {
                this.rotateMesh(this.W01P01_mesh,
                    this.W01P01_mesh_closed,
                    3000,
                    ()=> { this.W01P01_status = MESH_STATUS.TRANSITION},
                    ()=> { this.W01P01_status = MESH_STATUS.CLOSED}
                )
            }
        }

    }

    buttonClicked_W03P05BT = (buttonId: string): void => {

        if (this.W03P05_status === MESH_STATUS.TRANSITION) return;

        if (this.W03P05_status === MESH_STATUS.CLOSED) {
            this.rotateMesh(this.W03P05_mesh,
                this.W03P05_mesh_open,
                3000,
                ()=> { this.W03P05_status = MESH_STATUS.TRANSITION},
                ()=> { this.W03P05_status = MESH_STATUS.OPEN}
            )
        }
        else {
            if (this.W03P05_status === MESH_STATUS.OPEN) {
                this.rotateMesh(this.W03P05_mesh,
                    this.W03P05_mesh_closed,
                    3000,
                    ()=> { this.W03P05_status = MESH_STATUS.TRANSITION},
                    ()=> { this.W03P05_status = MESH_STATUS.CLOSED}
                )
            }
        }
    }
    buttonClicked_W03P06BT = (buttonId: string): void => {

        if (this.W03P06_status === MESH_STATUS.TRANSITION) return;

        if (this.W03P06_status === MESH_STATUS.CLOSED) {
            this.rotateMesh(this.W03P06_mesh,
                this.W03P06_mesh_open,
                3000,
                ()=> { this.W03P06_status = MESH_STATUS.TRANSITION},
                ()=> { this.W03P06_status = MESH_STATUS.OPEN}
            )
        }
        else {
            if (this.W03P06_status === MESH_STATUS.OPEN) {
                this.rotateMesh(this.W03P06_mesh,
                    this.W03P06_mesh_closed,
                    3000,
                    ()=> { this.W03P06_status = MESH_STATUS.TRANSITION},
                    ()=> { this.W03P06_status = MESH_STATUS.CLOSED}
                )
            }
        }
    }
    buttonClicked_fireBT = (buttonId: string): void => {
        this.particleSysFire.pause = !this.particleSysFire.pause;
        if (this.particleSysFire.pause) {
            this.W04A13_fire_audio.PauseVideo();
        }
        else {
            this.W04A13_fire_audio.playVideo();
        }

    }
    buttonClicked_smokeBT = (buttonId: string): void => {
        this.particleSysSmoke.pause = !this.particleSysSmoke.pause;
        if (this.particleSysSmoke.pause) {
            this.W04A14_smoke_audio.PauseVideo();
        }
        else {
            this.W04A14_smoke_audio.playVideo();
        }
    }
    buttonClicked_waterBT = (buttonId: string): void => {
        this.particleSysWater.pause = !this.particleSysWater.pause;
        if (this.particleSysWater.pause) {
            this.W04A15_water_audio.PauseVideo();
        }
        else {
            this.W04A15_water_audio.playVideo();
        }

    }
    buttonClicked_coalLidBT = (buttonId: string): void => {

        if (this.coalLid_status === MESH_STATUS.TRANSITION) return;

        if (this.coalLid_status === MESH_STATUS.CLOSED) {
            this.moveMeshToPos(this.coalLid_mesh,
                this.coalLid_mesh_open,
                3000,
                ()=> { this.coalLid_status = MESH_STATUS.TRANSITION},
                ()=> { this.coalLid_status = MESH_STATUS.OPEN}
            )
        }
        else {
            if (this.coalLid_status === MESH_STATUS.OPEN) {
                this.moveMeshToPos(this.coalLid_mesh,
                    this.coalLid_mesh_closed,
                    3000,
                    ()=> { this.coalLid_status = MESH_STATUS.TRANSITION},
                    ()=> { this.coalLid_status = MESH_STATUS.CLOSED}
                )
            }
        }
    }


    rotateMesh = (obj: THREE.Object3D, endPos: THREE.Euler, period: number, onUpdateCB?: () => void ,onCompleteCB?: () => void) => {
        const startPos = obj.rotation.clone();

        new TWEEN.Tween(startPos).to(endPos, period).onUpdate(newPos => {
                if (onUpdateCB) {
                    onUpdateCB();
                }
                obj.rotation.x = newPos.x;
                //obj.updateMatrix();
                //console.log("rotateMesh tween =", newPos);

            }
        ).onComplete(() => {
                if (onCompleteCB) {
                    onCompleteCB();
                }
            }
        ).start();
    }

    moveMeshToPos = (obj: THREE.Object3D, endPos: THREE.Vector3, period: number, onUpdateCB?: () => void ,onCompleteCB?: () => void) => {
        const startPos = obj.position.clone();
        // console.log("Start pos=", startPos);
        // console.log("end pos=", endPos);

        new TWEEN.Tween(startPos).to(endPos, period).onUpdate(newPos => {
            if (onUpdateCB) {
                onUpdateCB();
            }
                obj.position.copy(newPos);
            }
        ).onComplete(() => {
                if (onCompleteCB) {
                    onCompleteCB();
                }
            }
        ).start();
    }
}
