import {ColliderX} from "./ColliderX";
import {ColliderTriggerBase} from "./ColliderTriggerBase";
import * as THREE from "three";

export enum COLLIDER_STATUS {
    intersected,
    not_intersected
}

export type ColliderXCallback = ((collider: ColliderX) => void);

export type ColliderTriggerCallback = ((colliderTrigger: ColliderTriggerBase) => void);

export type ColliderIntersectPointType = { colliderTrigger: ColliderTriggerBase, point: THREE.Vector3};
