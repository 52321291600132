import * as THREE from "three";
import {EnvironmentMapsType, EnvironmentOptionsType, EnvironmentTextureDoubleResults} from "../types/ViewerTypes";
import {RGBELoader} from "three/examples/jsm/loaders/RGBELoader";
import {EXRLoader} from "three/examples/jsm/loaders/EXRLoader";

export class EnvironmentTextureLoader {
    constructor(private _manager?: THREE.LoadingManager) {

    }

    get manager(): THREE.LoadingManager | undefined {
        return this._manager;
    }

    set manager(value: THREE.LoadingManager | undefined) {
        this._manager = value;
    }

    async getCubeMapHDRITexture(environment: EnvironmentMapsType): Promise<THREE.DataTexture> {
        try {
            const texture = await new RGBELoader(this.manager).loadAsync(environment.path)

            return texture;
        } catch (error) {
            throw new Error(`Cannot find Texture HDR ${environment}`);
        }
    }

    async getCubeMapEXRTexture(environment: EnvironmentMapsType): Promise<THREE.DataTexture> {

        try {
            const texture = await new EXRLoader(this.manager).setDataType(THREE.UnsignedByteType).loadAsync(environment.path)

            return texture;
        } catch (error) {
            throw new Error(`Cannot find Texture exr ${environment}`);
        }
    }

    async getSingleTexture(environment: EnvironmentMapsType): Promise<THREE.DataTexture | null> {
        try {
            if (environment.format === 'none') {
                return null
            } else if (environment.format === 'hdr') {
                return await this.getCubeMapHDRITexture(environment)
            } else if (environment.format === 'exr') {
                return await this.getCubeMapEXRTexture(environment)
            }
            return null;
        } catch (error) {
            throw new Error(`Cannot find HDR Texture ${environment}`);
        }

    }

    async getEnvironmentTexture(environmentOptions: EnvironmentOptionsType): Promise<EnvironmentTextureDoubleResults> {

        const colorTexture = await this.getSingleTexture(environmentOptions.colorMap);

        if (environmentOptions.colorMap.name === environmentOptions.backgroundMap.name) {
            return {colorTexture: colorTexture, backgroundTexture: colorTexture};
        }

        const backgroundTexture = await this.getSingleTexture(environmentOptions.backgroundMap);

        return {colorTexture: colorTexture, backgroundTexture:backgroundTexture};

    }
}
