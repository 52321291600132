import {MeshButtonContainer} from "../../components/annotations/ts/MeshButtonContainer";
import * as THREE from "three";
import {MeshButtonBase} from "../../components/annotations/ts/MeshButtonBase";
import {ColliderXContainer} from "../../components/colliders/ts/ColliderXContainer";
import {ColliderX} from "../../components/colliders/ts/ColliderX";
import * as TWEEN from "@tweenjs/tween.js";
import {CameraNavMeshHandler} from "../../components/three-gltf-loader/ts/CameraNavMeshHandler";
import {MeshObjectContainer} from "../../components/annotations/ts/MeshObjectContainer";
import CameraLocationsDataController from "../../components/zones/CameraLocationsDataController";


//lift top pos -5.2545 ,3.5486 , 13.764 in Three coordinate
//lift bottom pos -5.2545 ,-1.25314  , -13.764 in Three coordinate  = height = 4.80174

//door bottom right x offset for opening -4.57583 to -3.83833 = 0.7375

enum DOOR_STATUS {
    OPEN,
    Opening,
    CLOSED
}

enum LIFT_POS {
    Top,
    Bottom
}

enum CAMERA_TRIGGER_STATUS {
    InTop,
    Out,
    InBottom,

}

enum CAMERA_ZONE_LOCATION {
    GROUND_FLOOR,
    FIRST_FLOOR,
    AUDITORIUM_LEFT,
    AUDITORIUM_RIGHT,
}

enum CAMERA_MOVEMENT_STATUS {
    CameraMovingUp,
    CameraMovingDown,
    CameraFree

}

export class BSHFProcessor {

    private readonly _colliderXContainer: ColliderXContainer;
    private meshButton_LiftBottomButton: MeshButtonBase;
    private meshButton_LiftTopButton: MeshButtonBase;
    private doorBottomLeft: THREE.Mesh | null;
    private doorBottomRight: THREE.Mesh | null;
    private doorTopLeft: THREE.Mesh | null;
    private doorTopRight: THREE.Mesh | null;
    private liftCabin: THREE.Mesh | null;

    private readonly liftCabinBottomPos: THREE.Vector3;
    private readonly liftCabinTopPos: THREE.Vector3;
    private readonly doorBottomPosLeftClosed: THREE.Vector3;
    private readonly doorBottomPosLeftOpen: THREE.Vector3;
    private readonly doorTopPosLeftClosed: THREE.Vector3;
    private readonly doorTopPosLeftOpen: THREE.Vector3;
    private readonly doorBottomPosRightClosed: THREE.Vector3;
    private readonly doorBottomPosRightOpen: THREE.Vector3;
    private readonly doorTopPosRightClosed: THREE.Vector3;
    private readonly doorTopPosRightOpen: THREE.Vector3;
    private readonly cameraParent: THREE.Object3D | null;

    private bottomDoorStatus: DOOR_STATUS = DOOR_STATUS.CLOSED;
    private topDoorStatus: DOOR_STATUS = DOOR_STATUS.CLOSED;
    private liftPosStatus: LIFT_POS = LIFT_POS.Bottom;
    private CameraInTriggerStatus: CAMERA_TRIGGER_STATUS = CAMERA_TRIGGER_STATUS.Out;
    private _cameraZoneLocation: CAMERA_ZONE_LOCATION = CAMERA_ZONE_LOCATION.GROUND_FLOOR;
    private _cameraMovementStatus: CAMERA_MOVEMENT_STATUS = CAMERA_MOVEMENT_STATUS.CameraFree;

    constructor(private camera: THREE.Camera,
                private meshButtonContainer: MeshButtonContainer,
                private colliders: Array<THREE.Object3D>,
                private animatedMeshes: Array<THREE.Object3D>,
                private cameraNavMeshHandler: CameraNavMeshHandler,
                private meshObjectContainer: MeshObjectContainer,
                private scene:THREE.Scene,
                showWireframe = false) {

        const colliderXs: Array<ColliderX> = [];
        this.colliders.forEach((collider) => {
            colliderXs.push(new ColliderX(collider, showWireframe));
        })

        this._colliderXContainer = new ColliderXContainer(camera, new THREE.Vector3(0.5, 0.5, 0.5), colliderXs, this.colliderEnter, this.colliderStay, this.colliderExit, showWireframe);

        this.meshButton_LiftBottomButton = this.meshButtonContainer.getMeshButton("LiftBottomButton")!;
        this.meshButton_LiftBottomButton.onClickCallback = this.buttonClicked_LiftBottomButton;

        this.meshButton_LiftTopButton = this.meshButtonContainer.getMeshButton("LiftTopButton")!;
        this.meshButton_LiftTopButton.onClickCallback = this.buttonClicked_LiftTopButton;

        this.doorBottomLeft = this.meshObjectContainer.getMesh("LiftBottomDoor_L");
        this.doorBottomLeft!.matrixAutoUpdate = true;
        this.doorBottomLeft!.updateMatrix();

        this.doorBottomRight = this.meshObjectContainer.getMesh("LiftBottomDoor_R");
        this.doorBottomRight!.matrixAutoUpdate = true;
        this.doorBottomRight!.updateMatrix();

        this.doorTopLeft = this.meshObjectContainer.getMesh("LiftTopDoor_L");
        this.doorTopLeft!.matrixAutoUpdate = true;
        this.doorTopLeft!.updateMatrix();

        this.doorTopRight = this.meshObjectContainer.getMesh("LiftTopDoor_R");
        this.doorTopRight!.matrixAutoUpdate = true;
        this.doorTopRight!.updateMatrix();

        this.liftCabin = this.meshObjectContainer.getMesh("LiftCabin");
        this.liftCabin!.matrixAutoUpdate = true;
        this.liftCabin!.updateMatrix();


        this.liftCabinBottomPos = this.liftCabin!.position.clone();
        this.liftCabinTopPos = this.liftCabinBottomPos.clone().add(new THREE.Vector3(0, 5.0, 0));

        this.doorBottomPosLeftClosed = this.doorBottomLeft!.position.clone();
        this.doorBottomPosLeftOpen = this.doorBottomPosLeftClosed.clone().add(new THREE.Vector3(-0.7375, 0, 0));

        this.doorBottomPosRightClosed = this.doorBottomRight!.position.clone();
        this.doorBottomPosRightOpen = this.doorBottomPosRightClosed.clone().add(new THREE.Vector3(0.7375, 0, 0));

        this.doorTopPosRightClosed = this.doorTopRight!.position.clone();
        this.doorTopPosRightOpen = this.doorTopPosRightClosed.clone().add(new THREE.Vector3(-0.7375, 0, 0));

        this.doorTopPosLeftClosed = this.doorTopLeft!.position.clone();
        this.doorTopPosLeftOpen = this.doorTopPosLeftClosed.clone().add(new THREE.Vector3(0.7375, 0, 0));

        this.cameraParent = this.camera.parent;
        console.log("Camera Parent=", this.cameraParent);


    }

    moveLiftCabinUp = (period = 3000, callBack?: () => void) => {


        this.camera.parent = this.liftCabin;

        this.camera.position.copy(new THREE.Vector3(0, 1.5, 0));
        this.moveMeshToPos(<THREE.Object3D>this.liftCabin, this.liftCabinTopPos.clone(), period, () => {
            const wPos = new THREE.Vector3();
            this.camera.getWorldPosition(wPos);


            this.camera.parent = this.cameraParent;
            this.camera.position.copy(wPos);

            if (callBack) {
                callBack();
            }
            this.liftPosStatus = LIFT_POS.Top;
        });
    }

    moveLiftCabinDown = (period = 3000, callBack?: () => void) => {


        this.camera.parent = this.liftCabin;
        this.camera.position.copy(new THREE.Vector3(0, 1.5, 0));
        this.moveMeshToPos(<THREE.Object3D>this.liftCabin, this.liftCabinBottomPos.clone(), period, () => {
            let wPos = this.camera.getWorldPosition(new THREE.Vector3());


            this.camera.parent = this.cameraParent;
            this.camera.position.copy(wPos);

            if (callBack) {
                callBack();
            }
            this.liftPosStatus = LIFT_POS.Bottom;
        });
    }

    openBottomDoors = (period = 3000, callBack?: () => void) => {
        this.moveMeshToPos(<THREE.Object3D>this.doorBottomRight, this.doorBottomPosRightOpen.clone(), period);
        this.moveMeshToPos(<THREE.Object3D>this.doorBottomLeft, this.doorBottomPosLeftOpen.clone(), period, () => {
            if (callBack) {
                callBack();
            }
            this.bottomDoorStatus = DOOR_STATUS.OPEN;
        });
    }

    closeBottomDoors = (period = 3000, callBack?: () => void) => {
        this.moveMeshToPos(<THREE.Object3D>this.doorBottomRight, this.doorBottomPosRightClosed.clone(), period);
        this.moveMeshToPos(<THREE.Object3D>this.doorBottomLeft, this.doorBottomPosLeftClosed.clone(), period, () => {
            if (callBack) {
                callBack();
            }
            this.bottomDoorStatus = DOOR_STATUS.CLOSED;
        });

    }

    closeTopDoors = (period = 3000, callBack?: () => void) => {
        this.moveMeshToPos(<THREE.Object3D>this.doorTopRight, this.doorTopPosRightClosed.clone(), period);
        this.moveMeshToPos(<THREE.Object3D>this.doorTopLeft, this.doorTopPosLeftClosed.clone(), period, () => {
            if (callBack) {
                callBack();
            }
            this.topDoorStatus = DOOR_STATUS.CLOSED;
        });
    }

    openTopDoors = (period = 3000, callBack?: () => void) => {
        this.moveMeshToPos(<THREE.Object3D>this.doorTopRight, this.doorTopPosRightOpen.clone(), period);
        this.moveMeshToPos(<THREE.Object3D>this.doorTopLeft, this.doorTopPosLeftOpen.clone(), period, () => {
            if (callBack) {
                callBack();
            }
            this.topDoorStatus = DOOR_STATUS.OPEN;
        });
    }

    buttonClicked_LiftBottomButton = (buttonId: string): void => {
        console.log("buttonClicked- buttonClicked_LiftBottomButton", buttonId, this._cameraZoneLocation, this._cameraMovementStatus);

        if (this._cameraZoneLocation !== CAMERA_ZONE_LOCATION.GROUND_FLOOR || this._cameraMovementStatus !== CAMERA_MOVEMENT_STATUS.CameraFree) return;

        console.log("buttonClicked_LiftBottomButton will move to door");

        const cameraLocationRec = CameraLocationsDataController.populateCameraLocationRecordById("LIFT_BOTTOM_CAM");
        if (cameraLocationRec && cameraLocationRec.ObjectId ) {
            const meshToNavigate = this.scene.getObjectById(cameraLocationRec.ObjectId);
            // this.cameraNavMeshHandler.navigateToNanMeshPoint(new THREE.Vector3(-5.01957 , 4, -16), () => {
            this.cameraNavMeshHandler.navigateToTargetPos(meshToNavigate!, false, () => {
                // this.cameraNavMeshHandler.navigateToNanMeshPoint(new THREE.Vector3(-4.15283, -1, -11.9), () => {
                this._cameraMovementStatus = CAMERA_MOVEMENT_STATUS.CameraMovingUp;
                console.log("buttonClicked_LiftBottomButton moving to door");
                this.openBottomDoors(3000, () => {
                    console.log("buttonClicked_LiftBottomButton open door");
                    this.cameraNavMeshHandler.rotateCameraToTarget(new THREE.Vector3(-5.2545, -1, -13.764), 1500, () => {

                        this.cameraNavMeshHandler.moveCameraToPosition(new THREE.Vector3(-5.2545, -1, -13.764), undefined, () => {
                            this.cameraNavMeshHandler.rotateCameraToTarget(new THREE.Vector3(-6.40665, -1, -13.764), 1500, () => {
                                this.closeBottomDoors(3000, () => {
                                    this.moveLiftCabinUp(5000, () => {
                                        this.cameraNavMeshHandler.rotateCameraToTarget(new THREE.Vector3(-9.6068  , 4, -23.7317 ), 1500, () => {
                                            this.openTopDoors(3000, () => {

                                                    this.cameraNavMeshHandler.moveCameraForwardNoNavmesh(4.0, 0,()=> {
                                                        this.cameraNavMeshHandler.updateGroupIDAtPlayerPos();
                                                        this.closeTopDoors();
                                                        this._cameraZoneLocation = CAMERA_ZONE_LOCATION.FIRST_FLOOR;
                                                        this._cameraMovementStatus = CAMERA_MOVEMENT_STATUS.CameraFree;
                                                    });
                                            });
                                        });
                                    });
                                });
                            });
                        });
                    });
                });
            });
        }


    }

    buttonClicked_LiftTopButton = (buttonId: string): void => {
        console.log("buttonClicked- buttonClicked_LiftTopButton", buttonId, this._cameraZoneLocation, this._cameraMovementStatus);

        if (this._cameraZoneLocation !== CAMERA_ZONE_LOCATION.FIRST_FLOOR || this._cameraMovementStatus !== CAMERA_MOVEMENT_STATUS.CameraFree) return;

        console.log("buttonClicked_LiftTopButton will move to door");

        const cameraLocationRec = CameraLocationsDataController.populateCameraLocationRecordById("LIFT_TOP_CAM");
        if (cameraLocationRec && cameraLocationRec.ObjectId ) {
            const meshToNavigate = this.scene.getObjectById(cameraLocationRec.ObjectId);
            // this.cameraNavMeshHandler.navigateToNanMeshPoint(new THREE.Vector3(-5.01957 , 4, -16), () => {
            this.cameraNavMeshHandler.navigateToTargetPos(meshToNavigate!, false,() => {
                this._cameraMovementStatus = CAMERA_MOVEMENT_STATUS.CameraMovingDown;
                console.log("buttonClicked_LiftTopButton moving to door");
                this.openTopDoors(3000, () => {
                    console.log("buttonClicked_LiftTopButton open door");
                    this.cameraNavMeshHandler.rotateCameraToTarget(new THREE.Vector3(-5.2545, 4, -13.764), 1500, () => {

                        this.cameraNavMeshHandler.moveCameraToPosition(new THREE.Vector3(-5.2545, 4, -13.764), undefined, () => {
                            this.cameraNavMeshHandler.rotateCameraToTarget(new THREE.Vector3(-6.40665, 4, -13.764), 1500, () => {
                                this.closeTopDoors(3000, () => {
                                    this.moveLiftCabinDown(5000, () => {
                                        this.cameraNavMeshHandler.rotateCameraToTarget(new THREE.Vector3(-2.87649, -1, -9.35571), 1500, () => {
                                            this.openBottomDoors(3000, () => {

                                                this.cameraNavMeshHandler.moveCameraToPosition(new THREE.Vector3(-3.9, -1, -11.2), undefined, () => {
                                                    this.cameraNavMeshHandler.updateGroupIDAtPlayerPos();
                                                    this.cameraNavMeshHandler.rotateCameraToTarget(new THREE.Vector3(2.2, -1, -9.82), 1500, () => {
                                                        this.closeBottomDoors();
                                                        this._cameraZoneLocation = CAMERA_ZONE_LOCATION.GROUND_FLOOR;
                                                        this._cameraMovementStatus = CAMERA_MOVEMENT_STATUS.CameraFree;
                                                    });
                                                });
                                            });
                                        });
                                    });
                                });
                            });
                        });
                    });
                });
            });
        }


    }

    get colliderXContainer(): ColliderXContainer {
        return this._colliderXContainer;
    }

    colliderEnter = (collider: ColliderX): void => {
        // console.log("colliderEnter-", collider)
      /*  if (collider.colliderName === "LiftBottomCollider") {
            this.CameraInTriggerStatus = CAMERA_TRIGGER_STATUS.InBottom;
        } else if (collider.colliderName === "LiftTopCollider") {
            this.CameraInTriggerStatus = CAMERA_TRIGGER_STATUS.InTop;
        }*/

    }

    colliderStay = (collider: ColliderX): void => {
        // console.log("colliderStay -",collider.colliderName);
      /*  if (collider.colliderName === "LiftBottomCollider") {
            this.CameraInTriggerStatus = CAMERA_TRIGGER_STATUS.InBottom;
        } else if (collider.colliderName === "LiftTopCollider") {
            this.CameraInTriggerStatus = CAMERA_TRIGGER_STATUS.InTop;
        }*/
    }

    colliderExit = (collider: ColliderX): void => {

        //this.CameraInTriggerStatus = CAMERA_TRIGGER_STATUS.Out;

    }

    moveMeshToPos = (obj: THREE.Object3D, endPos: THREE.Vector3, period: number, onCompleteCB?: () => void) => {
        const startPos = obj.position.clone();
        // console.log("Start pos=", startPos);
        // console.log("end pos=", endPos);

        new TWEEN.Tween(startPos).to(endPos, period).onUpdate(newPos => {
                obj.position.copy(newPos);
            }
        ).onComplete(() => {
                if (onCompleteCB) {
                    onCompleteCB();
                }
            }
        ).start();
    }

    get cameraZoneLocation(): CAMERA_ZONE_LOCATION {
        return this._cameraZoneLocation;
    }

    get cameraMovementStatus(): CAMERA_MOVEMENT_STATUS {
        return this._cameraMovementStatus;
    }

    setCameraZoneLocationToGroundFloor = () => {
        this.cameraNavMeshHandler.updateGroupIDAtPlayerPos();
        this.moveMeshToPos(<THREE.Object3D>this.liftCabin, this.liftCabinBottomPos.clone(), 100, () => {
            let wPos = this.camera.getWorldPosition(new THREE.Vector3());
            this.liftPosStatus = LIFT_POS.Bottom;
            this._cameraZoneLocation = CAMERA_ZONE_LOCATION.GROUND_FLOOR;

        });

    }
}
