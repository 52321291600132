import * as THREE from "three";
import {HoverState, MeshButtonCallback} from "./ButtonsTypes";
import {MeshButtonBase} from "./MeshButtonBase";
import {OutlineEffect} from "postprocessing";


export class MeshButton extends MeshButtonBase {


    constructor(button: THREE.Mesh,
                buttonId: string,
                onClickCallback?: MeshButtonCallback,
                onHoverOverCallback?: MeshButtonCallback,
                onHoverOutCallback?: MeshButtonCallback,
                useOutliner?: boolean,
                outlinerColor?: THREE.Color,
                outlinerSize?:  THREE.Vector3,
                outlineEffect?:OutlineEffect | null) {

        super(button, buttonId, onClickCallback, onHoverOverCallback, onHoverOutCallback,useOutliner, outlinerColor, outlinerSize, outlineEffect)


    }


}
