import * as THREE  from "three";
import { DEFAULT_NAVMESH_RAYTRACE_HEIGHT } from "../../../client-data/clientOptions";
import {Pathfinding} from "../pathfinding"
import {NavMeshPlayer} from "./NavMeshPlayer";
import {CompletePlayerCallBack, UpdatePlayerCallBack} from "./NavMeshUtils";
import {INavMeshContainer} from "./INavMeshContainer";
import {INavMeshPlayer} from "./INavMeshPlayer";


const NAVMESH_COLOR =  new THREE.Color( 0xFFFFFF );
const ZONE = 'level';

export class NavMeshContainer extends INavMeshContainer{

    // private readonly _navMesh: THREE.Mesh;
    private readonly _pathFinder:Pathfinding;
    private readonly _zone:any;
    // private readonly _oldNavmeshId:number;

    constructor(private scene: THREE.Scene, theMesh:THREE.Mesh, private zoneName:string = ZONE) {

        super( theMesh);
        this._pathFinder = new Pathfinding();
        this._zone = Pathfinding.createZone( this._navMesh.geometry);
        this._pathFinder.setZoneData(this.zoneName,this._zone);
    }

    createPlayer =(player: THREE.Object3D,onInitCallBack:UpdatePlayerCallBack = null,onStartPlayerCallBack:UpdatePlayerCallBack = null,
                   onUpdatePlayerCallBack:UpdatePlayerCallBack = null,
                   onCompletePlayerCallBack:CompletePlayerCallBack = null): INavMeshPlayer => {
        return new NavMeshPlayer(this,player,this._pathFinder,this.zoneName,this._navMesh.position,onInitCallBack,onStartPlayerCallBack,onUpdatePlayerCallBack,onCompletePlayerCallBack);
    }

    get navMesh(): THREE.Mesh {
        return this._navMesh;
    }

    get oldNavmeshId(): number {
        return this._oldNavmeshId;
    }
}
