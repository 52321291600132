import {MATERIAL_PROPS_DB} from "../../../client-data/MaterialPropsDB";


class MaterialPropsController {

    static getMaterialPropsByName(materialName) {
        const record = MATERIAL_PROPS_DB.filter(rec => rec.materialName === materialName);
        if (record && record.length > 0) {
            return(MaterialPropsController.populateMaterialProps(record[0]));
        }

        return null;
    }

    static populateMaterialProps(record) {
        return record; //for now just return the record
    }

}

export default MaterialPropsController;
