import React from "react";
import Modal from "react-bootstrap/Modal";
import VideoComponent from "../../util/VideoComponent";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";

const VideoFullScreenModal = (props) => {
    // console.log("[VideoFullScreenModal] is called", props.videoData);
    return (
        !props.videoData ?
            null
            :
            <Modal dialogClassName={"modal-dialog-centered"}
                   backdrop="static"
                   show={props.show}
                   animation={true}
                   onHide={props.onHideModal} size={props.modelType}>
                <Modal.Header closeButton style={{border: 'none'}}>
                    {/*<Modal.Title>{props.videoData.headerText}</Modal.Title>*/}
                </Modal.Header>
                <Modal.Body>
                    <h2 className={'vides-displayer-title'}>{props.videoData.headerText}</h2>
                    <div className="video-style">
                        <video playsInline controls width="100%">
                            <source id={props.videoData.videoId} src={props.videoData.videoUrl} type="video/mp4"/>
                            <source src={props.videoData.videoUrl} type="video/ogg"/>
                            Your browser does not support HTML video.
                        </video>
                    </div>

                </Modal.Body>
            </Modal>

    )
}

export default VideoFullScreenModal;