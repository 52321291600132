import MaterialPropsController from "../MaterialPropsController";
import * as THREE from "three";


export class MaterialPropProcessor {

    private mpmRecords: Record<number, THREE.MeshPhysicalMaterial> = {};
    private oldMatRecords: Record<number, THREE.Material> = {};
    private _enableUpdate:boolean = true;

    constructor(public envMap: THREE.Texture) {
    }


    updateMaterialProps = (material: THREE.MeshStandardMaterial, node: THREE.Mesh): boolean => {
        //console.log("updateMaterialProps begin",material);
        if (!this.enableUpdate) return false;

        const matProp = MaterialPropsController.getMaterialPropsByName(material.name);
        let processed = false;
        if (matProp) {
            if ((matProp.convertToBasicMaterial || 'N') === 'Y') {
                let mpmMat = this.mpmRecords[material.id];
                if (!mpmMat) {
                    const basicMat = new THREE.MeshBasicMaterial({});
                    basicMat.name = material.name + "-basic";
                    basicMat.alphaMap = material.alphaMap;
                    basicMat.aoMap = material.aoMap;
                    basicMat.aoMapIntensity = material.aoMapIntensity;
                    basicMat.map = material.map;
                    basicMat.toneMapped = material.toneMapped;
                    basicMat.transparent = material.transparent;
                    basicMat.opacity = material.opacity;
                    node.material = basicMat;

                    // @ts-ignore
                    this.mpmRecords[material.id] = basicMat;
                    this.oldMatRecords[material.id] = material;

                    // @ts-ignore
                    material = basicMat;


                    // console.log("updateMaterialProps=", basicMat);
                }
                else {
                    node.material = mpmMat;
                }

            }
            if (matProp.glass) {
                let mpmMat = this.mpmRecords[material.id];
                if (!mpmMat) {
                    mpmMat = matProp.glassMatFunc(this.envMap);
                    this.mpmRecords[material.id] = mpmMat;
                    // console.log("updateMaterialProps: create Glass-",material.name, node)
                }
                this.oldMatRecords[material.id] = material;
                node.material = mpmMat;
                processed = true;
                // console.log("updateMaterialProps: update Glass-",material.name, node)

            }
            else {

                if (matProp.aOIntensity) {
                    material.aoMapIntensity = matProp.aOIntensity;
                    processed = true;
                }

                if (matProp.emmisiveColor) {
                    material.emissive = matProp.emmisiveColor;
                    processed = true;
                }
                if (matProp.emmissiveIntensity) {
                    material.emissiveIntensity = matProp.emmissiveIntensity;
                    processed = true;
                }

                if (matProp.envMapIntensity) {
                    material.envMapIntensity = matProp.envMapIntensity;
                    processed = true;
                }

                if (matProp.color) {
                    material.color = matProp.color;
                    processed = true;
                }

                if ((matProp.useMapAsLightMap || 'N') === 'Y') {
                    material.lightMap = material.map;
                    if (matProp.lightMapIntensity) {
                        material.lightMapIntensity = matProp.lightMapIntensity;
                    }
                    processed = true;
                }

                if (matProp.hasOwnProperty("roughness")) {
                    material.roughness = matProp.roughness;
                    processed = true;
                }

                if (matProp.hasOwnProperty("metalness")) {
                    material.metalness = matProp.metalness;
                    processed = true;
                }

                if (matProp.toneMapped) {
                    material.toneMapped = matProp.toneMapped === 'Y';
                    processed = true;
                }
            }

        }
        return processed;
    }

    cleanOldMaterials = ()=> {
        for (const key of Object.keys(this.oldMatRecords)) {
            const value = this.oldMatRecords[Number.parseInt(key)];
            if (value) {
                // console.log("MaterialPropProcessor delte not used mat:", value.name);
                value.dispose();
                delete this.oldMatRecords[Number.parseInt(key)];
            }
        }
    }

    cleanup = ()=> {
        for (const key of Object.keys(this.mpmRecords)) {
            const value = this.mpmRecords[Number.parseInt(key)];
            if (value) {
                value.dispose();
                delete this.mpmRecords[Number.parseInt(key)];
            }
        }
    }

    get enableUpdate(): boolean {
        return this._enableUpdate;
    }

    set enableUpdate(value: boolean) {
        this._enableUpdate = value;
    }

}
