import React, {forwardRef, useEffect} from "react";
import {Tooltip} from "react-bootstrap";

export const getToolTipMsg = (toolTipType) => {

    switch (toolTipType) {
        case "hamburger":
            return "Main Menu"
        case 'vreality':
            return "Virtual Reality"
        case 'help':
            return "Hints & Tips"
        case 'wscreen':
            return "Exit Full Screen"
        case 'fullscreen':
            return "Full Screen"
        case 'resetview':
            return "Reset View"
        case 'toggleAnnotationHide':
            return "Hide Tag"
        case 'toggleAnnotationShow':
            return "Show Tag"
        case 'floor_plan':
            // return "Getting Around"
            return "Go To"
        case 'hide_all_controls':
            return 'Hide Controls & Menu'
        case 'unhide_all_controls':
            return 'Show Controls & Menu'
        case 'pauseBackgroundAudio':
            return 'Mute Background Audio'
        case 'playBackgroundAudio':
            return 'Play Music/Video'
        case 'zoom_display':
            return 'Zoom Workshops';
        case 'about_us':
            return 'Acknowledgements';
        case '3d-viewer-parts':
            return `Scroll to view and select.` ;
        case '3d-viewer-objects':
            return `Scroll to view and select.`;
        default:
            return toolTipType
    }
}






const DisplayTooltip = forwardRef((props,ref) => {
    // console.log("[DisplayTooltip is running!]");
    return (
        <Tooltip id={props.tooltip_type + "button-tooltip"} {...props} ref={ref}>
            {getToolTipMsg(props.tooltip_type)}
        </Tooltip>
    );
})
export default DisplayTooltip;