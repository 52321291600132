import {ButtonState, VideoMeshButton} from "./VideoMeshButton";
import * as THREE from "three";

type VideoGroupCallback = ((buttonState: ButtonState) => void) | null;

export class VideoGroupMeshButton extends VideoMeshButton {

    private audioVideoCount = 0;
    private audioVideoPlaying = 0;
    private callBacks: Record<string, VideoGroupCallback> = {}
    private videoGroupMeshId: string;
    private playingStates: Record<string, ButtonState> = {};
    private buttonPlayingState: ButtonState = ButtonState.PAUSE;

    constructor(protected scene: THREE.Scene, public button: THREE.Mesh , pauseImage: THREE.Texture) {
        super(scene, button, pauseImage);
        this.videoGroupMeshId = button.userData.videoGroupButtonId;
    }

    addCallBack(callBack: VideoGroupCallback, paintingId: string) {
        this.callBacks[paintingId] = callBack;
        this.audioVideoCount++;
    }

    buttonClicked = () => {
        const pState = this.buttonPlayingState === ButtonState.PAUSE ? ButtonState.PLAY : ButtonState.PAUSE
        for (const [key, value] of Object.entries(this.callBacks)) {

            if (value) {

                value(pState);
            }
        }
    }

    public setStatePause = (paintingId: string) => {

        this.playingStates[paintingId] = ButtonState.PLAY;
        this.buttonPlayingState = ButtonState.PLAY;
        this.animateButton(this.button, ButtonState.PAUSE, null);


    }

    public setStatePlay = (paintingId: string) => {

        this.playingStates[paintingId] = ButtonState.PAUSE;
        for (const [key, value] of Object.entries(this.playingStates)) {
             if (value !== ButtonState.PAUSE) return

        }
        this.buttonPlayingState = ButtonState.PAUSE;
        this.animateButton(this.button, ButtonState.PLAY, null);

    }
}
