import * as THREE from "three";
import {RGBELoader} from "three/examples/jsm/loaders/RGBELoader"
import {
    EnvironmentMapsLoaderDoubleResults,
    EnvironmentMapsType,
    EnvironmentMapsTypes,
    EnvironmentOptionsType, EnvironmentTextureDoubleResults
} from "../types/ViewerTypes";
import {EXRLoader} from "three/examples/jsm/loaders/EXRLoader"
import {environmentData} from "../../../client-data/ts/EnvironmentData";


export class EnvironmentLoader {

    private pmremGenerator: THREE.PMREMGenerator;

    constructor(private renderer: THREE.WebGLRenderer, private _manager?: THREE.LoadingManager) {

        this.pmremGenerator = new THREE.PMREMGenerator(renderer);
        this.pmremGenerator.compileEquirectangularShader();
    }

    static getEnvironmentData(envColorImage: string, backgroundImage: string): EnvironmentOptionsType {
        const colorImage = environmentData[envColorImage];
        const backImage = environmentData[backgroundImage];

        return {colorMap: colorImage, backgroundMap: backImage};
    }

    async getEnvironmentMaps(environmentOptions: EnvironmentOptionsType): Promise<EnvironmentMapsLoaderDoubleResults> {

        const colorMap = await this.getSingleMap(environmentOptions.colorMap);

        if (environmentOptions.colorMap.name === environmentOptions.backgroundMap.name) {
            return {colorMap: colorMap, backgroundMap: colorMap};
        }

        const backgroundMap = await this.getSingleMap(environmentOptions.backgroundMap);

        return {colorMap, backgroundMap};

    }

    async getSingleMap(environment: EnvironmentMapsType): Promise<EnvironmentMapsTypes> {
        try {
            if (environment.format === 'none') {
                return null
            } else if (environment.format === 'hdr') {
                return await this.getCubeMapHDRI(environment)
            } else if (environment.format === 'exr') {
                return await this.getCubeMapEXR(environment)
            }
            return null;
        } catch (error) {
            throw new Error(`Cannot find HDR ${environment}`);
        }

    }

    async getCubeMapHDRI(environment: EnvironmentMapsType): Promise<EnvironmentMapsTypes> {
        try {
            const texture = await new RGBELoader(this.manager).loadAsync(environment.path)
            const envMap = this.pmremGenerator.fromEquirectangular(texture).texture;
            this.pmremGenerator.dispose();
            texture.dispose();
            return envMap;
        } catch (error) {
            throw new Error(`Cannot find HDR ${environment}`);
        }
    }

    async getCubeMapEXR(environment: EnvironmentMapsType): Promise<EnvironmentMapsTypes> {
        try {
            const texture = await new EXRLoader(this.manager).setDataType(THREE.UnsignedByteType).loadAsync(environment.path)
            const envMap = this.pmremGenerator.fromEquirectangular(texture).texture;
            this.pmremGenerator.dispose();
            texture.dispose();
            return envMap;
        } catch (error) {
            throw new Error(`Cannot find EXR ${environment}`);
        }
    }


    get manager(): THREE.LoadingManager | undefined {
        return this._manager;
    }

    set manager(value: THREE.LoadingManager | undefined) {
        this._manager = value;
    }

    /*async getCubeMapHDRITexture(environment: EnvironmentMapsType): Promise<THREE.DataTexture> {
        try {
            const texture = await new RGBELoader(this.manager).loadAsync(environment.path)

            return texture;
        } catch (error) {
            throw new Error(`Cannot find Texture HDR ${environment}`);
        }
    }

    async getCubeMapEXRTexture(environment: EnvironmentMapsType): Promise<THREE.DataTexture> {

        try {
            const texture = await new EXRLoader(this.manager).setDataType(THREE.UnsignedByteType).loadAsync(environment.path)

            return texture;
        } catch (error) {
            throw new Error(`Cannot find Texture exr ${environment}`);
        }
    }

    async getSingleTexture(environment: EnvironmentMapsType): Promise<THREE.DataTexture | null> {
        try {
            if (environment.format === 'none') {
                return null
            } else if (environment.format === 'hdr') {
                return await this.getCubeMapHDRITexture(environment)
            } else if (environment.format === 'exr') {
                return await this.getCubeMapEXRTexture(environment)
            }
            return null;
        } catch (error) {
            throw new Error(`Cannot find HDR Texture ${environment}`);
        }

    }

    async getEnvironmentTexture(environmentOptions: EnvironmentOptionsType): Promise<EnvironmentTextureDoubleResults> {

        const colorTexture = await this.getSingleTexture(environmentOptions.colorMap);

        if (environmentOptions.colorMap.name === environmentOptions.backgroundMap.name) {
            return {colorTexture: colorTexture, backgroundTexture: colorTexture};
        }

        const backgroundTexture = await this.getSingleTexture(environmentOptions.backgroundMap);

        return {colorTexture: colorTexture, backgroundTexture:backgroundTexture};

    }*/
}
