// export const ANNOTATION_TEXT_ALIGNMENT = "justify";
// export const ANNOTATION_TEXT_LINE_HEIGHT = "1.5";


import VideosMeshButtonObj from "../../components/videos/ts/VideosMeshButtonObj";

const VideosDB: VideosMeshButtonObj[] = [
    // // Example
    // {
    //     meshButtonID: "HKT_P001",
    //     videosList: [
    //         {
    //             videoId: 'SWK_V007',
    //             videoUrl: 'SWK_V007.mp4',
    //             thumbnailUrl: `SWK_V007.jpg`,
    //             artistName: `Liz Torc`,
    //             headerText: `The Haiflu Project`,
    //             moreInfo: null,
    //             bodyText: null,
    //             datePublished: null,
    //             videoElement: 'SWK_V007',
    //         },
    //     ],
    // },
];

export default VideosDB;
