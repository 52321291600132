import React, {useEffect, useRef, useState} from 'react';
import ThumbnailImages from "./ThumbnailImages";
import {OverlayTrigger, Tooltip} from "react-bootstrap/";
import DisplayTooltip from "../../client-data/DisplayTooltip";
import {THREE_D_VIEWER_TOOLTIP, USE_THREE_D_VIEWER_BORDER_BOX} from "../../client-data/clientOptions";
import ThumbnailImagesWithTooltips from "./ThumbnailImagesWithTooltips";


export const HorizontalThumbnailScrollBar = (props) => {
    // const aRef = useRef(null);
    const thumbnailSliderElement = useRef();
    const [showTooltip,setShowTooltip] = useState(false);


    const onClickThumbnailHandler = (index) => {
        console.log("HorizontalThumbnailScrollBar - index:", index);
        props.onClick(index);
    }

    const onClickArrowHandler = (direction) => {
        let newPosition = thumbnailSliderElement.current.scrollLeft;
        newPosition += direction * 30;
        thumbnailSliderElement.current.scrollLeft = newPosition;
        // console.log("[HorizontalThumbnailScrollBar]", newPosition);


    }

    useEffect(() => {
        // const element = document.getElementById("thumbnail-slider");
        // if(thumbnailSliderElement) {
        //     console.log("[HorizontalThumbnailScrollBar]", thumbnailSliderElement.current.clientWidth);
        // }

        const timeoutID = setTimeout(() => {setShowTooltip(false)}, 1000);

        return () => {
            clearTimeout(timeoutID);
        }

    }, [showTooltip]);

    return (
        // <OverlayTrigger
        //     overlay={<DisplayTooltip ref={aRef} tooltip_type={props.toolTipTxt} />}
        //     show={showTooltip}
        //     placement={props.placement}
        // >
            <div className={USE_THREE_D_VIEWER_BORDER_BOX ? "horizontal-thmbnl-slider-container three-d-in-box" : "horizontal-thmbnl-slider-container"}
                 onMouseEnter={()=> setShowTooltip(true)}
            >
                <div className="three-d-gradient-left"></div>
                <div className="three-d-gradient-right"></div>
                <div className="arrow arrow-circle arrow-circle-left" onClick={() => onClickArrowHandler(-1)}>
                    <div className="arrow arrow-left">&#10094;</div>
                </div>
                <div className="arrow arrow-circle arrow-circle-right" onClick={() => onClickArrowHandler(1)}>
                    <div className="arrow arrow-right">&#10095;</div>
                </div>
                <div className="thumbnail-horizontal-slider three-d-scrollbar"
                     id="thumbnail-slider"
                     ref={thumbnailSliderElement}
                >
                    <ThumbnailImagesWithTooltips thumbnailImages={props.thumbnailSlides}
                                     prefix={props.prefix}
                                     itemIndex={props.itemIndex}
                                     onClick={onClickThumbnailHandler}
                                     scrollbarOrientaion={props.scrollbarOrientaion}
                                     showTooltip={true}
                    />
                </div>
            </div>
        // </OverlayTrigger>
    )

}