import React, {Component} from 'react';
import ContactLink from "./ContactLink";

import Modal from "react-bootstrap/Modal";

import DisplayMediaComponentProxy from "./DisplayMediaComponentProxy";
import OnlyMediaCarousel from "./OnlyMediaCarousel";
import ContactLinksModal from "./ContactLinksModal";

class AboutUsModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            // isShowInSitu: false,
            showMediaCarousel: false,
        }

        this.showMediaCarouselHandler = this.showMediaCarouselHandler.bind(this);
        this.mediaCarouselCloseBtnHandler = this.mediaCarouselCloseBtnHandler.bind(this);
    }

    onShow = () => {
        this.setState({
            ...this.props.annotationData
        })

        //This is part of the Google Analytics Module
        // console.log("ReactGa", this.props.annotationData.paintingId);
        // ReactGa.event({
        //     category: 'Annotation',
        //     action: `User clicked an annotation ${this.props.annotationData.paintingId}`,
        // })
    }

    showMediaCarouselHandler = () => {
        // this.setState((state) => ({isShowInSitu: true}))
        this.setState((state) => ({showMediaCarousel: true}))

    }

    mediaCarouselCloseBtnHandler = () => {
        //this.setState({isShowInSitu: false});
        this.setState({showMediaCarousel: false});
    }

    render() {

        let hasMediaCarouselLink = null;
        if (this.state.hasMediaCarousel === "Y") {
            hasMediaCarouselLink =
                <ContactLink key={`hasMediaCarouselLink_${this.props.annotationData.paintingId}`}
                             contactName={this.props.annotationData.mediaCarouselLinkLabel} isVerticalBar={false}
                             hrefLink="#" onClickHandler={this.showMediaCarouselHandler}/>
        }

        if (this.state.showMediaCarousel) {
            /* Show a carousel page*/
            // console.log("[AnnotationModal] images length", this.props.annotationData.images.length);
            return (
                <OnlyMediaCarousel
                    annotationData={this.props.annotationData}
                    showMediaCarousel={this.state.showMediaCarousel}
                    mediaCarouselCloseBtnHandler={this.mediaCarouselCloseBtnHandler}
                    annotationModalSize={this.props.annotationModalSize}
                />
            )

        } else {

            return (

                <div className="annotation-modal">
                    <Modal
                        dialogClassName={"primaryModal modal-dialog annotation-modal-classname modal-dialog-scrollable"}
                        show={this.props.show} onShow={() => this.onShow()}
                        onHide={this.props.close} size={this.props.size} centered={true}
                        backdrop="static">
                        <Modal.Header closeButton style={{border: 'none'}}>
                        </Modal.Header>
                        <Modal.Body>
                            <div>
                                <h5 align={"center"}><b>Acknowledgements</b></h5>
                                {/*<div className="about-us-txt">*/}
                                {/*    A great deal of work has gone into creating the ECCB’s 40th Anniversary Virtual Museum, and we take this opportunity to acknowledge and express gratitude to the following:<br/>*/}
                                {/*    V21 Artspace for building and designing the virtual museum<br/>*/}
                                {/*    <div className="about-us-txt-sub-title">*/}
                                {/*        <b>Contributions from the ECCB:</b>*/}
                                {/*    </div>*/}
                                {/*    Valencia Grant, Virtual Museum Research Lead and Curator<br/>*/}
                                {/*    Marvin Jones, Virtual Museum Technical Lead and Design Liaison<br/>*/}
                                {/*    Other Members of the ECCB’s 40th Anniversary Planning Committee and Secretariat:<br/>*/}
                                {/*    Chairperson, Dr. Valda F. Henry (Deputy Governor of the ECCB)<br/>*/}
                                {/*    Leon Bullen<br/>Beverley Edwards-Gumbs<br/>*/}
                                {/*    Kereem Ferrol<br/>*/}
                                {/*    Marie-Louise Francis<br/>*/}
                                {/*    Vanessa Francis-Pennyfeather<br/>*/}
                                {/*    Benedict John<br/>*/}
                                {/*    Ashanda Lapsey<br/>*/}
                                {/*    Adaeze Matthew-Hanley<br/>*/}
                                {/*    Everton Sealy<br/>*/}
                                {/*    Jehu Sedra<br/>*/}
                                {/*    Gillian Skerritt<br/>*/}
                                {/*    Cosilia St. Louis-Jeremiah<br/>*/}
                                {/*    <div className="about-us-txt-sub-title">*/}
                                {/*        <b>Special thanks to:</b>*/}
                                {/*    </div>*/}
                                {/*    ECCB Governor, Timothy N.J. Antoine<br/>*/}
                                {/*    Chief Director (Policy), D. Tracy Polius<br/>*/}
                                {/*    Acting Director of the Corporate Relations Department (CRD), Shermalon Kirby<br/>*/}
                                {/*    CRD Officers (in particular Marlon Bristol, Shana Derrick and Brentwood Drigo)<br/>*/}
                                {/*    Director of the Currency Management Department, Rosbert Humphrey<br/>*/}
                                {/*    Acting Director of the Human Resources Department, Merva Mallalieu<br/>*/}
                                {/*    ECCB Library Staff: Carmen Blanchard-Browne and Shaundel Jones<br/>*/}
                                {/*    Members of the Marketing, Communications and Public Relations (MCPR) Subcommittee of the 40th Anniversary Planning Committee (not already acknowledged above):<br/>*/}
                                {/*    Aurin Heyliger<br/>*/}
                                {/*    Nevine Harvey<br/>*/}
                                {/*    Sheena Gonsalves<br/>*/}
                                {/*    Steven Neckles<br/>*/}
                                {/*    ECCB Employees and Retirees who participated in our special 40th Anniversary video productions (to be posted over the course of several months)<br/>*/}
                                {/*    <div className="about-us-txt-sub-title">*/}
                                {/*        <b>Other Contributions:</b>*/}
                                {/*    </div>*/}
                                {/*    Research assistance provided by the following external partners:<br/>*/}
                                {/*    Assistant Archivist at the St. Kitts & Nevis National Archives, Mavis Johnson-Vaughan<br/>*/}
                                {/*    Other Employees of the National Archives at Government Headquarters: Ranetta Browne-Belle,<br/>*/}
                                {/*    Shaylyn Gibson and Kyle Maynard<br/>*/}
                                {/*    Staff at The Charles A. Halbert Public Library<br/>*/}
                                {/*    Editor of The Labour Spokesman, Precious Mills<br/>*/}
                                {/*    Production and videography assistance provided by the following external partner:<br/>*/}
                                {/*    Cleon Bradshaw<br/>*/}
                                {/*    <div className="about-us-txt-sub-title">*/}
                                {/*        <b>Primary Sources:</b>*/}
                                {/*    </div>*/}
                                {/*    Eastern Caribbean Central Bank<br/>*/}
                                {/*    The Democrat Newspaper<br/>*/}
                                {/*    The Labour Spokesman Newspaper<br/>*/}
                                {/*    Archival photographs (to be posted) provided by Denzil (Don) James, Earl Smithen and Corinne Telfer-James*/}
                                {/*</div>*/}

                                <div className="about-us-txt">
                                    <p>
                                        A great deal of work has gone into creating the ECCB’s 40th Anniversary Virtual
                                        Museum, and we take this opportunity to acknowledge and express gratitude to the
                                        following:<br/>
                                        <i>V21 Artspace</i> for building and designing the virtual museum
                                    </p>

                                    <div className="about-us-txt-sub-title">
                                        <b>Contributions from the ECCB:</b>
                                    </div>
                                    <p>Valencia Grant, Virtual Museum Research Lead and Curator</p>
                                    <p>
                                        Marvin Jones, Virtual Museum Technical Lead and Design Liaison
                                    </p>
                                    <p>
                                        Other Members of the ECCB’s 40th Anniversary Planning Committee and Secretariat:<br/>
                                        Chairperson, Dr. Valda F. Henry (Deputy Governor of the ECCB)<br/>
                                        Acklyn Blaize<br/>
                                        Leon Bullen<br/>
                                        Beverley Edwards-Gumbs<br/>
                                        Kereem Ferrol<br/>
                                        Lisa Francis<br/>
                                        Marie-Louise Francis<br/>
                                        Vanessa Francis-Pennyfeather<br/>
                                        Benedict John<br/>
                                        Ashanda Lapsey<br/>
                                        Adaeze Matthew-Hanley<br/>
                                        Everton Sealy<br/>
                                        Jehu Sedra<br/>
                                        Gillian Skerritt<br/>
                                        Cosilia St. Louis-Jeremiah<br/>
                                    </p>
                                    <div className="about-us-txt-sub-title">
                                        <b>Special thanks to:</b>
                                    </div>
                                    <p>
                                        ECCB Governor, Timothy N.J. Antoine
                                    </p>
                                    <p>
                                        Chief Director (Policy), D. Tracy Polius
                                    </p>
                                    <p>
                                        Director of the Corporate Relations Department (CRD), Shermalon Kirby
                                    </p>
                                    <p>
                                        CRD Officers (in particular Marlon Bristol, Shana Derrick and Brentwood Drigo)
                                    </p>
                                    <p>
                                        Director of the Currency Management Department, Rosbert Humphrey
                                    </p>
                                    <p>
                                        Acting Director of the Human Resources Department, Merva Mallalieu
                                    </p>
                                    <p>
                                        ECCB Library Staff: Carmen Blanchard-Browne and Shaundel Jones
                                    </p>
                                    <p>
                                        Senior Project Specialist, Sybil Welsh
                                    </p>
                                    <p>
                                        Policy Specialist, Patricia Welsh
                                    </p>
                                    {/*<p>*/}
                                    {/*    Members of the Marketing, Communications and Public Relations (MCPR) Subcommittee of the 40th Anniversary Planning Committee (not already acknowledged above):<br/>*/}
                                    {/*    Aurin Heyliger<br/>*/}
                                    {/*    Nevine Harvey<br/>*/}
                                    {/*    Sheena Gonsalves<br/>*/}
                                    {/*    Steven Neckles<br/>*/}
                                    {/*</p>*/}
                                    <p>
                                        ECCB Employees and Retirees who participated in our special 40th Anniversary
                                        video productions
                                    </p>

                                    <div className="about-us-txt-sub-title">
                                        <b>Other Contributions:</b>
                                    </div>
                                    <p>
                                        Research assistance provided by the following external partners:<br/>
                                        Assistant Archivist at the St. Kitts & Nevis National Archives, Mavis
                                        Johnson-Vaughan<br/>
                                        Other Employees of the National Archives at Government Headquarters: Ranetta
                                        Browne-Belle,
                                        Shaylyn Gibson and Kyle Maynard<br/>
                                        Staff at The Charles A. Halbert Public Library<br/>
                                        Editor of The Labour Spokesman, Precious Mills<br/>
                                    </p>
                                    <p>
                                        Production and videography assistance provided by the following external
                                        partner:
                                        <br/>Cleon Bradshaw
                                    </p>
                                    <p>
                                        Three-dimensional bust of Nobel Laureate Sir Arthur Lewis made by Oyehmi T.
                                        Begho of Evolution Media Lab
                                    </p>

                                    <div className="about-us-txt-sub-title">
                                        <b>Primary Sources:</b>
                                    </div>
                                    <p>
                                        Eastern Caribbean Central Bank
                                    </p>
                                    <p>
                                        The Democrat Newspaper
                                    </p>
                                    <p>
                                        The Labour Spokesman Newspaper
                                    </p>
                                    <p>The Grenada Informer</p>
                                    <p>The Grenadian Voice</p>
                                    <p>
                                        Archival photographs provided by Denzil (Don) James and Earl Smithen
                                    </p>
                                    <p>
                                        Members of the Budget Subcommittee of the 40th Anniversary Planning Committee
                                        chaired by Adaeze Matthew-Hanley:
                                    </p>
                                    Nelisa Anthony
                                    <br/>Sharon Tisson-Bennett
                                    <br/>Micah Browne
                                    <p>
                                        Members of the Community Outreach Subcommittee of the 40th Anniversary Planning
                                        Committee co-chaired by Kereem Ferrol and Benedict John:
                                    </p>
                                    Nigel Browne
                                    <br/>Lisa Francis
                                    <br/>Marie-Louise Francis
                                    <br/>Sheon John
                                    <br/>Michael Morgan
                                    <br/>Florence Palmer
                                    <br/>Sasha Richards
                                    <p>
                                        Members of the Entertainment Subcommittee of the 40th Anniversary Planning
                                        Committee chaired by Cosilia St. Louis-Jeremiah:
                                    </p>
                                    Acklyn Blaize
                                    <br/>Schwabach Caines
                                    <br/>Shana Derrick
                                    <br/>Leon Esprit
                                    <br/>Kereem Ferrol
                                    <br/>Heidi Hypolite
                                    <br/>Merva Mallalieu
                                    <br/>Marissa Richardson-Orchard
                                    <br/>Laurel Seraphin-Bedford
                                    <br/>Sybil Welsh
                                    <br/>Jacob Wilson
                                    <p>
                                        Members of the Family Fun Day Subcommittee of the 40th Anniversary Planning
                                        Committee chaired by Beverley Edwards-Gumbs:
                                    </p>
                                    Livia Bertin-Mark
                                    <br/>Acklyn Blaize
                                    <br/>Emma Felix
                                    <br/>Andrea Gilbert-Bullen
                                    <br/>Darnell Morton
                                    <br/>Gillian Skerritt
                                    <br/>Elizabeth Wayland-Phillip
                                    <p>
                                        Members of the Gala Subcommittee of the 40th Anniversary Planning Committee
                                        chaired by Beverley Edwards-Gumbs:
                                    </p>
                                    Livia Bertin-Mark
                                    <br/>Acklyn Blaize
                                    <br/>Emma Felix
                                    <br/>Andrea Gilbert-Bullen
                                    <br/>Darnell Morton
                                    <br/>Gillian Skerritt
                                    <br/>Elizabeth Wayland-Phillip
                                    <p>
                                        Members of the Lecture Subcommittee of the 40th Anniversary Planning Committee
                                        chaired by Everton Sealy:
                                    </p>
                                    Angela Estwick
                                    <br/>Linda Felix-Berkeley
                                    <br/>Karel Forde-Harrigan
                                    <br/>Kozel Fraser
                                    <br/>Sherma John
                                    <br/>Shirmaine Lynch-Harrigan
                                    <p>
                                        Members of the Logistics Subcommittee of the 40th Anniversary Planning Committee
                                        chaired by Ashanda Lapsey:
                                    </p>
                                    Seana Benjamin-Mack
                                    <br/>Acklyn Blaize
                                    <br/>Lydia Blanchette
                                    <br/>Corinne Browne
                                    <br/>Karel Forde-Harrigan
                                    <br/>Benedict John
                                    <br/>Marvin Jones
                                    <br/>Tamara Powell
                                    <br/>Valene Streete
                                    <p>
                                        Members of the Marketing, Communications and Public Relations (MCPR)
                                        Subcommittee of the 40th Anniversary Planning Committee chaired by Valencia
                                        Grant:
                                    </p>
                                    Brentwood Drigo
                                    <br/>Sheena Gonsalves
                                    <br/>Nevine Harvey
                                    <br/>Aurin Heyliger
                                    <br/>Steven Neckles
                                    <br/>Jehu Sedra (Deputy Chair)
                                    <p>
                                        Members of the Youth Subcommittee of the 40th Anniversary Planning Committee
                                        chaired by Leon Bullen:
                                    </p>
                                    Delishir Archibald
                                    <br/>Samora Cherebin
                                    <br/>Kayla Farrell
                                    <br/>Tessa Marius-Gilbert
                                    <br/>Bryan Vidal


                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>

                /* */
            )

        }
    }
}

export default AboutUsModal;