import React, {Component, useState} from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import DisplayTooltip from "../../client-data/DisplayTooltip";

class V21NavItem extends Component {

    constructor(props) {
        super(props);
        this.aRef = React.createRef();
        this.state = {
            open: false,
            stopAnimation: false,
        }
    }

    itemOnClick(evt) {
        const openTmp = !this.state.open;
        this.setState(
            {
                open: openTmp,
                stopAnimation: true
            }
        )
        if (this.props.onClick) {
            this.props.onClick(evt);
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        // console.log("[V21NavItem/componentDidUpdate] prevProps]", prevProps);

        console.log("[V21NavItem]", this.props.icon, this.props.animate);
        if (this.props.closeMenu) {
            if (this.props.closeMenu !== prevProps.closeMenu) {
                if (this.props.closeMenu) {
                    this.setState(
                        {
                            open: !this.props.closeMenu
                        }
                    )
                }
                if (this.props.setCloseMenuToFalse) {
                    this.props.setCloseMenuToFalse();
                }
            }
        }
    }

    render() {

        return (
            <div id="V21NavItemId">
                <li className="v21-nav-item">
                    {/* If the item is the main menu, then don't show a tooltip*/}
                    {this.props.tooltip_type === "hamburger" ?
                        <button className="v21-button-menu-item" onClick={(evt) => {
                            this.itemOnClick(evt)
                        }} >
                            <img src={this.props.icon} className="icon-button"/>
                        </button>
                        :
                        <OverlayTrigger
                            placement="top"
                            delay={{show: 150, hide: 200}}
                            overlay={<DisplayTooltip ref={this.aRef} tooltip_type={this.props.tooltip_type} />}
                            >
                            <button className="v21-button-menu-item" onClick={(evt) => {
                                this.itemOnClick(evt)
                            }}>
                                {/* {this.props.icon}*/}
                                {this.props.animate ?
                                    <img src={this.props.icon} className={`icon-button ${this.state.stopAnimation ? '': 'animate-me'}`}/>
                                :
                                    <img src={this.props.icon} className="icon-button"/>
                                }

                            </button>
                        </OverlayTrigger>
                    }

                    {this.state.open && this.props.children}
                </li>
            </div>
        );
    }
}

export default V21NavItem;
