import {Modal3DCompareType} from "../../components/Viewer3DModal/types/ViewerTypes";
import * as THREE from "three";

export const Modal3DCompareDB: Array<Modal3DCompareType> = [
    {
        "id1": "01",
        "id2": "02",
       /* position: new THREE.Vector3(),
        rotation: new THREE.Euler(),*/

        "en": {
            "title": "<b>cf. Bartolini - terra cruda</b>",
            "tooltipTxt": "Bartolini - terra cruda",
            "audioURL": null,
            "description": "Thorvaldsen's bust of Lord Byron depicted the poet in a neoclassical tradition, emphasising an idealised form of beauty associated with Emperors of the Classical World. In contrast, Bartolini's depiction of Byron emphasised the poet's realism. You can see how the angle of the poet's chin differs, as does the shape of his neck and his brow line."
        },
        "it": {
            "title": "<b>cf. Bartolini - terra cruda</b>",
            "tooltipTxt": "Bartolini - terra cruda",
            "audioURL": null,
            "description": "Il busto di Thorvaldsen di Lord Byron ritrae il poeta secondo la tradizione neoclassica, enfatizzando una forma idealizzata di bellezza associata agli imperatori del mondo classico. Al contrario, la raffigurazione di Byron di Bartolini esalta l'aspetto reale del poeta. Da notare come l'angolo del mento del poeta sia diverso, così come la forma del collo e la linea delle sopracciglia."
        }
    },
    {
        "id1": "01",
        "id2": "03",
        "en": {
            "title": "<b>cf. Bartolini - marble</b>",
            "tooltipTxt": "Bartolini - marble",
            "audioURL": null,
            "description": "Thorvaldsen's bust of Lord Byron depicted the poet in a neoclassical tradition, emphasising an idealised form of beauty associated with Emperors of the Classical World. Thorvaldsen's depiction shows Byron with a square jawline and shorter curls. In contrast, Bartolini's depiction of Byron emphasised the poet's realism and also opted for longer curls and sideburns, or whiskers. You can see how the angle of the poet's chin differs, as does the shape of his neck, and his brow line."
        },
        "it": {
            "title": "<b>cf. Bartolini - marble</b>",
            "tooltipTxt": "Bartolini - marble",
            "audioURL": null,
            "description": "Il busto di Thorvaldsen di Lord Byron ritrae il poeta secondo la tradizione neoclassica, enfatizzando una forma idealizzata di bellezza associata agli imperatori del mondo classico. Il ritratto di Thorvaldsen mostra Byron con una mascella squadrata e riccioli più corti. Al contrario, quello di Bartolini evidenzia le caratteristiche reali del poeta e adotta basette e ricioli più lunghi. Si può notare come l'angolo del mento del poeta sia diverso, così come la forma del collo e la linea delle sopracciglia."
        }
    },
    {
        "id1": "01",
        "id2": "04",
        "en": {
            "title": "<b>cf. Giovannozzi - marble</b>",
            "tooltipTxt": "Giovannozzi - marble",
            "audioURL": null,
            "description": "Thorvaldsen's neoclassical depiction of Byron is even more striking when compared with Giovannozzi's bust of the poet. Giovannozzi moved towards an even greater realism. In Giovannozzi's depiction Byron's nose is less delicate, his brows more furrowed, and the lines on his face have been made visible. "
        },
        "it": {
            "title": "<b>cf. Giovannozzi - marble</b>",
            "tooltipTxt": "Giovannozzi - marble",
            "audioURL": null,
            "description": "La rappresentazione neoclassica di Byron da parte di Thorvaldsen è ancora più sorprendente se confrontata con il busto del poeta di Giovannozzi. Giovannozzi si è spinto verso un realismo ancora maggiore. Nella raffigurazione di Giovannozzi il naso di Byron è meno delicato, le sopracciglia più aggrottate e le rughe del viso sono rese più evidenti."
        }
    },
    {
        "id1": "01",
        "id2": "05",
        "en": {
            "title": "<b>cf. Bartolini - plaster</b>",
            "tooltipTxt": "Bartolini - plaster",
            "audioURL": null,
            "description": "The survival of the plaster cast for the Bartolini bust shows how the final marble was made. Thorvaldsen's portrait would have used a similar process."
        },
        "it": {
            "title": "<b>cf. Bartolini - plaster</b>",
            "tooltipTxt": "Bartolini - plaster",
            "audioURL": null,
            "description": "La sopravvivenza del calco in gesso per il busto di Bartolini testimonia il modo in cui veniva realizzato il marmo finale. Il ritratto di Thorvaldsen sarebbe stato realizzato con un processo simile."
        }
    },
    {
        "id1": "02",
        "id2": "01",
        /* position: new THREE.Vector3(),
         rotation: new THREE.Euler(),*/

        "en": {
            "title": "<b>cf. Thorvaldsen - marble</b>",
            "tooltipTxt": "Thorvaldsen - marble",
            "audioURL": null,
            "description": "Bartolini's depiction of Lord Byron emphasised the poet's realism. In contrast, Thorvaldsen's earlier bust of Byron had depicted the poet in a neoclassical tradition, emphasising an idealised form of beauty associated with Emperors of the Classical World. You can see how the angle of the poet's chin differs, as does the shape of his neck and his brow line."
        },
        "it": {
            "title": "<b>cf. Thorvaldsen - marble</b>",
            "tooltipTxt": "Thorvaldsen - marble",
            "audioURL": null,
            "description": "La raffigurazione di Lord Byron fatta da Bartolini enfatizza i tratti realistici del poeta. Al contrario, il precedente busto di Byron realizzato da Thorvaldsen raffigurava il poeta secondo la tradizione neoclassica, esaltando una forma idealizzata di bellezza associata agli imperatori del mondo classico. Si può notare come l'angolo del mento del poeta sia diverso, così come la forma del collo e la linea delle sopracciglia."
        }
    },
    {
        "id1": "02",
        "id2": "03",
        "en": {
            "title": "<b>cf. Bartolini - marble</b>",
            "tooltipTxt": "Bartolini - marble",
            "audioURL": null,
            "description": "In the first stage of the bust's production, the making of the terra cruda, the artist's working methods are still visible. On the surface of the face, there are lines and marks made by the artist's tools. On the back of the head, a cast line is also visible. In contrast, the finished marble bust is smooth and does not show these working lines."
        },
        "it": {
            "title": "<b>cf. Bartolini - marble</b>",
            "tooltipTxt": "Bartolini - marble",
            "audioURL": null,
            "description": "Nella prima fase della produzione del busto, la realizzazione della terra cruda, sono ancora visibili i metodi di lavoro dell'artista. Sulla superficie del volto sono presenti linee e segni realizzati dagli strumenti dell'artista. Anche sul retro della testa è visibile la linea del calco. Al contrario, il busto in marmo finito è liscio e non presenta queste linee di lavorazione."
        }
    },
    {
        "id1": "02",
        "id2": "04",
        "en": {
            "title": "<b>cf. Giovannozzi - marble</b>",
            "tooltipTxt": "Giovannozzi - marble",
            "audioURL": null,
            "description": "Giovannozzi followed Bartolini's style and there are many similarities between their depictions of Lord Byron. In contrast to Thorvaldsen's bust, they both show the poet's features in a more realistic way, giving the poet side burns or whiskers, visible lines on his face, and a furrowed brow. "
        },
        "it": {
            "title": "<b>cf. Giovannozzi - marble</b>",
            "tooltipTxt": "Giovannozzi - marble",
            "audioURL": null,
            "description": "Giovannozzi seguì lo stile di Bartolini e i infatti due ritratti di Lord Byron sono molto simili tra loro. A differenza del busto di Thorvaldsen, entrambi mostrano i lineamenti del poeta in modo più realistico, ritraendolo con basette, rughe ben visibili sul viso e sopracciglia aggrottate."
        }
    },
    {
        "id1": "02",
        "id2": "05",
        "en": {
            "title": "<b>cf. Bartolini - plaster</b>",
            "tooltipTxt": "Bartolini - plaster",
            "audioURL": null,
            "description": "Both of these busts are working models used by the sculptor to create the final marble piece. While the terra cruda was sculpted largely by hand the plaster would have been cast. There is evidence of this process in the cast line across the back of the head which indicates where the two pieces of the mould were joined. The plaster model also had pins inserted at the extremeties, this was an aid for the carvers who would use the pins to make accurate measurements that helped them transfer the form into stone."
        },
        "it": {
            "title": "<b>cf. Bartolini - plaster</b>",
            "tooltipTxt": "Bartolini - plaster",
            "audioURL": null,
            "description": "Entrambi i busti costituiscono dei modelli di lavoro utilizzati dallo scultore per creare il pezzo finale in marmo. Mentre la terra cruda veniva scolpita principalmente a mano, il gesso veniva colato. La prova di questo processo è data dalla linea di calco che attraversa la parte posteriore della testa e che indica il punto in cui i due pezzi del calco venivano uniti. Il modello in gesso aveva anche dei perni inseriti alle estremità, un supporto per gli intagliatori che li usavano per prendere misure precise utili a trasferire la forma nella pietra."
        }
    },
    {
        "id1": "03",
        "id2": "01",
        "en": {
            "title": "<b>cf. Thorvaldsen - marble</b>",
            "tooltipTxt": "Thorvaldsen - marble",
            "audioURL": null,
            "description": "Bartolini's depiction of Lord Byron emphasised the poet's realism and opted for longer curls and sideburns, or whiskers. In contrast, Thorvaldsen's bust depicted the poet in a neoclassical tradition, emphasising an idealised form of beauty associated with Emperors of the Classical World.  Thorvaldsen's depiction shows Byron with a square jawline and shorter curls. You can see how the angle of the poet's chin differs, as does the shape of his neck, and his brow line. In the finished marble bust, Byron's features are slimmer than in the earlier terra cruda model, adjustments that would likely have been made in response to Byron's requests.",
        },
        "it": {
            "title": "<b>cf. Thorvaldsen - marble</b>",
            "tooltipTxt": "Thorvaldsen - marble",
            "audioURL": null,
            "description": "Il ritratto di Lord Byron eseguito da Bartolini sottolinea gli aspetti realistici del poeta adottando riccioli più lunghi e basette. Al contrario, il busto di Thorvaldsen rappresenta il poeta secondo la tradizione neoclassica, enfatizzando una forma idealizzata di bellezza associata agli imperatori del mondo classico.  La versione di Thorvaldsen mostra Byron con una mascella squadrata e riccioli più corti. Da notare come l'angolo del mento del poeta sia diverso, così come la forma del collo e la linea delle sopracciglia. Nel busto di marmo finito, i lineamenti di Byron sono più sottili rispetto al precedente modello in terra cruda, ritocchi che probabilmente sono stati fatti in risposta alle richieste di Byron.",
        }
    },
    {
        "id1": "03",
        "id2": "02",
        "en": {
            "title": "<b>cf. Bartolini - terra cruda</b>",
            "tooltipTxt": "Bartolini - terra cruda",
            "audioURL": null,
            "description": "In the first stage of the bust's production, the making of the terra cruda, the artist's working methods are still visible. On the surface of the face, there are lines and marks made by the artist's tools. On the back of the head, a cast line is also visible. In contrast, the finished marble bust is smooth and does not show these working lines.",
        },
        "it": {
            "title": "<b>cf. Bartolini - terra cruda</b>",
            "tooltipTxt": "Bartolini - terra cruda",
            "audioURL": null,
            "description": "Nella prima fase della produzione del busto, la realizzazione della terra cruda, sono ancora visibili i metodi di lavoro dell'artista. Sulla superficie del volto sono presenti linee e segni realizzati dagli strumenti dell'artista. Anche sul retro della testa è visibile la linea del calco. Al contrario, il busto in marmo finito è liscio e non presenta queste linee di lavorazione.",
        }
    },
    {
        "id1": "03",
        "id2": "04",
        "en": {
            "title": "<b>cf. Giovannozzi - marble</b>",
            "tooltipTxt": "Giovannozzi - marble",
            "audioURL": null,
            "description": "In Bartolini's marble bust of Byron the poet's features appear softer than in Giovannozzi's version. Giovannozzi gave Byron angular features, a stronger nose, and emphasised the natural lines on his face. Bartolini's marble, in contrast, smoothed Byron's features and opted for a more delicate nose. Both artists, however, emphasised Byron's curls, his sideburns or whiskers, and the dimple in his chin."
        },
        "it": {
            "title": "<b>cf. Giovannozzi - marble</b>",
            "tooltipTxt": "Giovannozzi - marble",
            "audioURL": null,
            "description": "Nel busto in marmo di Byron realizzato da Bartolini i lineamenti del poeta appaiono più delicati rispetto alla versione di Giovannozzi. Giovannozzi aveva dato a Byron tratti spigolosi, un naso più marcato e aveva enfatizzato le linee naturali del suo volto. Bartolini, invece, aveva levigato i suoi lineamenti e aveva preferito un naso più sottile. Entrambi gli artisti, tuttavia, avevano messo in risalto i riccioli di Byron, le basette e la fossetta del mento."
        }
    },
    {
        "id1": "03",
        "id2": "05",
        "en": {
            "title": "<b>cf. Bartolini - plaster</b>",
            "tooltipTxt": "Bartolini - plaster",
            "audioURL": null,
            "description": "While the marble bust was intended for display, the plaster cast in contrast was a working model. The reverse of the plaster bust shows the rough plaster while damage to the nose is also visible. The plaster model also had pins inserted at the extremeties, this was an aid for the carvers who would use the pins to make accurate measurements that helped them transfer the form into stone. Once the bust was rendered in stone, these imperfections and aids were removed."
        },
        "it": {
            "title": "<b>cf. Bartolini - plaster</b>",
            "tooltipTxt": "Bartolini - plaster",
            "audioURL": null,
            "description": "Mentre il busto in marmo era destinato ad essere esposto, il calco in gesso era invece un modello da lavoro. Il retro del busto in gesso mostra tracce della lavorazione grezza del calco, così come sono visibili danni al naso. Il modello in gesso aveva anche dei perni inseriti alle estremità, come supporto per gli intagliatori che li usavano per prendere misure precise utili a trasferire la forma nella pietra. Una volta realizzato il busto in pietra, queste imperfezioni e questi aiuti venivano rimossi."
        }
    },
    {
        "id1": "04",
        "id2": "01",
        "en": {
            "title": "<b>cf. Thorvaldsen - marble</b>",
            "tooltipTxt": "Thorvaldsen - marble",
            "audioURL": null,
            "description": "Giovannozzi's depiction of Byron moves towards an even greater realism, particularly when seen in contrast to Thorvaldsen's earlier neoclassical bust. In Giovannozzi's depiction Byron's nose is less delicate, his brows more furrowed, and the lines on his face have been made visible."
        },
        "it": {
            "title": "<b>cf. Thorvaldsen - marble</b>",
            "tooltipTxt": "Thorvaldsen - marble",
            "audioURL": null,
            "description": "Il ritratto di Byron realizzato da Giovannozzi si avvicina a un realismo ancora maggiore, soprattutto se visto in contrasto con il precedente busto neoclassico di Thorvaldsen. Nel busto di Giovannozzi il naso di Byron è meno delicato, le sopracciglia più aggrottate e le rughe del viso sono rese più evidenti."
        }
    },
    {
        "id1": "04",
        "id2": "02",
        "en": {
            "title": "<b>cf. Bartolini - terra cruda</b>",
            "tooltipTxt": "Bartolini - terra cruda",
            "audioURL": null,
            "description": "Giovannozzi followed Bartolini's style and there are many similarities between their depictions of Lord Byron. In contrast to Thorvaldsen's bust, they both show the poet's features in a more realistic way, giving the poet side burns or whiskers, visible lines on his face, and a furrowed brow."
        },
        "it": {
            "title": "<b>cf. Bartolini - terra cruda</b>",
            "tooltipTxt": "Bartolini - terra cruda",
            "audioURL": null,
            "description": "Giovannozzi seguì lo stile di Bartolini e i infatti due ritratti di Lord Byron sono molto simili tra loro. A differenza del busto di Thorvaldsen, entrambi mostrano i lineamenti del poeta in modo più realistico, ritraendolo con basette, rughe ben visibili sul viso e sopracciglia aggrottate."
        }
    },
    {
        "id1": "04",
        "id2": "03",
        "en": {
            "title": "<b>cf. Bartolini - marble</b>",
            "tooltipTxt": "Bartolini - marble",
            "audioURL": null,
            "description": "In Bartolini's marble bust of Byron the poet's features appear softer than in Giovannozzi's version. Giovannozzi gave Byron angular features, a stronger nose, and emphasised the natural lines on his face. Bartolini's marble, in contrast, smoothed Byron's features and opted for a more delicate nose. Both artists, however, emphasised Byron's curls, his sideburns or whiskers, and the dimple in his chin."
        },
        "it": {
            "title": "<b>cf. Bartolini - marble</b>",
            "tooltipTxt": "Bartolini - marble",
            "audioURL": null,
            "description": "Nel busto in marmo di Byron realizzato da Bartolini i lineamenti del poeta appaiono più delicati rispetto alla versione di Giovannozzi. Giovannozzi aveva dato a Byron tratti spigolosi, un naso più marcato e aveva enfatizzato le linee naturali del suo volto. Bartolini, invece, aveva levigato i suoi lineamenti e aveva preferito un naso più sottile. Entrambi gli artisti, tuttavia, avevano messo in risalto i riccioli di Byron, le basette e la fossetta del mento."
        }
    },
    {
        "id1": "04",
        "id2": "05",
        "en": {
            "title": "<b>cf. Bartolini - plaster</b>",
            "tooltipTxt": "Bartolini - plaster",
            "audioURL": null,
            "description": "The survival of the plaster cast for the Bartolini bust shows how the final marble was made. Giovannozzi's portrait would have used a similar process."
        },
        "it": {
            "title": "<b>cf. Bartolini - plaster</b>",
            "tooltipTxt": "Bartolini - plaster",
            "audioURL": null,
            "description": "La sopravvivenza del calco in gesso per il busto di Bartolini testimonia il modo in cui veniva realizzato il marmo finale. Il ritratto di Giovannozzi sarebbe stato realizzato con un processo simile."
        }
    },
    {
        "id1": "05",
        "id2": "01",
        "en": {
            "title": "<b>cf. Thorvaldsen - marble</b>",
            "tooltipTxt": "Thorvaldsen - marble",
            "audioURL": null,
            "description": "The survival of the plaster cast for the Bartolini bust shows how the final marble was made. Thorvaldsen's portrait would have used a similar process."
        },
        "it": {
            "title": "<b>cf. Thorvaldsen - marble</b>",
            "tooltipTxt": "Thorvaldsen - marble",
            "audioURL": null,
            "description": "A testimoniare il processo di realizzazione del marmo finale è rimasta la copia del calco in gesso del busto di Bartolini. Anche per il ritratto di Thorvaldsen sarebbe stato utilizzato un processo simile."
        }
    },
    {
        "id1": "05",
        "id2": "02",
        "en": {
            "title": "<b>cf. Bartolini - terra cruda</b>",
            "tooltipTxt": "Bartolini - terra cruda",
            "audioURL": null,
            "description": "Both of these busts are working models used by the sculptor to create the final marble piece. While the terra cruda was sculpted largely by hand the plaster would have been cast. There is evidence of this process in the cast line across the back of the head which indicates where the two pieces of the mould were joined. The plaster model also had pins inserted at the extremeties, this was an aid for the carvers who would use the pins to make accurate measurements that helped them transfer the form into stone."
        },
        "it": {
            "title": "<b>cf. Bartolini - terra cruda</b>",
            "tooltipTxt": "Bartolini - terra cruda",
            "audioURL": null,
            "description": "Entrambi i busti sono modelli di lavoro utilizzati dallo scultore per creare il pezzo finale in marmo. Mentre la terra cruda veniva scolpita in gran parte a mano, il gesso veniva colato. La prova di questo processo è data dalla linea del calco che attraversa la parte posteriore della testa e che indica il punto in cui i due pezzi del calco sono stati uniti. Il modello in gesso aveva anche dei perni inseriti alle estremità, che gli intagliatori usavano per prendere misure precise in modo da realizzare la forma nella pietra."
        }
    },
    {
        "id1": "05",
        "id2": "03",
        "en": {
            "title": "<b>cf. Bartolini - marble</b>",
            "tooltipTxt": "Bartolini - marble",
            "audioURL": null,
            "description": "The plaster cast is clearly a working model, not one intended for display, unlike the final marble bust. The reverse of the bust shows the rough plaster while damage to the nose is also visible. The plaster model also had pins inserted at the extremeties, this was an aid for the carvers who would use the pins to make accurate measurements that helped them transfer the form into stone."
        },
        "it": {
            "title": "<b>cf. Bartolini - marble</b>",
            "tooltipTxt": "Bartolini - marble",
            "audioURL": null,
            "description": "Il calco in gesso è chiaramente una copia da lavoro, non destinata all'esposizione, a differenza del busto definitivo in marmo. Il retro del busto mostra il gesso grezzo, e sono visibili anche i danni al naso. Il modello in gesso aveva anche dei perni inseriti alle estremità, che servivano agli intagliatori per prendere misure precise utili a definire la forma nella pietra."
        }
    },
    {
        "id1": "05",
        "id2": "04",
        "en": {
            "title": "<b>cf. Giovannozzi - marble</b>",
            "tooltipTxt": "Giovannozzi - marble",
            "audioURL": null,
            "description": "The survival of the plaster cast for the Bartolini bust shows how the final marble was made. Giovannozzi's portrait would have used a similar process."
        },
        "it": {
            "title": "<b>cf. Giovannozzi - marble</b>",
            "tooltipTxt": "Giovannozzi - marble",
            "audioURL": null,
            "description": "In questo caso, la sopravvivenza del calco in gesso per il busto di Bartolini rivela come venisse realizzato il marmo finale. Un processo simile sarebbe stato utilizzato anche per il ritratto di Giovannozzi."
        }
    }
]
