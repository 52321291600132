import * as THREE from "three";
import {getNavigationTargetPosition, MeshTransform} from "../../navigation/ts/NavMeshUtils";
import * as TWEEN from "@tweenjs/tween.js";
import {Vector3} from "three";
import {
    NAVMESH_NAVIGATION_SPEED,
    MAX_SQUARE_DISTANCE_FOR_ROTATION,
    CAMERA_OFFSET_FROM_NAVMESH,
    MIN_SQUARE_DISTANCE_TO_NAVMESH_NODE
} from "../../../client-data/clientOptions";
import {RenderingValidationSingleton} from "../../Rendering/ts/RenderingValidationSingleton";
import {INavMeshPlayer} from "../../navigation/ts/INavMeshPlayer";
import {INavMeshContainer} from "../../navigation/ts/INavMeshContainer";
import {PathMovingPlayer} from "../../navigation/ts/player/PathMovingPlayer";
import {Path} from "../../navigation/ts/player/Path";
import {ColliderTriggerContainer} from "../../colliders/ts/ColliderTriggerContainer";

const EMPTY_EULER = new THREE.Euler();
const EMPTY_QUATERNION = new THREE.Quaternion();
const VEC_UP = new Vector3(0,1,0);
const MESH_TARGET_TWEEN_DELAY = 500; //delay for mesh target
const POSITION_TARGET_TWEEN_DELAY = 250; //delay for Vector3  position
const RAYCAST_DIRECTION = new THREE.Vector3(0,-1,0);

export class CameraNavMeshHandler {

    private navmeshPlayer: INavMeshPlayer;
    private targetNode: THREE.Object3D | null = null;
    private readonly cameraYOffset: number = CAMERA_OFFSET_FROM_NAVMESH;
    private readonly raycaster = new THREE.Raycaster();
    private lookAt: boolean = false;
    private _locked = false; // used to prevent concurrency
    private navigationDelay = MESH_TARGET_TWEEN_DELAY;
    private readonly _navmeshId: number;
    private _renderLoopLock = true;
    private _navigationPath: Array<THREE.Vector3> | null = null;
    private _lastTween: (TWEEN.Tween<{t: number}>) | null = null;
    private _postNavigationCallBack: (()=> void) | null = null;

    private pathMovingPlayer: PathMovingPlayer;
    private path = new Path();

    // private
    constructor(private camera: THREE.PerspectiveCamera, private navMeshContainer: INavMeshContainer, private colliderTriggerContainer: ColliderTriggerContainer) {
        this.navmeshPlayer = this.navMeshContainer.createPlayer(this.camera, this.onInitPlayerCallBack, this.onStartPlayerCallBack, this.onUpdatePlayerCallBack, this.onCompletePlayerCallBack);
        this._navmeshId = this.navMeshContainer.navMesh.id;

        this.pathMovingPlayer = new PathMovingPlayer(camera, CAMERA_OFFSET_FROM_NAVMESH);
    }

    navigateToTargetPos = (node: THREE.Object3D, jumpToTarget = false, postNavigationCallBack: (()=> void) | null = null, isZoneHopping = true ) => {

        //  used to prevent concurrency
        if (this._locked) return;

        this._postNavigationCallBack = postNavigationCallBack;
        this.lookAt = true;
        const target = getNavigationTargetPosition(node, isZoneHopping);
        this.navigationDelay = MESH_TARGET_TWEEN_DELAY;


        if (jumpToTarget) {
            this._locked = true;
            this._navigationPath = this.navmeshPlayer.getPathForTargetPos(target, true, false);
          //  console.log("jumpToTargetPosition=", this._navigationPath);
            this.jumpToTargetPosition(target);
        } else {

            /*if (this.navmeshPlayer.navigateToTargetPos(target, true, false, this.navigationDelay)) {
                this._locked = true;
            }*/
            this.targetNode = node;
            const targetPos = target.position.clone();
            targetPos.y = this.camera.position.y;
            if (targetPos.distanceToSquared(this.camera.position) < MIN_SQUARE_DISTANCE_TO_NAVMESH_NODE) {
                const wp = new THREE.Vector3();
                this.targetNode.getWorldPosition(wp);
                this.targetNode = null;
                const nodePos = wp.clone();
                nodePos.y = this.camera.position.y;

                this.rotateCameraToTarget(nodePos, 1000, () => {
                    if (this._postNavigationCallBack) {
                        this._postNavigationCallBack()
                        this._postNavigationCallBack =  null;
                    }
                    this._locked = false;
                })

            }
            else {

                this._navigationPath = this.navmeshPlayer.getPathForTargetPos(target, true, false);
                this.initNavigateToMeshPoint();
            }


        }

    }



    navigateToNanMeshPoint = (pos: THREE.Vector3, postNavigationCallBack: (()=> void) | null = null ) => {

        //  used to prevent concurrency
        if (this._locked) return;

        this._postNavigationCallBack = postNavigationCallBack;
        this.lookAt = true;
        const target: MeshTransform = {
            rotationEuler: EMPTY_EULER,
            position: pos,
            rotationQuaternion: EMPTY_QUATERNION,
        };
        this.navigationDelay = MESH_TARGET_TWEEN_DELAY;

        const targetPos = pos.clone();
        targetPos.y = this.camera.position.y;
        if (targetPos.distanceToSquared(this.camera.position) < MIN_SQUARE_DISTANCE_TO_NAVMESH_NODE) {
            if (this._postNavigationCallBack) {
                this._postNavigationCallBack()
                this._postNavigationCallBack =  null;
            }
            this._locked = false;

        }
        else {
            this._navigationPath = this.navmeshPlayer.getPathForTargetPos(target, true, false);

            this.initNavigateToMeshPoint();
        }
    }

    onInitPlayerCallBack = (pos: THREE.Vector3, index: number, pathLength: number, pathSegmentEndPos: THREE.Vector3, distance: number, meshTransform?: MeshTransform) => {
        //this.cameraYOffset = this.camera.position.y - pos.y;

    }

    onUpdatePlayerCallBack = (pos: THREE.Vector3, index: number, pathLength: number, pathSegmentEndPos: THREE.Vector3, distance: number,meshTransform?: MeshTransform) => {

        const offsetPos = pos.clone();
        offsetPos.y += this.cameraYOffset;
        this.camera.position.copy(offsetPos);

    }

    onStartPlayerCallBack = (pos: THREE.Vector3, index: number, pathLength: number, pathSegmentEndPos: THREE.Vector3, distance: number,meshTransform?: MeshTransform) => {

        const offsetPos = pos.clone();
        offsetPos.y += this.cameraYOffset;

        let targetPos: THREE.Vector3;

        targetPos = pathSegmentEndPos.clone();
        targetPos.y = offsetPos.y;
        this.rotateCameraToTarget(targetPos, 1000);
    }

    onCompletePlayerCallBack = (pos: MeshTransform, distance: number) => {
        if (!pos.node) {
            this._locked = false;
            return;
        }
        const wp = new THREE.Vector3()
        pos.node.getWorldPosition(wp)
        const nodePos = wp.clone();
        nodePos.y = this.camera.position.y;
        this.rotateCameraToTarget(nodePos, 1000, () => {
            this._locked = false;
        })

    }

    update = () => {
        this.navmeshPlayer.update();
    }

    onMouseDbClick = (intersect: THREE.Intersection): THREE.Intersection | null => {
        if (intersect.object.id === this.navmeshId) {
            return intersect;
        }
        return null;

        // this.camera.updateMatrixWorld();
    }

    moveForward = (distance: number) => {
        //  used to prevent concurrency
        if (this._locked) return;
        this._locked = true;

        if (distance === 0) {
            this._locked = false;
            return;
        }

        let vec = new Vector3();

        vec.setFromMatrixColumn(this.camera.matrix, 0);

        vec.crossVectors(VEC_UP, vec);


        const target = this.camera.position.clone();
        target.addScaledVector(vec, distance);
        this.moveToTarget(target);

    }

    moveSide = (distance: number) => {
        //  used to prevent concurrency
        if (this._locked) return;
        this._locked = true;

        if (distance === 0) {
            this._locked = false;
            return;
        }

        let vec = new Vector3();

        vec.setFromMatrixColumn(this.camera.matrix, 0);

        const target = this.camera.position.clone();
        target.addScaledVector(vec, distance);
        this.moveToTarget(target);

    }

    private moveToTarget = (target: THREE.Vector3) => {

        // const intersectTarget = this.navmeshPlayer.rayTraceNaveMesh(target);
        //if (intersectTarget.length) {
        const cameraPos = this.camera.position.clone();
        //const intersectPlayer = this.navmeshPlayer.rayTraceNaveMesh(cameraPos);
        const playerOffsetY = this.cameraYOffset;
        //if (intersectPlayer.length) {
        target.y -= playerOffsetY;
        cameraPos.y -= playerOffsetY;
        //console.log("cameraNavmeshHandler.moveToTarget player, target=", intersectPlayer[0].point, target)
        const newPos = this.navmeshPlayer.clampStep(cameraPos, target.clone(), false);
        if (newPos) {
            newPos.y += playerOffsetY;
            this.camera.position.copy(newPos);

        } else {
            console.log("CameraNavMeshHandler.moveToTarget clampStem is null");
        }
        /*} else {
            console.log("moveForward Player no intersect");
        }*/
        /*} else {
            console.log("moveForward Target no intersect");
        }*/

        this._locked = false;
    }

    get locked(): boolean {
        return this._locked;
    }

    rotateCameraToTarget = (pos: THREE.Vector3, duration = 0, onCompleteCB?: (() => void)): (TWEEN.Tween<{t: number}>) | null => {

        const matrix4 = this.camera.matrix.clone();
        matrix4.lookAt(this.camera.position, pos, VEC_UP);
        const quaternion = new THREE.Quaternion().setFromRotationMatrix(matrix4);
        const angle = this.camera.quaternion.angleTo(quaternion);
        // const cameraQuaternion = this.camera.quaternion.clone();


        if (duration) {
            // duration = 100 + (1000 * angle);
            duration = 1000;

            let time = {t: 0};
            const tween = new TWEEN.Tween(time)
                .to({t: 1}, duration)
                .easing(TWEEN.Easing.Quadratic.InOut)
                .onUpdate(() => {
                    this.camera.quaternion.slerp(quaternion, time.t);
                })
                .onComplete(() => {
                    if (onCompleteCB) {
                        onCompleteCB();
                    }
                })
                .start()
            return tween;
        } else {
            this.camera.quaternion.copy(quaternion);
            if (onCompleteCB) {
                onCompleteCB();

            }
            return null;
        }

        //this.camera.matrix.lookAt( this.camera.position, pos, VEC_UP );
        //this.camera.quaternion.setFromRotationMatrix( this.camera.matrix );
    }

    jumpToTargetPosition = (meshTransform: MeshTransform) => {

        const target = meshTransform.position.clone();

        this.camera.updateMatrixWorld(true);
        const intersectTarget = this.navmeshPlayer.rayTraceNaveMesh(target,RAYCAST_DIRECTION);
        if (intersectTarget.length) {
            this.navmeshPlayer.updateGroup(intersectTarget[0].point);
            const cameraPos = this.camera.position.clone();
            const intersectPlayer = this.navmeshPlayer.rayTraceNaveMesh(cameraPos,RAYCAST_DIRECTION);
            if (intersectPlayer.length) {
                target.y = intersectTarget[0].point.y;
                const playerOffsetY = this.cameraYOffset;
                const newPos = this.navmeshPlayer.clampStep(intersectPlayer[0].point, target, false);
                if (newPos) {
                    if (this._navigationPath) {
                       const intersects = this.colliderTriggerContainer.collideWithPathAtAllPoints(this._navigationPath);
                       //console.log("jumpToTargetPosition intersects =", intersects);
                    }
                    newPos.y += playerOffsetY;
                    this.camera.position.copy(newPos);
                    const lookAtPos = meshTransform.node!.position.clone();
                    lookAtPos.y = newPos.y;
                    this.rotateCameraToTarget(lookAtPos);
                    RenderingValidationSingleton.getInstance().invalidateOnce(); // make sure to render once

                } else {
                    console.log("CameraNavMeshHandler.jumpToTargetPosition clampStem is null");
                }
            }
            else {
                console.log("CameraNavMeshHandler.jumpToTargetPosition Player no intersect");
            }
        }
        else {
            console.log("CameraNavMeshHandler.jumpToTargetPosition Target no intersect");
        }

        this._locked = false;

    }

    moveCameraToPosition = (pos: THREE.Vector3, period?: number, completeCallBack?: () => void) => {

        const startPos = this.camera.position.clone();

        if (!period) {
            period = startPos.distanceTo(pos) * 200;
            console.log("moveCameraToPosition period=", period);
        }

        new TWEEN.Tween(startPos).to(pos,period).
        onUpdate( newPos => {
                this.camera.position.copy(newPos);
            }

        ).onComplete( () => {
                if (completeCallBack) {
                    completeCallBack();
                }
            }

        ).start();
    }

    //move camera forward  and ignore navmesh intersections
    moveCameraForwardNoNavmesh = (distance: number, period?: number, completeCallBack?: () => void) => {

        if (distance === 0) {

            return;
        }

        let vec = new Vector3();

        vec.setFromMatrixColumn(this.camera.matrix, 0);

        vec.crossVectors(VEC_UP, vec);


        const target = this.camera.position.clone();
        target.addScaledVector(vec, distance);

        this.moveCameraToPosition(target, period,completeCallBack);
    }

    updateGroupIDAtPlayerPos = (origin: THREE.Vector3 = this.camera.position):number | null => {
        return this.navmeshPlayer.updateGroupIDAtPlayerPos(origin);

    }

    get navmeshId(): number {
        return this._navmeshId;
    }

    initNavigateToMeshPoint = () => {

        if (this._navigationPath?.length) {
            this.pathMovingPlayer.maxSpeed = NAVMESH_NAVIGATION_SPEED;
            this.path.path = this._navigationPath!
            if (this.targetNode) {
                const wp = new THREE.Vector3();
                this.targetNode.getWorldPosition(wp);
                this.targetNode = null;
                this.pathMovingPlayer.startMoving(this.path,wp);
            }
            else {

                this.pathMovingPlayer.startMoving(this.path);
            }
            this._locked = this.pathMovingPlayer.start;
        }

    }

    renderUpdateLoop = (dt: number): void => {

        this.pathMovingPlayer.update(dt);
        this._locked = this.pathMovingPlayer.start;

    }

}
