import {ColliderX} from "./ColliderX";
import * as THREE from 'three';
import {COLLIDER_STATUS, ColliderTriggerCallback} from "./ColliderTypes";

export class ColliderTriggerBase {

    private _colliderStatus = COLLIDER_STATUS.not_intersected;

    constructor(private _colliderX: ColliderX,
                private colliderEnter: ColliderTriggerCallback,
                private colliderStay: ColliderTriggerCallback,
                private colliderExit: ColliderTriggerCallback,
                private stopOnIntersect = false,
                private showWireframe = false) {

        this._colliderX.showWireframe = showWireframe;

    }

    public execute(player: ColliderX):  COLLIDER_STATUS {

        const doCollide = player.collideWith(this.colliderX);

        //console.log("ColliderTriggerBase collide=", doCollide);

        if (doCollide) {
            if (this.colliderStatus == COLLIDER_STATUS.intersected) {
                //already existed
                this.colliderStay(this);
            }
            else {
                this.colliderStatus = COLLIDER_STATUS.intersected;
                this.colliderEnter(this)
            }
        }
        else {
            if (this.colliderStatus == COLLIDER_STATUS.intersected) {
                //already existed, so we are exiting
                this.colliderStatus = COLLIDER_STATUS.not_intersected
                this.colliderExit(this);
            }
        }
        this.syncColliderX();

        return this.colliderStatus;
    }

    public collideWithPoint(point: THREE.Vector3): COLLIDER_STATUS {

        const doCollide = this.colliderX.collideWithPoint(point);

        if (doCollide) {
            if (this.colliderStatus == COLLIDER_STATUS.intersected) {
                //already existed
                this.colliderStay(this);
            }
            else {
                this.colliderStatus = COLLIDER_STATUS.intersected;
                this.colliderEnter(this)
            }
        }
        else {
            if (this.colliderStatus == COLLIDER_STATUS.intersected) {
                //already existed, so we are exiting
                this.colliderStatus = COLLIDER_STATUS.not_intersected
                this.colliderExit(this);
            }
        }
        this.syncColliderX();

        return this.colliderStatus;

    }

    public syncColliderX() {
        this.colliderX.colliderStatus = this.colliderStatus;
    }

    get colliderX(): ColliderX {
        return this._colliderX;
    }


    get colliderStatus(): COLLIDER_STATUS {
        return this._colliderStatus;
    }

    set colliderStatus(value: COLLIDER_STATUS) {
        this._colliderStatus = value;
    }

    get colliderName() :string {

        return this.colliderX.colliderName;
    }
}
