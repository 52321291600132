import {MultiViewSingleScene} from "./MultiViewSingleScene";
import {MultiViewRenderer} from "./MultiViewRenderer";
import * as THREE from "three"
import {MultiViewSlider} from "./MultiViewSlider";
import {ON_SLIDER_UPDATE_RENDERER, SLIDER_RADIUS} from "./MultiViewTypes";
import {MainCamera} from "./MainCamera";
import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";
// import {Pane} from "tweakpane";
import dat from "dat.gui";
import {
    EnvironmentMapsLoaderDoubleResults,
    EnvironmentOptionsType,
    EnvironmentTextureDoubleResults,
    ViewerOptionsTypes
} from "./types/ViewerTypes";
import {EnvironmentLoader} from "./loaders/EnvironmentLoader";
import {RenderingValidationSingleton} from "../Rendering/ts/RenderingValidationSingleton";
import {VignetteShader} from "three/examples/jsm/shaders/VignetteShader";
import {EffectComposer} from "three/examples/jsm/postprocessing/EffectComposer";
import {RenderPass} from "three/examples/jsm/postprocessing/RenderPass";
import {ShaderPass} from "three/examples/jsm/postprocessing/ShaderPass";
import {Nullable} from "./types/types";



export class MultiViewProcessor {

    private _mainContainer: HTMLElement;
    private _contentLeft: HTMLElement;
    private _contentRight: HTMLElement;
    private _sliderElement: HTMLElement;
    private _useForMouse: HTMLElement;
    private _sceneLeft: MultiViewSingleScene;
    private _sceneRight: MultiViewSingleScene;
    private _multiViewRenderer: MultiViewRenderer;
    private _slider: MultiViewSlider;

    private animationFrameId: number = 0;
    private _singleView = false;
    private orbitUpdate = true;
    private clock: THREE.Clock = new THREE.Clock();
    private _alwaysRender = false;
    private _modal3dViewBody: HTMLElement;
    private _container3dModal: HTMLElement;

    // private envData: EnvironmentOptionsType | null = null;
    private _pmremGenerator: THREE.PMREMGenerator;
    private _datGui: dat.GUI | null = null;
    private rightComposer: Nullable<EffectComposer> = null;
    private guiParam = {vignetteOffset: 1.5, vignetteDarkness: 1.6, useVignette: true};


    constructor(private _mainContainerId: string, private _contentLeftId: string, private _contentRightId: string, private _sliderId: string,
                private useForMouseId: string, private container3dModalId: string, private modal3dViewBodyId: string, private _modal3dOptions:ViewerOptionsTypes, useGui: boolean) {

        this._mainContainer = document.getElementById(this.mainContainerId)!

        if (!this._mainContainer) {
            console.log(`${this.mainContainerId} is null`);
        }

        this._contentLeft = document.getElementById(this._contentLeftId)!

        if (!this._contentLeft) {
            console.log(`${this._contentLeftId} is null`);
        }

        this._contentRight = document.getElementById(this._contentRightId)!

        if (!this._contentRight) {
            console.log(`${this._contentRightId} is null`);
        }

        this._sliderElement = document.getElementById(this._sliderId)!

        if (!this._sliderElement) {
            console.log(`${this._sliderId} is null`);
        }

        this._useForMouse = document.getElementById(this.useForMouseId)!

        if (!this._useForMouse) {
            console.log(`${this._useForMouse} is null`);
        }

        this._modal3dViewBody = document.getElementById(this.modal3dViewBodyId)!

        if (!this._modal3dViewBody) {
            console.log(`${this.modal3dViewBodyId} is null`);
        }

        // console.log("modal3dViewBody rect=", this._modal3dViewBody.getBoundingClientRect())

        this._container3dModal = document.getElementById(this.container3dModalId)!

        if (!this._container3dModal) {
            console.log(`${this.container3dModalId} is null`);
        }

        this._sceneLeft = new MultiViewSingleScene(this.contentLeft, this.modal3dOptions);
        this._sceneRight = new MultiViewSingleScene(this.contentRight,this.modal3dOptions);
        this.sceneRight.offsetX = this._mainContainer.getBoundingClientRect().width / 2;

        this._multiViewRenderer = new MultiViewRenderer(this.mainContainer,this.modal3dOptions);

        this._pmremGenerator = new THREE.PMREMGenerator(this._multiViewRenderer.renderer);
        this._pmremGenerator.compileEquirectangularShader();

        window.addEventListener('resize', this.onWindowResize);

        this._slider = new MultiViewSlider(this.sliderElement, this.mainContainer, this.modal3dViewBody, this.modal3dViewBody, this._container3dModal, this.sceneRight, this.sceneLeft);

        this.slider.addEventListener(ON_SLIDER_UPDATE_RENDERER, this.onSliderUpdateRendering);

        const main3DViewModalGuiDat = document.getElementById("main3DViewModalGuiDat");
        /*  const PARAMS = {
              factor: 123,
              title: 'hello',
              color: '#ff0055',
              background: {r: 255, g: 0, b: 55},
              tint: {r: 0, g: 255, b: 214, a: 0.5},
              hidden: true,
              hex: '#0088ff',
              key: '#ff0055ff',
              offset: {x: 50, y: 25},
              source: {x: 0, y: 0, z: 0},
              camera: {x: 0, y: 20, z: -10},
              color2: {x: 0, y: 0, z: 0, w: 1},
          };


          const pane = new Pane({
              container:main3DViewModalText!
          });

          pane.addBinding(PARAMS, 'factor');
          pane.addBinding(PARAMS, 'title');
          pane.addBinding(PARAMS, 'color');
          pane.addBinding(PARAMS, 'background');
          pane.addBinding(PARAMS, 'tint');
          pane.addBinding(PARAMS, 'hidden');
          pane.addBinding(PARAMS, 'hex', {
              view: 'text',
          });
          pane.addBinding(PARAMS, 'key', {
              picker: 'inline',
              expanded: true,
          });
          pane.addBinding(PARAMS, 'offset', {
              x: {step: 20},
              y: {min: 0, max: 100},
          });
          pane.addBinding(PARAMS, 'source');
          pane.addBinding(PARAMS, 'camera', {
              y: {step: 10},
              z: {max: 0},
          });

  // 4d
          pane.addBinding(PARAMS, 'color2', {
              x: {min: 0, max: 1},
              y: {min: 0, max: 1},
              z: {min: 0, max: 1},
              w: {min: 0, max: 1},
          });*/


        if (useGui) {
            this._datGui = new dat.GUI({autoPlace: false, closeOnTop: true});
            main3DViewModalGuiDat!.appendChild(this._datGui.domElement);
        }

    }

    onSliderUpdateRendering = (e: THREE.Event) => {
        // console.log("onSliderUpdateRendering=", e)
        this.orbitUpdate = e.render;
    }

    render = () => {

        const delta = this.clock.getDelta();

        if (this.orbitUpdate || this.alwaysRender || RenderingValidationSingleton.getInstance().isInvalidatedOnce()) {
            if (!this._singleView) {
                const rectLeft = this.sceneLeft.size;

                this.multiViewRenderer.initViewPort(rectLeft, this.sceneLeft.offsetX, this.sceneLeft.offsetY);
                this.renderer.render(this.sceneLeft.scene, this.sceneLeft.sceneCamera!.camera);

                const rectRight = this.sceneRight.size;

                this.sceneLeft.orbitControls?.update();

                this.multiViewRenderer.initViewPort(rectRight, this.sceneRight.offsetX, this.sceneRight.offsetY);
            }

            if (this.rightComposer && this.guiParam.useVignette) {
                this.rightComposer.render();
            }
            else {
                this.renderer.render(this.sceneRight.scene, this.sceneRight.sceneCamera!.camera);
            }
            this.sceneRight.orbitControls?.update();
            // console.log("Is rendering");
        }
    }

    animate = () => {
        this.animationFrameId = requestAnimationFrame(this.animate);
        this.render();
    }

    pauseRendering = () => {
        if (this.animationFrameId) {
            cancelAnimationFrame(this.animationFrameId);
            // console.log("animationFrameId=", this.animationFrameId);
            this.animationFrameId = 0;
            this.clock.stop();
        }
    }

    resumeRendering = () => {
        if (!this.animationFrameId) {
            this.animate();
            this.clock.start();
        }
    }

    setSingleView = () => {
        // console.log("Single View")
        this.sliderElement.hidden = true;
        this.contentLeft.hidden = true;
        this.container3dModal.style.gridTemplateColumns = "0 100%";
        this._slider.removeEventListener("ON_SLIDER_UPDATE_RENDERER", this.onSliderUpdateRendering);
        this.contentRight.style.gridColumn = "1 / -1";
        this.contentRight.style.width = "100%";
        this.sceneLeft.orbitControls!.enabled = false;
        this.sceneRight.onWindowResize();
        this.multiViewRenderer.renderer.setSize(this.sceneRight.size.width, this.sceneRight.size.height);
        this.multiViewRenderer.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
        this.multiViewRenderer.initViewPort(this.sceneRight.size, 0, 0);
        this.renderer.setScissorTest(false);

        this._singleView = true;
        this.orbitUpdate = true
    }

    setDoubleView = () => {
        // console.log("Double View")
        this.contentRight.style.gridColumn = "2 / -1";
        this.contentLeft.style.gridColumn = "1 / 2";
        this.container3dModal.style.gridTemplateColumns = "50% 50%";
        this.contentLeft.hidden = false;
        this.contentLeft.style.width = "100%";
        this.contentRight.style.width = "100%";
        this.sceneLeft.orbitControls!.enabled = true;
        this.sceneRight.onWindowResize();
        this.sceneLeft.onWindowResize();

        this.sliderElement.hidden = false;
        this._slider.addEventListener("ON_SLIDER_UPDATE_RENDERER", this.onSliderUpdateRendering);
        this.sliderElement.style.left = `calc(50% - ${SLIDER_RADIUS}px)`;
        this.renderer.setScissorTest(true);

        this._singleView = false;
        this.orbitUpdate = true
    }

    initCameraLeft(fov: number = 45) {

        const size = this.sceneLeft.getElementSize();
        this.sceneLeft.sceneCamera = new MainCamera(size.width, size.height, this.modal3dOptions);

        this.sceneLeft.orbitControls?.addEventListener("change", this.sceneRightChange);
        this.sceneLeft.orbitControls?.addEventListener("start", this.sceneRightChange);
        this.sceneLeft.orbitControls?.addEventListener("end", this.sceneRightEnd);
    }

    initCameraRight(fov: number = 45) {

        const size = this.sceneRight.getElementSize();
        this.sceneRight.sceneCamera = new MainCamera(size.width, size.height, this.modal3dOptions);
        if (this._datGui) {
            // @ts-ignore
            this._datGui.addCamera("Right Camera",this.sceneRight.sceneCamera.camera, this.printOrbitCtrlDataToGUI );
        }
       // this.addVignette();


        this.sceneRight.orbitControls?.addEventListener("change", this.sceneRightChange);
        this.sceneRight.orbitControls?.addEventListener("start", this.sceneRightChange);
        this.sceneRight.orbitControls?.addEventListener("end", this.sceneRightEnd);
    }

    private addVignette() {
        this.rightComposer = new EffectComposer(this.renderer);
        this.rightComposer.addPass(new RenderPass(this.sceneRight.scene, this.sceneRight.sceneCamera!.camera));
        const vignetteShader = VignetteShader;
        const effectVignette = new ShaderPass(vignetteShader);
        effectVignette.uniforms["offset"].value = 1.4;
        effectVignette.uniforms["darkness"].value = .5;
        effectVignette.renderToScreen = true;
        this.rightComposer.addPass(effectVignette);
        if (this._datGui) {
            const folderVignette = this._datGui.addFolder('Vignette');
            const vignetteOffsetGUI = folderVignette.add( this.guiParam, 'vignetteOffset' ).min(0).max(3).step(0.01).name("Offset").listen();
            vignetteOffsetGUI.onChange((value) => {
                effectVignette.uniforms["offset"].value = value;
                console.log("Vignette offset =", value);
                RenderingValidationSingleton.getInstance().invalidateOnce();
            })
            const vignetteDarknessGUI = folderVignette.add( this.guiParam, 'vignetteDarkness' ).min(0).max(3).step(0.01).name("Darkness").listen();
            vignetteDarknessGUI.onChange((value) => {
                effectVignette.uniforms["darkness"].value = value;
                console.log("Vignette darkness =", value);
                RenderingValidationSingleton.getInstance().invalidateOnce();
            })

            const useVignette = folderVignette.add(this.guiParam,'useVignette').name("Use Vignette").listen();
            useVignette.onChange((value=> {
                if (!value) {
                    //this.rightComposer = null;
                    RenderingValidationSingleton.getInstance().invalidateOnce();

                }
            }))
        }
    }

    printOrbitCtrlDataToGUI = (): string => {
        const target = this.sceneRight.orbitControls?.target;
        const azimAngel = this.sceneRight.orbitControls?.getAzimuthalAngle();
        const polarAngel = this.sceneRight.orbitControls?.getPolarAngle();
        const distance = this.sceneRight.orbitControls?.getDistance();
        const logStr = `target= new Vector3(${target?.x} , ${target?.y}, ${target?.z} ) - AzimAngle=${azimAngel} - polarAngle= ${polarAngel} - distance= ${distance}`;
        console.log(logStr);
        return logStr;

    }

    sceneRightChange = () => {
        //console.log("sceneRightChange")



        this.orbitUpdate = true;
    }

    sceneRightEnd = () => {
        // console.log("sceneRightEnd");
        const self = this;
        setTimeout(() => {
            self.orbitUpdate = false;
            if (self.sceneRight.orbitControls?.autoRotate) {
                self.sceneRight.orbitControls!.autoRotate = false;
            }
        }, 100)
    }

    onWindowResize = () => {

        this.sceneLeft.onWindowResize();
        this.sceneRight.onWindowResize();
        this.multiViewRenderer.onWindowResize();
        const sceneLeftRight = this.sceneLeft.size.right;
        const sceneLeftRight2 = this.sceneLeft.sceneElement.getBoundingClientRect().right;
        const modelPositionX = this.modal3dViewBody.getBoundingClientRect().x;
        // console.log("sceneLeftRight=", sceneLeftRight, "-sceneLeftRight2", sceneLeftRight2, "-modelPositionX=", modelPositionX);
        this.sliderElement.style.left = (sceneLeftRight - SLIDER_RADIUS - modelPositionX) + 'px';
        this.slider.resetScreenRightOffset();
        // this.sceneRight.offsetX = (sceneLeftRight - SLIDER_RADIUS - modelPositionX)

        // this.sceneRight.offsetX = this.sceneRight.size.left - this.modal3dViewBody.getBoundingClientRect().x;
        //this.sceneRight.offsetX = (this.mainContainer.getBoundingClientRect().x + (this.mainContainer.getBoundingClientRect().width/2)) - this.modal3dViewBody.getBoundingClientRect().x;
        this.orbitUpdate = true;

    }

    async getEnvironmentMaps(envColorImage: string, backgroundImage: string): Promise<EnvironmentMapsLoaderDoubleResults> {
        // return  await new Promise<void>((resolve, reject) => {
        const envLoader = new EnvironmentLoader(this.renderer);
        const envData = EnvironmentLoader.getEnvironmentData(envColorImage, backgroundImage);
        if (!envData.colorMap) {
            throw new Error(`Cannot find ColorMap HDR ${envColorImage}`)
        }

        if (!envData.backgroundMap) {
            throw new Error(`Cannot find Background HDR ${backgroundImage}`)
        }
        // const envMaps =  envLoader.getEnvironmentMaps(envData).then(envMaps => {
        const envMaps =  await envLoader.getEnvironmentMaps(envData)
        // this.envData = envData;


        return envMaps

    }

   /* async getEnvironmentTextures(envColorImage: string, backgroundImage: string): Promise<EnvironmentTextureDoubleResults> {
        // return  await new Promise<void>((resolve, reject) => {
        const envLoader = new EnvironmentLoader(this.renderer);
        const envData = EnvironmentLoader.getEnvironmentData(envColorImage, backgroundImage);
        if (!envData.colorMap) {
            throw new Error(`Cannot find Color Texture HDR ${envColorImage}`)
        }

        if (!envData.backgroundMap) {
            throw new Error(`Cannot find Background Texture HDR ${backgroundImage}`)
        }
        const envTexture =  await envLoader.getEnvironmentTexture(envData)

        return envTexture

    }*/

    createEnvMapFromTexture =(envTexture:EnvironmentTextureDoubleResults):EnvironmentMapsLoaderDoubleResults => {

        let colorMap: THREE.Texture | null = null;
        let backMap: THREE.Texture | null = null;
        if (envTexture.colorTexture) {
            colorMap = this.pmremGenerator.fromEquirectangular(envTexture.colorTexture).texture;
        }
        if (envTexture.backgroundTexture) {
            backMap = this.pmremGenerator.fromEquirectangular(envTexture.backgroundTexture).texture;
        }
        this.pmremGenerator.dispose();

        return {colorMap:colorMap, backgroundMap: backMap}

    }

    dispose = () => {

        this.pauseRendering();

        // setTimeout(()=> {
    /*    const info1 =   this.multiViewRenderer.renderer.info;
        console.log("info1", info1)*/

        window.removeEventListener('resize', this.onWindowResize);
        this.slider.removeEventListener(ON_SLIDER_UPDATE_RENDERER, this.onSliderUpdateRendering);

        this.slider.dispose()
        this.sceneLeft.dispose();
        this.sceneRight.dispose();

     /*   const info2 =   this.multiViewRenderer.renderer.info;
        console.log("info2", info2)*/
        this.multiViewRenderer.dispose();

      /*  const info3 =   this.multiViewRenderer.renderer.info;
        console.log("info3", info3)*/




        // @ts-ignore
        this._slider = null;
        // @ts-ignore
        this._sceneLeft = null;
        // @ts-ignore
        this._sceneRight = null;
        // @ts-ignore
        this._multiViewRenderer = null;
        //  }, 500);


    }


    get mainContainer(): HTMLElement {
        return this._mainContainer;
    }

    get mainContainerId(): string {
        return this._mainContainerId;
    }


    get contentLeft(): HTMLElement {
        return this._contentLeft;
    }

    get contentRight(): HTMLElement {
        return this._contentRight;
    }

    get contentLeftId(): string {
        return this._contentLeftId;
    }

    get contentRightId(): string {
        return this._contentRightId;
    }


    get sliderElement(): HTMLElement {
        return this._sliderElement;
    }

    get sliderId(): string {
        return this._sliderId;
    }

    get sceneLeft(): MultiViewSingleScene {
        return this._sceneLeft;
    }

    set sceneLeft(value: MultiViewSingleScene) {
        this._sceneLeft = value;
    }


    get sceneRight(): MultiViewSingleScene {
        return this._sceneRight;
    }

    set sceneRight(value: MultiViewSingleScene) {
        this._sceneRight = value;
    }


    get multiViewRenderer(): MultiViewRenderer {
        return this._multiViewRenderer;
    }

    get renderer(): THREE.WebGLRenderer {
        return this.multiViewRenderer.renderer;
    }


    get slider(): MultiViewSlider {
        return this._slider;
    }


    get useForMouse(): HTMLElement {
        return this._useForMouse;
    }


    get alwaysRender(): boolean {
        return this._alwaysRender;
    }

    set alwaysRender(value: boolean) {
        this._alwaysRender = value;
    }


    get modal3dViewBody(): HTMLElement {
        return this._modal3dViewBody;
    }


    get container3dModal(): HTMLElement {
        return this._container3dModal;
    }


    get modal3dOptions(): ViewerOptionsTypes {
        return this._modal3dOptions;
    }


    get pmremGenerator(): THREE.PMREMGenerator {
        return this._pmremGenerator;
    }


    get singleView(): boolean {
        return this._singleView;
    }

    set singleView(value: boolean) {
        this._singleView = value;
    }


    get datGui(): dat.GUI | null {
        return this._datGui;
    }
}
