import {Euler, Vector3, Scene} from "three";

const FLOOR_NUMBER = {
    BOTTOM_FLOOR: 1,
    TOP_FLOOR: 2,
};
// Here we split by Artists Names
//ChatRoom01
//ScreenStageLeft
const CameraLocationsDB = [

    {
        cameraLocationId: '01_front',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Bertel Thorvaldsen, marble - front',
        cameraPosition: null,
        cameraRotation: null,
        displayMesh: 'N', //display/hide mesh - applies only on locations set in Blender. Default is 'Y'
    },
    {
        cameraLocationId: '01_back',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Bertel Thorvaldsen, marble - back',
        cameraPosition: null,
        cameraRotation: null,
        displayMesh: 'N', //display/hide mesh - applies only on locations set in Blender. Default is 'Y'
    },
    {
        cameraLocationId: '02_front',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Lorenzo Bartolini, terra cruda - front',
        cameraPosition: null,
        cameraRotation: null,
        displayMesh: 'N', //display/hide mesh - applies only on locations set in Blender. Default is 'Y'
    },
    {
        cameraLocationId: '02_back',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Lorenzo Bartolini, terra cruda - back',
        cameraPosition: null,
        cameraRotation: null,
        displayMesh: 'N', //display/hide mesh - applies only on locations set in Blender. Default is 'Y'
    },
    {
        cameraLocationId: '05_front',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Lorenzo Bartolini, plaster - front',
        cameraPosition: null,
        cameraRotation: null,
        displayMesh: 'N', //display/hide mesh - applies only on locations set in Blender. Default is 'Y'
    },
    {
        cameraLocationId: '05_back',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Lorenzo Bartolini, plaster - back',
        cameraPosition: null,
        cameraRotation: null,
        displayMesh: 'N', //display/hide mesh - applies only on locations set in Blender. Default is 'Y'
    },
    {
        cameraLocationId: '03_front',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Lorenzo Bartolini, marble - front',
        cameraPosition: null,
        cameraRotation: null,
        displayMesh: 'N', //display/hide mesh - applies only on locations set in Blender. Default is 'Y'
    },
{
        cameraLocationId: '03_back',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Lorenzo Bartolini, marble - back',
        cameraPosition: null,
        cameraRotation: null,
        displayMesh: 'N', //display/hide mesh - applies only on locations set in Blender. Default is 'Y'
    },
    {
        cameraLocationId: '04_front',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: `Ottaviano Giovannozzi, marble - front`,
        cameraPosition: null,
        cameraRotation: null,
        displayMesh: 'N', //display/hide mesh - applies only on locations set in Blender. Default is 'Y'
    },
    {
        cameraLocationId: '04_back',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: `Ottaviano Giovannozzi, marble - back`,
        cameraPosition: null,
        cameraRotation: null,
        displayMesh: 'N', //display/hide mesh - applies only on locations set in Blender. Default is 'Y'
    },


    // {
    //     cameraLocationId: '',
    //     floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
    //     locationName: '',
    //     cameraPosition: null,
    //     cameraRotation: null,
    //     displayMesh: 'N', //display/hide mesh - applies only on locations set in Blender. Default is 'Y'
    // },
    // {
    //     cameraLocationId: 'W01Z01',
    //     floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
    //     locationName: `Look Under Seat #1`,
    //     cameraPosition: null,
    //     cameraRotation: null,
    //     displayMesh: 'N',
    // },
    // {
    //     cameraLocationId: 'GroundFloorCamLoc',
    //     floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
    //     locationName: `Ground Floor`,
    //     cameraPosition: null,
    //     cameraRotation: null,
    //     displayMesh: 'N',
    //     cameraLocationLevel2Id: ['NANWENG01', 'NANCIDG01', 'NANHDSG01', 'NANHDSG02', 'NANHGSG01', 'NANHGSG01B', 'NANCLOG01', 'VENCORG01', 'VENRESG01', 'NANDEPG01'],
    // },
    // {
    //     cameraLocationId: 'NANWENG01',
    //     floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
    //     locationName: `Welcome to Venice`,
    //     cameraPosition: null,
    //     cameraRotation: null,
    //     displayMesh: 'N',
    //     cameraLocationIdOfChild: 'Y',
    // },

];

export {CameraLocationsDB, FLOOR_NUMBER};
