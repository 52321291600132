import {HoverState, MeshButtonCallback} from "./ButtonsTypes";
import * as THREE from "three";
import {RenderingValidationSingleton} from "../../Rendering/ts/RenderingValidationSingleton";
import {
    DEFAULT_BUTTON_OUTLINER_COLOR,
    DEFAULT_BUTTON_OUTLINER_SIZE,
    DEFAULT_HOVER_OVER_COLOR
} from "../../../client-data/clientOptions";
import {OutlineEffect} from "postprocessing";


export class MeshButtonBase {




    protected hoverState = HoverState.HOVER_OUT;
    protected _meshMat: THREE.Material;
    protected buttonOriginalColor: THREE.Color;
    private _disabled = false;

    constructor(private _button: THREE.Mesh,
                private _buttonId: string,
                private _onClickCallback?: MeshButtonCallback,
                private _onHoverOverCallback?: MeshButtonCallback,
                private _onHoverOutCallback?: MeshButtonCallback,
                private useOutliner?: boolean,
                private _outlinerColor?: THREE.Color,
                private _outlinerSize?:  THREE.Vector3,
                private outlineEffect?:OutlineEffect | null) {


        this._meshMat = this.getMaterial(this._button.material);
        this.buttonOriginalColor = this.getButtonOriginColour();
        // if (useOutliner) {
        //     this._meshMat.toneMapped = true;
        // }
        // else {
        //     this._meshMat.toneMapped = false;
        // }



        _button.matrixAutoUpdate = true;
        _button.updateMatrix();

    }

    protected getMaterial = (material: THREE.Material | Array<THREE.Material>): THREE.Material => {
        let objMat;
        if (Array.isArray(material)) {
            objMat = material[0]
        } else {
            objMat = material;
        }
        return objMat;

    }

    get disabled(): boolean {
        return this._disabled;
    }

    set disabled(value: boolean) {
        this._disabled = value;
    }

    protected getButtonOriginColour = (): THREE.Color => {
        return (<THREE.MeshStandardMaterial>this._meshMat).color
    }

    protected setMeshButtonColor = (color: string) => {

        (<THREE.MeshStandardMaterial>this._meshMat).color = new THREE.Color(color);
        this._meshMat.needsUpdate = true;
        RenderingValidationSingleton.getInstance().invalidateOnce();
    }

    protected getDefaultHoverOverColour = (): string => {
        return DEFAULT_HOVER_OVER_COLOR;
    }

    get meshMat(): THREE.Material {
        return this._meshMat;
    }

    get button(): THREE.Mesh {
        return this._button;
    }

    get buttonId(): string {
        return this._buttonId;
    }

    get onClickCallback(): MeshButtonCallback | undefined {
        return this._onClickCallback;
    }

    get onHoverOverCallback(): MeshButtonCallback  | undefined {
        return this._onHoverOverCallback;
    }

    get onHoverOutCallback(): MeshButtonCallback  | undefined {
        return this._onHoverOutCallback;
    }


    set onClickCallback(value: MeshButtonCallback  | undefined) {
        this._onClickCallback = value;
    }

    set onHoverOverCallback(value: MeshButtonCallback  | undefined) {
        this._onHoverOverCallback = value;
    }

    set onHoverOutCallback(value: MeshButtonCallback  | undefined) {
        this._onHoverOutCallback = value;
    }

    hoverOver = () => {

        if (this.disabled) return;

        if (this.hoverState === HoverState.HOVER_OVER) {
            return;
        }

        document.body.style.cursor = "pointer";

        if (this.onHoverOverCallback) {
            this.onHoverOverCallback(this.buttonId)
        }
        if (this.useOutliner) {
            if (this.outlineEffect) {
                this.outlineEffect.uniforms.get("visibleEdgeColor")!.value.setHex(this.outlinerColor.getHex()).convertSRGBToLinear();
                this.outlineEffect.selection.add(this._button)
                RenderingValidationSingleton.getInstance().invalidateOnce();
            }
        }
        else {
           // this.setMeshButtonColor(this.getDefaultHoverOverColour());
        }
        this.hoverState = HoverState.HOVER_OVER;
    }

    hoverOut = () => {

        if (this.disabled) return;

        if (this.hoverState === HoverState.HOVER_OUT) {
            return;
        }
        if (this.useOutliner) {
            if (this.outlineEffect) {
                this.outlineEffect.selection.delete(this._button);
                RenderingValidationSingleton.getInstance().invalidateOnce();
            }
        }
        else {
           // this.setMeshButtonColor(`#${this.buttonOriginalColor.getHexString()}`);
        }
        document.body.style.cursor = "default";

        if (this.onHoverOutCallback) {
            this.onHoverOutCallback(this.buttonId)
        }
        this.hoverState = HoverState.HOVER_OUT;
    }

    buttonClicked = () => {
        console.log("MeshButtonBase - buttonId:", this.buttonId);
        if (this.disabled) return;
        console.log("Button clicked:",this.buttonId)

        if (this.onClickCallback) {
            this.onClickCallback(this.buttonId);
        }
    }

    get outlinerColor(): THREE.Color {
        if (!this._outlinerColor) {
            return DEFAULT_BUTTON_OUTLINER_COLOR.clone();
        }
        return this._outlinerColor;
    }

    set outlinerColor(value: THREE.Color) {
        this._outlinerColor = value;
    }

    get outlinerSize(): THREE.Vector3 {
        if (!this._outlinerSize) {
            return DEFAULT_BUTTON_OUTLINER_SIZE.clone()
        }
        return this._outlinerSize;
    }

    set outlinerSize(value: THREE.Vector3) {
        this._outlinerSize = value;
    }
}
