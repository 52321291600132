
import AnnotationDataController from "./AnnotationDataController";

class MediaController{
    constructor(annotationContainer,videoContainer,css3dContainer, meshContainer) {
        this._annotationContainer = annotationContainer;
        this._videoContainer = videoContainer;
        this._css3dContainer = css3dContainer;
        this._meshContainer = meshContainer;
    }

    static getNodeRecord = (node) => {
        return AnnotationDataController.getAnnotationDataById(node.userData.painting_id);
    }
    addMedia = (node) => {
        const mediaRecord = MediaController.getNodeRecord(node);
        if (mediaRecord) {
            if (mediaRecord.isCSS3DObject) {
                this._css3dContainer.addCSS3DObject(node,mediaRecord);
            }
            else {
                if (mediaRecord.isAnnotation) {
                    this._annotationContainer.addAnnotationToMesh(node, mediaRecord);
                    if (mediaRecord.displayMesh === 'Y') {
                        this._meshContainer.addMesh(node);
                        // if (!mediaRecord.isAnnotationVideo) {
                        //     node.visible = false;
                        // }
                    }
                }
                if (mediaRecord.isStandaloneVideo) {
                    this._videoContainer.addVideoDisplay(mediaRecord, node);
                    if (!mediaRecord.isAnnotation) { //already been added via the annotation
                        this._meshContainer.addMesh(node);
                    }
                }
                else if (mediaRecord.isAudioPlayer) {
                    this._videoContainer.addVideoDisplay(mediaRecord, node);
                }
            }

            if (mediaRecord.displayMesh === 'N' && !(mediaRecord.isStandaloneVideo || mediaRecord.isAudioPlayer)) {
                node.visible = false;
            }


        }
        else {
            this._meshContainer.addMesh(node);
        }
    }

    addMediaWithNoParent = () => {
        const records = AnnotationDataController.getAnnotationsWithNoParent();
        records.forEach(mediaRecord => {
            if (mediaRecord.isAnnotation) {
                this._annotationContainer.addAnnotationToMesh(null, mediaRecord);
            }
            else if (mediaRecord.isCSS3DObject) {
                this._css3dContainer.addCSS3DObject(null,mediaRecord);
            }
            else if (mediaRecord.isAudioPlayer) {
                this._videoContainer.addVideoDisplay(mediaRecord, null);
            }

        })
    }

    get annotationContainer() {
        return this._annotationContainer;
    }

    get videoContainer() {
        return this._videoContainer;
    }

    clearMaterialCache = () => {
        this._annotationContainer.clearMaterialCache();
        this._videoContainer.clearMaterialCache();
    }

    pauseMouseListeners = (pause = true)=> {
        if (pause) {
            this.annotationContainer.removeMouseListeners();
            this.videoContainer.removeMouseListeners();
        }
        else {
            this.annotationContainer.addMouseListeners();
            this.videoContainer.addMouseListeners();
        }
    }

}

export default MediaController;
