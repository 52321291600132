import React, {Component} from "react";
import ContactLink from "../annotations/ContactLink";


export default class ExternalLinkComponent extends Component {
    constructor(props) {
        super(props);
        // console.log("[ExternalLinkComponent]", this.props);
        this.goToPage = this.goToPage.bind(this);
    }

    goToPage = (urlLink) => {
        window.open(urlLink, '_blank');
    }

    render() {
        // console.log("[ExternalLinkComponent]", this.props.noOfLink);
        const divClass = (this.props.index === (this.props.noOfLink - 1)) ? "bsh-external_links-link bsh-zone-links-last-rows" : "bsh-external_links-link bsh-zone-links-rows";
        return (
            <div className={divClass}>
                <div className="bsh-external_links-div">
                    <ContactLink key={`external_links_${this.props.index}`} contactName={this.props.externalLinkData.linkTitle}
                                 hrefLink={this.props.externalLinkData.linkHref}
                                 onClickHandler={() => this.goToPage(this.props.externalLinkData.linkHref)}
                    />
                </div>

            </div>

        );
    }
}
