import VideosMeshButtonObj from "./VideosMeshButtonObj";
import VideoRecord from "./VideoRecord";


export default class ListOfVideosLists {
    private videosMeshBtnRecords: Array<VideosMeshButtonObj> | null = null;

    constructor(videosDB: Array<VideosMeshButtonObj>) {
        if (videosDB != null) {
            this.videosMeshBtnRecords = new Array<VideosMeshButtonObj>();
            for (let videosMeshObj of videosDB) {
                this.videosMeshBtnRecords.push(videosMeshObj);
            }
        }

    }

    getVideosListByMeshBtnId(meshBtnId: string): VideosMeshButtonObj | null {
        if (this.videosMeshBtnRecords != null) {
            for (let i = 0; i < this.videosMeshBtnRecords.length; i++) {
                // console.log("[ListOfVideosLists] videosMeshBtnRecords: ",i, this.videosMeshBtnRecords[i]);
                let record: VideosMeshButtonObj = new VideosMeshButtonObj(this.videosMeshBtnRecords[i]);
                // console.log("[ListOfVideosLists] ", this.videosMeshBtnRecords[i]);
                if (record != null && record.meshButtonID === meshBtnId) {
                    // console.log("[ListOfVideosLists] Record: ", record);
                    return record;
                }
            }
        }
        return null;
    }


}