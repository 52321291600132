import * as THREE from "three";
import {ANNOTATION_TYPE} from "./GlobalConstants";
// import * as THREE from 'three';


export const ANNOTATION_CONTACT_TEXT = '';
export const CONTACT_URL = 'WEBSITE';
export const YOUTUBE = 'YOUTUBE';
export const INSTAGRAM = 'INSTAGRAM';
export const SPOTIFY = 'SPOTIFY';
export const TWITTER = 'TWITTER';
export const TIKTOK = 'TIKTOK';
export const IMDB = 'IMDB';
export const FACEBOOK = 'FACEBOOK';
// export const ENQUIRE_EMAIL = 'ENQUIRE';
export const ENQUIRE_EMAIL = 'Send Your Submission';
export const LINKED_IN = 'LINKEDIN';
export const HAS_MEDIA_CAROUSEL_LINK = 'MORE';
export const SEND_TO_EMAIL = 'SEND TO';
export const CANTALOUPE_IMAGE_SERVER = "https://iiif.v21artspace.com/iiif/2/eccb%2Feccb-2023%2F";
export const ANNOTATION_TEXT_ALIGNMENT = "justify";
export const ANNOTATION_TEXT_LINE_HEIGHT = "1.5";
export const GALLERY_LINK_LABEL = "TROUBLESHOOTING";

const INFO_TAG = '/assets/sprites/project_sprites/Info.svg';


const AnnotationsDB = [

    {
        paintingId: '01',
        annotationType: ANNOTATION_TYPE.THREE_D_MODAL,
        videoId: null,
        videoURL: null,
        headerText: null,
        bodyText: null,
        displayMesh: "Y",
        useMeshButton: "Y"
    },
    {
        paintingId: '02',
        annotationType: ANNOTATION_TYPE.THREE_D_MODAL,
        videoId: null,
        videoURL: null,
        headerText: null,
        bodyText: null,
        displayMesh: "Y",
        useMeshButton: "Y"
    },
    {
        paintingId: '03',
        annotationType: ANNOTATION_TYPE.THREE_D_MODAL,
        videoId: null,
        videoURL: null,
        headerText: null,
        bodyText: null,
        displayMesh: "Y",
        useMeshButton: "Y"
    },
    {
        paintingId: '04',
        annotationType: ANNOTATION_TYPE.THREE_D_MODAL,
        videoId: null,
        videoURL: null,
        headerText: null,
        bodyText: null,
        displayMesh: "Y",
        useMeshButton: "Y"
    },
    {
        paintingId: '05',
        annotationType: ANNOTATION_TYPE.THREE_D_MODAL,
        videoId: null,
        videoURL: null,
        headerText: null,
        bodyText: null,
        displayMesh: "Y",
        useMeshButton: "Y"
    },
    {
        paintingId: '01_back',
        annotationType: ANNOTATION_TYPE.THREE_D_MODAL,
        videoId: null,
        videoURL: null,
        headerText: null,
        bodyText: null,
        displayMesh: "Y",
        useMeshButton: "Y"
    },
    {
        paintingId: '02_back',
        annotationType: ANNOTATION_TYPE.THREE_D_MODAL,
        videoId: null,
        videoURL: null,
        headerText: null,
        bodyText: null,
        displayMesh: "Y",
        useMeshButton: "Y"
    },
    {
        paintingId: '03_back',
        annotationType: ANNOTATION_TYPE.THREE_D_MODAL,
        videoId: null,
        videoURL: null,
        headerText: null,
        bodyText: null,
        displayMesh: "Y",
        useMeshButton: "Y"
    },
    {
        paintingId: '04_back',
        annotationType: ANNOTATION_TYPE.THREE_D_MODAL,
        videoId: null,
        videoURL: null,
        headerText: null,
        bodyText: null,
        displayMesh: "Y",
        useMeshButton: "Y"
    },
    {
        paintingId: '05_back',
        annotationType: ANNOTATION_TYPE.THREE_D_MODAL,
        videoId: null,
        videoURL: null,
        headerText: null,
        bodyText: null,
        displayMesh: "Y",
        useMeshButton: "Y"
    },

];

export {AnnotationsDB};
