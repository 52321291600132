import React from "react";
import Modal from "react-bootstrap/Modal";
import Image from 'react-bootstrap/Image'
import Container from "react-bootstrap/Container";
import Carousel from "react-bootstrap/Carousel";
import {isMobile, isTablet} from "react-device-detect";

const HelpModal = (props) => {


    const helpVideo = 'Image_viewing_walkthrough.mp4';

    const hideModal = (evt) => {
        props.closefunc(evt);
    };

    // const imageURL =  window.isMobile ? process.env.PUBLIC_URL + "/images/MobilePortrait_QuestionMarkHelpCard.png" : process.env.PUBLIC_URL + "/images/Desktop_QuestionMarkHelpCard.png";
    const navigationImage = window.isMobile ? process.env.PUBLIC_URL + "/images/mobile_navigation.png" : process.env.PUBLIC_URL + "/images/desktop_navigation.png";
    const ThreeDViewerImage = (isMobile && !isTablet) ? process.env.PUBLIC_URL + "/images/mobile3DVBtn.png" : process.env.PUBLIC_URL + "/images/desktop3DVBtn.png";
    const menuIcons = process.env.PUBLIC_URL + "/images/menu_icons.png";
    const chromeFirefoxImg = process.env.PUBLIC_URL + "/images/chrome_firefox.png";

    const imageStyle = window.isMobile ? {maxWidth: "90%"} : {maxWidth: "60%"};
    const ThreeDViewerImageStyle = (window.isMobile && !window.isTablet) ? {maxWidth: "90%"} : {maxWidth: "100%"};
    const chromeFirefoxStyle = window.isMobile ? {maxWidth: "20%"} : {maxWidth: "10%"};
    const ThreeDViewerText = (window.isMobile && !window.isTablet ) ? `Click on this button to access the '3D Object Viewer' <br>where you can inspect the busts in more detail, <br>for detailed descriptions and comparison please view on laptop, tablet or desktop.` : `Click on this button to access the '3D Object Viewer'<br>where you can inspect the busts in more detail,<br>with descriptions and comparisons.`;

    return (
            <Modal dialogClassName={"primaryModal modal-dialog modal-dialog-centered modal-dialog-scrollable"} show={props.showHelp}
                   onHide={hideModal} size={props.modelType}
                   className={"help-dialog"}
                   scrollable={"true"}
                // className="v21-modal-color"
            >
                {/*className="v21-modal-color"*/}
                <Modal.Header closeButton style={{border:'none'}}  >
                </Modal.Header>
                <Modal.Body>
                    <Carousel interval={null} indicators={false}>
                        <Carousel.Item>
                            <Container className="text-center">
                                <div className="help-divs">
                                    {window.isMobile ?
                                        <h5>Navigation Tips</h5>
                                        :
                                        <h4>Navigation Tips</h4>
                                    }

                                    <div className="text-center">
                                        <p className="help-image-paragraph">
                                            <Image className="img-responsive"
                                                // style={{maxWidth: "60%"}}
                                                   style={imageStyle}
                                                   src={navigationImage}
                                                   alt={"Entry Image"} fluid/>
                                        </p>
                                    </div>
                                </div>

                            </Container>
                        </Carousel.Item>

                        {/*{window.isMobile ? null*/}
                        {/*    :*/}
                        {/*    <Carousel.Item>*/}
                        {/*        <div>*/}
                        {/*            <h5 className="text-center">'How To' Video</h5>*/}
                        {/*            <video playsInline width="100%" controls>*/}
                        {/*                <source src={process.env.PUBLIC_URL + `/videos/help/${helpVideo}`} type="video/mp4"/>*/}
                        {/*                <source src={process.env.PUBLIC_URL + `/videos/help/${helpVideo}`} type="video/ogg"/>*/}
                        {/*                Your browser does not support HTML video.*/}
                        {/*            </video>*/}
                        {/*        </div>*/}
                        {/*    </Carousel.Item>*/}
                        {/*}*/}

                        {!window.isMobile ?
                        <Carousel.Item>
                            <Container className="text-center">
                                {/*<div className="help-divs">*/}
                                    <h4>Menu Icons</h4>
                                    <p className="text-center help-image-paragraph">
                                        <Image className="img-responsive"
                                               style={imageStyle}
                                               src={menuIcons}
                                               alt={"Entry Image"} fluid/>
                                    </p>
                            </Container>
                        </Carousel.Item>
                            : null
                        }

                        <Carousel.Item>
                            <Container className="text-center">
                                        <div>
                                            {window.isMobile ?
                                                <h5 className="text-center">3D Object Viewer Button</h5>
                                                :
                                                <h4 className="text-center">3D Object Viewer Button</h4>
                                            }
                                                <p className="text-center help-image-paragraph">
                                                    <Image className="img-responsive"
                                                           style={imageStyle}
                                                           src={ThreeDViewerImage}
                                                           alt={"Entry Image"} fluid/>
                                                </p>
                                                {/*<p className="help-image-paragraph"*/}
                                                {/*   dangerouslySetInnerHTML={{__html: ThreeDViewerText}}>*/}

                                                {/*</p>*/}

                                        </div>
                            </Container>
                        </Carousel.Item>
                        {/*{mobileTroubleshooting()}*/}
                    </Carousel>
                </Modal.Body>
            </Modal>
    );
};

export default HelpModal;