import React, {useEffect} from "react";
import QuestionMark from "../../icons/QuestionMarkGray.svg";


import {Modal} from "react-bootstrap";
import Image from "react-bootstrap/Image";

const navigationImage = process.env.PUBLIC_URL + "/images/NavigationHelp.png";

const ThreeDViewerInstructions = (props) => {
    const myBtnRef = React.useRef(null);
    const myImageRef = React.useRef(null);
    const [showTooltip, setShowTooltip] = React.useState(false);
    const [width, setWidth] = React.useState(0);

    const onClickHandler = () => {
        // console.log("[ThreeDViewerInstructions] clicked");
        // const toggleFlag = !showTooltip;
        // console.log("[ThreeDViewerInstructions] clicked", toggleFlag);
        // setShowTooltip(toggleFlag);
        props.onClick();
        setShowTooltip(true);
    }

    const hideModal = () => {
        setShowTooltip(false);
    }

    useEffect(() => {
        // if (myImageRef.current) {
        //     const width = myImageRef.current.clientWidth;
        //     console.log("[ThreeDViewerInstructions] width", width)
        //     setWidth(width)
        // }
    }, );

    return (
        <>
            <button ref={myBtnRef} className="three-d-reset-icon-button" onClick={onClickHandler}>
                <img src={QuestionMark} className="three-d-reset-icon"/>
            </button>
            <Modal
                dialogClassName="primaryModal modal-dialog modal-dialog-centered modal-dialog-scrollable modal-max-size"
                show={showTooltip}
                onHide={hideModal}
                size="xl"
                scrollable={"false"}
                backdrop="static"
                className="three-d-modal-help"
                centered
            >
                <Modal.Header className="three-d-modal-header" closeVariant="white" closeButton>
                    <h3>Navigation Help</h3>
                </Modal.Header>
                <Modal.Body style={{height: props.height}}>
                    <div ref={myImageRef} className="text-center three-d-instructions">
                        <Image className="three-d-help-img"
                            // style={imageStyle}
                               src={navigationImage}
                               alt={"Entry Image"}/>

                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

{/*<>*/
}
{/*    <button ref={myBtnRef} className="three-d-reset-icon-button" onClick={onClickHandler}>*/
}
{/*        <img src={QuestionMark} className="three-d-reset-icon"/>*/
}
{/*    </button>*/
}
{/*    <Overlay target={myBtnRef.current} show={showTooltip} placement="right">*/
}
{/*        {({*/
}
{/*              placement: _placement,*/
}
{/*              arrowProps: _arrowProps,*/
}
{/*              show: _show,*/
}
{/*              popper: _popper,*/
}
{/*              hasDoneInitialMeasure: _hasDoneInitialMeasure,*/
}
{/*              ...props*/
}
{/*          }) => (*/
}
{/*            <div*/
}
{/*                {...props}*/
}
{/*                style={{*/
}
{/*                    position: 'absolute',*/
}
{/*                    backgroundColor: 'rgba(0, 0, 0, 0.85)',*/
}
{/*                    zIndex: 1100,*/
}
{/*                    padding: '2px 10px',*/
}
{/*                    color: 'white',*/
}
{/*                    borderRadius: 3,*/
}
{/*                    ...props.style,*/
}
{/*                }}*/
}
{/*                dangerouslySetInnerHTML={{__html: THREE_D_VIEWER_NAVIGATION_INSTRUCTIONS}}*/
}
{/*            >*/
}
{/*            </div>*/
}
{/*        )}*/
}
{/*    </Overlay>*/
}
{/*</>*/
}


export default ThreeDViewerInstructions;