export default class ExternalLinkRecord {
    public linkId: string;
    public linkTitle: string;
    public linkHref: string;


    constructor(externalLinkRecord: any) {
        // console.log("ExternalLinkRecord is created", externalLinkRecord);
        this.linkId = externalLinkRecord.linkId;
        this.linkTitle = externalLinkRecord.linkTitle;
        this.linkHref = externalLinkRecord.linkHref;
    }

}