import React, {useEffect, useRef} from 'react';
import * as Hls from "hls.js";
import {useParams} from "react-router-dom";

const  testVideoStreamURL =  `https://bitdash-a.akamaihd.net/content/MI201109210084_1/m3u8s/f08e80da-bf1d-4e3d-8899-f0f6155f6efa.m3u8`
const BSHF_AUDITORIUM_B_STREAM = `https://cdn3.wowza.com/1/b3NOK0JXbyt3akpR/ZzlnNjJL/hls/live/playlist.m3u8`;
const BSHF_AUDITORIUM_A_STREAM = `https://cdn3.wowza.com/1/cWozWCs5U0RscXZx/dDE2d2dS/hls/live/playlist.m3u8`;

function BSStreamPage(props) {

        const param = useParams();
        // console.log("[BSStreamPage]", param);

        let mount = useRef();
        let videoStreamSrc = null;

        switch (String(param.id )) {

            case 'AA':
                videoStreamSrc = BSHF_AUDITORIUM_A_STREAM;
                break;
            case 'AB':
                videoStreamSrc = BSHF_AUDITORIUM_B_STREAM;
                break;
            case 'TEST' :
                videoStreamSrc = testVideoStreamURL;
                break;
        }


    useEffect(() => {
        // console.log("BSStreamPage HLS pre-init");
        if (Hls.isSupported()) {
            // console.log("BSStreamPage HLS init",param.id);
            const hls = new Hls();
            if ( videoStreamSrc) {
                // console.log("[BSStreamPage] mount= ", mount);
                // console.log("[BSStreamPage] videoStreamSrc= ", videoStreamSrc);
                hls.loadSource(videoStreamSrc);
                hls.attachMedia(mount.current);
                const self = this;
                hls.on(Hls.Events.MANIFEST_PARSED, function () {
                    console.log("BSStreamPage Hls.Events.MANIFEST_PARSED:", param.id);

                });
            }
        }
        else {
            console.log("BSStreamPage HLS is not supported");
        }
    })

        return (
            <div id="BSStreamPage-id">
                <video  ref={mount} id="BSStreamPageVideo" controls>
                    <source   type="video/mp4"/>
                    <source   type="video/ogg"/>
                    Your browser does not support the video tag.
                </video>
            </div>

        )

}



export default BSStreamPage;
