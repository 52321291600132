import * as THREE from "three"
import {ViewerOptionsTypes} from "../../components/Viewer3DModal/types/ViewerTypes";

export const MODAL3D_OPTIONS: ViewerOptionsTypes = {
    envColorImage: 'adams_place_bridge_02k.hdr',
    envBackgroundImage: 'none',
    background: true,
    physicallyCorrectLights: true,
    exposure: 2.0,
    lerpToExposure: true,
    lerpDuration: 1000,
    textureEncoding: THREE.sRGBEncoding,  //THREE.sRGBEncoding | THREE.LinearEncoding
    outputEncoding: THREE.sRGBEncoding, //THREE.sRGBEncoding | THREE.LinearEncoding
    envMapIntensity:0.9,
    toneMapping: 'ACESFilmicToneMapping',
    usePostprocessing: false,
    ambientLight: true,
    // ambientLightIntensity: 3.3,
    ambientLightIntensity: 3.3,
    ambientLightColor: 0xFFFFFF,
    cameraFOV: 20.7
}
