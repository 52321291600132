import * as THREE from 'three';
import {Path} from "./Path";
import {RenderingValidationSingleton} from "../../../Rendering/ts/RenderingValidationSingleton";
import * as TWEEN from "@tweenjs/tween.js";

const UP_VEC3 = new THREE.Vector3(0,1,0);

export class PathMovingPlayer {

    private _initialSpeed = 4;
    private _maxSpeed = 12;
    private _minSpeed = .5;
    private _angularSpeed = 3;
    private _acceleration = .2;
    private _deAcceleration = .2;
    private _distanceToWaypointThreshold = .01;
    private _deAccelerationDistance = 1;

    private _canAccelerate = true;
    private _canDeAccelerate = true;
    private _canRotate = true;
    private _playerHeight;

    private _detectCollider = false;
    private _stopOnCollision = false;

    private _lookAtTarget = true;

    private _start = false;

    private _path: Path | null = null;
    private nextWayPoint : THREE.Vector3 | null = null;
    private _speed = 0;
    private _finalTarget: THREE.Vector3 | null = null;

    private _vec3 = new THREE.Vector3();
    private _heightVec = new THREE.Vector3();
    private _m1 = new THREE.Matrix4();
    private _quat = new THREE.Quaternion();

    private _deAccelerate = false;
    private _isLastWayPoint = false;
    private _speedSumForOneWayPoint = 0;

    constructor( private player: THREE.Object3D, playerHeight = 1.5) {

        this._playerHeight = playerHeight;
        this._heightVec.setY(playerHeight);

    }

    update(delta: number) : void {

        if (!this.start || !this.path || !this.nextWayPoint) return;
        const vec = this.getDisplacementVec(this.nextWayPoint);
        //console.log("PathMovingPlayer vec 1a", vec.clone());
        const lenSq = vec.lengthSq();
        if (lenSq > this._distanceToWaypointThreshold) {
            vec.normalize();

            if (!this._deAccelerate && this.canDeAccelerate) {
                if (this.path.lengthFromPosToEnd(this.player.position) < this.deAccelerationDistance) {
                    this._deAccelerate = true;
                    //console.log("*******************************PathMovingPlayer deaccelerate");
                }
            }

            if (this._deAccelerate) {

                this._speed -= (this.deAcceleration);

            }
            else {
                if (this.canAccelerate) {
                    this._speed += (this.initialSpeed + this.acceleration);
                }
                else {
                    this._speed += this.initialSpeed;
                }
            }
           // console.log("PathMovingPlayer vec 1b", this._speed);
            this._speed = Math.min(this.maxSpeed, this._speed);
            this._speed = Math.max(this.minSpeed, this._speed);
            //console.log("PathMovingPlayer vec 1c", this._speed);
            const deltaSpeed = this._speed * delta;
            this._speedSumForOneWayPoint += deltaSpeed;
            vec.multiplyScalar(deltaSpeed);
            //console.log("PathMovingPlayer vec 1d", vec.clone(), lenSq ,this._speed);
            this.player.position.add(vec);

            if (this.lookAtTarget) {
                if (this._isLastWayPoint) {
                    if (this._finalTarget) {
                        const rot = this.playerLookAt(this._finalTarget);
                        this.player.quaternion.slerp(rot,  this._speedSumForOneWayPoint * delta * this.angularSpeed);
                    }
                }
                else {
                    const rot = this.playerLookAt(this.nextWayPoint);
                    // this.player.quaternion.slerp(rot, delta * this.angularSpeed);
                    this.player.quaternion.slerp(rot,  this._speedSumForOneWayPoint * delta * this.angularSpeed);
                }
            }
        }
        else {
            this._speedSumForOneWayPoint = 0;
            this.player.position.copy(this.nextWayPoint);
            //console.log("******PathMovingPlayer vec 2", this.nextWayPoint.clone());
            this.nextWayPoint = this.path.next();
            if (!this.nextWayPoint) {
                if (this._finalTarget) {
                    this._finalTarget.y = this.player.position.y;
                    this.rotateCameraToTarget(this._finalTarget,2000);
                }
                this._deAccelerate = false;
                this._start = false;
                this.path = null;
                this._finalTarget = null;
                setTimeout(()=> {

                    RenderingValidationSingleton.getInstance().decRenderingFlag();
                },1000);
            }
            else {
                this.nextWayPoint.y += this.playerHeight;
                this._isLastWayPoint = this.path.isLastWayPoint;
                if (this._isLastWayPoint) {

                    if (this._finalTarget) {
                        this._finalTarget.y = this.player.position.y;
                    }
                }
            }
        }
    }

    getDisplacementVec(vec: THREE.Vector3): THREE.Vector3 {

        this._vec3.copy(vec).sub(this.player.position);
        return this._vec3;
    }

    playerLookAt(target: THREE.Vector3): THREE.Quaternion {

        this.player.updateWorldMatrix(true, false);
        this._vec3.setFromMatrixPosition(this.player.matrixWorld);
        this._m1.lookAt(this._vec3, target, UP_VEC3);
        this._quat.setFromRotationMatrix(this._m1);
        return this._quat;
    }

    rotateCameraToTarget = (target: THREE.Vector3, duration = 0, onCompleteCB?: (() => void)): (TWEEN.Tween<{t: number}>) | null => {

        this.player.updateWorldMatrix(true, false);
        this._vec3.setFromMatrixPosition(this.player.matrixWorld);
        this._m1.lookAt(this._vec3, target, UP_VEC3);
        this._quat.setFromRotationMatrix(this._m1);
        const angle = this.player.quaternion.angleTo(this._quat);

        const theSelf = this;

        if (duration) {

            let time = {t: 0};
            const tween = new TWEEN.Tween(time)
                .to({t: 1}, duration)
                .easing(TWEEN.Easing.Circular.InOut)
                .onUpdate(() => {
                    theSelf.player.quaternion.slerp( theSelf._quat, time.t);
                })
                .onComplete(() => {
                    if (onCompleteCB) {
                        onCompleteCB();
                    }
                })
                .start()
            return tween;
        } else {
            theSelf.player.quaternion.copy(theSelf._quat);
            if (onCompleteCB) {
                onCompleteCB();

            }
            return null;
        }
    }

    get path(): Path | null {
        return this._path;
    }

    set path(value: Path | null) {
        this._path = value;
    }

    get start(): boolean {
        return this._start;
    }

    startMoving(newPath: Path, finalTarget?: THREE.Vector3) {
        this._speedSumForOneWayPoint = 0;
        this._isLastWayPoint = false;
        this._speed = 0;
        this.path = newPath;
        this.nextWayPoint = newPath.next();
       // console.log("startMoving nextWayPoint =",this.nextWayPoint);
        if (this.nextWayPoint) {
            this.nextWayPoint.y += this.playerHeight;
            if (finalTarget) {
                this._finalTarget = finalTarget;
            }
            this._isLastWayPoint = this.path.isLastWayPoint;
            if (this._isLastWayPoint) {

                if (this._finalTarget) {
                    this._finalTarget.y = this.player.position.y;
                }
            }
            RenderingValidationSingleton.getInstance().incRenderingFlag();
            this._start = true;
        }
    }

    get lookAtTarget(): boolean {
        return this._lookAtTarget;
    }

    set lookAtTarget(value: boolean) {
        this._lookAtTarget = value;
    }

    get maxSpeed(): number {
        return this._maxSpeed;
    }

    set maxSpeed(value: number) {
        this._maxSpeed = value;
    }

    get minSpeed(): number {
        return this._minSpeed;
    }

    set minSpeed(value: number) {
        this._minSpeed = value;
    }

    get angularSpeed(): number {
        return this._angularSpeed;
    }

    set angularSpeed(value: number) {
        this._angularSpeed = value;
    }

    get acceleration(): number {
        return this._acceleration;
    }

    set acceleration(value: number) {
        this._acceleration = value;
    }

    get distanceToWaypointThreshold(): number {
        return this._distanceToWaypointThreshold;
    }

    set distanceToWaypointThreshold(value: number) {
        this._distanceToWaypointThreshold = value;
    }

    get canAccelerate(): boolean {
        return this._canAccelerate;
    }

    set canAccelerate(value: boolean) {
        this._canAccelerate = value;
    }

    get canRotate(): boolean {
        return this._canRotate;
    }

    set canRotate(value: boolean) {
        this._canRotate = value;
    }

    get detectCollider(): boolean {
        return this._detectCollider;
    }

    set detectCollider(value: boolean) {
        this._detectCollider = value;
    }

    get stopOnCollision(): boolean {
        return this._stopOnCollision;
    }

    set stopOnCollision(value: boolean) {
        this._stopOnCollision = value;
    }

    get initialSpeed(): number {
        return this._initialSpeed;
    }

    set initialSpeed(value: number) {
        this._initialSpeed = value;
    }

    get playerHeight(): number {
        return this._playerHeight;
    }

    set playerHeight(value: number) {
        this._playerHeight = value;
        this._heightVec.setY(value);
    }

    get deAcceleration(): number {
        return this._deAcceleration;
    }

    set deAcceleration(value: number) {
        this._deAcceleration = value;
    }

    get canDeAccelerate(): boolean {
        return this._canDeAccelerate;
    }

    set canDeAccelerate(value: boolean) {
        this._canDeAccelerate = value;
    }


    get deAccelerationDistance(): number {
        return this._deAccelerationDistance;
    }

    set deAccelerationDistance(value: number) {
        this._deAccelerationDistance = value;
    }
}
