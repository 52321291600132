import * as THREE from "three";
import {CompletePlayerCallBack, MeshTransform, UpdatePlayerCallBack} from "./NavMeshUtils";
import {INavMeshContainer} from "./INavMeshContainer";

export abstract class INavMeshPlayer {
    protected abstract _navMeshContainer:INavMeshContainer;
    protected playerPosition:THREE.Vector3;
    protected path?:Array<THREE.Vector3>;


    constructor(protected player:THREE.Object3D,
                protected navMeshPos: THREE.Vector3,
                protected onInitCallBack:UpdatePlayerCallBack = null,
                protected onStartPlayerCallBack:UpdatePlayerCallBack = null,
                protected onUpdatePlayerCallBack:UpdatePlayerCallBack = null,
                protected onCompletePlayerCallBack:CompletePlayerCallBack = null) {

        this.player.updateMatrixWorld();
        this.playerPosition = player.position.clone();
        this.playerPosition.y = navMeshPos.y;
    }

    rayTraceNaveMesh = (origin: THREE.Vector3 = this.player.position,direction:THREE.Vector3 = new THREE.Vector3(0,-1,0)): Array<THREE.Intersection> => {

        return this.navMeshContainer.rayTraceNaveMesh(origin,direction);
    }
    abstract updateGroup(newPos:THREE.Vector3):void;
    abstract updateGroupIDAtPlayerPos(origin: THREE.Vector3):number | null
    abstract clampStep(playerPos:THREE.Vector3,targetPos:THREE.Vector3, updateGroup: boolean):THREE.Vector3 | null;
    abstract navigateToTargetPos (targetPos:MeshTransform,updateGroup:boolean ,bypassTargetIntersectionCheck:boolean, delay:number): boolean;
    abstract getPathForTargetPos(targetPos:MeshTransform,updateGroup:boolean,bypassTargetIntersectionCheck:boolean): Array<THREE.Vector3> | null;
    abstract update():void;

    removeDuplicateFromPath (pathArray: Array<THREE.Vector3>): Array<THREE.Vector3> {


        const newPath: Array<THREE.Vector3> = [];
        let prevPos = pathArray[0];
        newPath.push(prevPos);
        if (pathArray.length === 1) return newPath;
        for (let x = 1; x < pathArray.length; x++) {
            //console.log(`pathArray[${x}]=${pathArray[x]}`);
            const vec = prevPos.clone().sub(pathArray[x]);
            if (vec.lengthSq() < .1) continue;
            prevPos = pathArray[x];
            newPath.push(pathArray[x]);
        }

        return newPath;
    }

    get navMeshContainer(): INavMeshContainer {
        return this._navMeshContainer;
    }

}
