
import * as THREE from "three";
import {EnvironmentMapsTypes, MaterialPropsType} from "./types/ViewerTypes";
import {MODAL3D_MATERIAL_PROPS_DB} from "../../client-data/ts/Modal3DMaterialDB";
import {Nullable, UI3Material, UI3Mesh, UI3Object3D} from "./types/types";


export class MaterialPropProcessor {

    private mpmRecords: Record<number, THREE.MeshPhysicalMaterial> = {};
    private oldMatRecords: Record<number, THREE.Material> = {};
    private _enableUpdate:boolean = true;

    constructor(private scene: THREE.Scene ,private envMap: EnvironmentMapsTypes, private encoding: number, private envMapIntensity: number, processMaterials: boolean = true) {

        if (processMaterials) {
            this.traverseMaterialsAndUpdate(scene);
        }
    }

    static getMaterialPropsByName(materialName: string): Nullable<MaterialPropsType> {
        const record = MODAL3D_MATERIAL_PROPS_DB.filter(rec => rec.materialName === materialName);
        if (record && record.length > 0) {
            return record[0];
        }

        return null;
    }


    updateMaterialProps = (material: THREE.MeshStandardMaterial, node: THREE.Mesh): boolean => {
        if (!this.enableUpdate) return false;

        const matProp = MaterialPropProcessor.getMaterialPropsByName(material.name);
        let processed = false;
        if (matProp) {
            //console.log ("updateMaterialProps=",matProp )
            if ((matProp.convertToBasicMaterial || 'N') === 'Y') {
                let mpmMat = this.mpmRecords[material.id];
                if (!mpmMat) {
                    const basicMat = new THREE.MeshBasicMaterial({});
                    basicMat.name = material.name + "-basic";
                    basicMat.alphaMap = material.alphaMap;
                    basicMat.aoMap = material.aoMap;
                    basicMat.aoMapIntensity = material.aoMapIntensity;
                    basicMat.map = material.map;
                    basicMat.toneMapped = material.toneMapped;
                    basicMat.transparent = material.transparent;
                    basicMat.opacity = material.opacity;
                    node.material = basicMat;

                    // @ts-ignore
                    this.mpmRecords[material.id] = basicMat;
                    this.oldMatRecords[material.id] = material;

                    // @ts-ignore
                    material = basicMat;


                    // console.log("updateMaterialProps=", basicMat);
                }
                else {
                    node.material = mpmMat;
                }

            }
            if (matProp.glass) {
                let mpmMat = this.mpmRecords[material.id];
                if (!mpmMat) {
                    if (matProp.glassMatFunc)  mpmMat = matProp.glassMatFunc(this.envMap);
                    this.mpmRecords[material.id] = mpmMat;
                    // console.log("updateMaterialProps: create Glass-",material.name, node)
                }
                this.oldMatRecords[material.id] = material;
                node.material = mpmMat;
                processed = true;
                console.log("updateMaterialProps: update Glass-",material.name, node)

            }
            else {

                if (matProp.aOIntensity) {
                    material.aoMapIntensity = matProp.aOIntensity;
                    processed = true;
                }

                if (matProp.emmisiveColor) {
                    material.emissive = matProp.emmisiveColor;
                    processed = true;
                }
                if (matProp.emmissiveIntensity) {
                    material.emissiveIntensity = matProp.emmissiveIntensity;
                    processed = true;
                }

                if (matProp.envMapIntensity) {
                    material.envMapIntensity = matProp.envMapIntensity;
                    processed = true;
                }

                if (matProp.color) {
                    material.color = matProp.color;
                    processed = true;
                }

                if ((matProp.useMapAsLightMap || 'N') === 'Y') {
                    material.lightMap = material.map;
                    if (matProp.lightMapIntensity) {
                        material.lightMapIntensity = matProp.lightMapIntensity;
                    }
                    processed = true;
                }

                if (matProp.roughness !== undefined) {
                    material.roughness = matProp.roughness;
                    processed = true;
                }

                if (matProp.metalness !== undefined) {
                    material.metalness = matProp.metalness;
                    processed = true;
                }

                if (matProp.toneMapped) {
                    material.toneMapped = matProp.toneMapped === 'Y';
                    processed = true;
                }
            }

        }
        return processed;
    }

    cleanOldMaterials = ()=> {
        for (const key of Object.keys(this.oldMatRecords)) {
            const value = this.oldMatRecords[Number.parseInt(key)];
            if (value) {
                console.log("MaterialPropProcessor delte not used mat:", value.name);
                value.dispose();
                delete this.oldMatRecords[Number.parseInt(key)];
            }
        }
    }

    cleanup = ()=> {
        for (const key of Object.keys(this.mpmRecords)) {
            const value = this.mpmRecords[Number.parseInt(key)];
            if (value) {
                value.dispose();
                delete this.mpmRecords[Number.parseInt(key)];
            }
        }
    }

    traverseMaterialsAndUpdate(object: UI3Object3D) {
        // console.log("traverseMaterialsAndUpdate begin");
        const self = this;
        object.traverse((nodeObject3d) => {
            // console.log("traverseMaterialsAndUpdate object =",nodeObject3d);
            // @ts-ignore
            if (!nodeObject3d.isMesh) return;
           // if (!node.visible) return;
            const node = nodeObject3d as UI3Mesh

            const tmpMat = (node as UI3Mesh).material;
            let materials: Array<UI3Material>;
            if (Array.isArray(tmpMat)) {
                materials = tmpMat;
            }
            else {
                materials = [tmpMat]
            }

           // const materials: Array<UI3Material> = Array.isArray((node as UI3Mesh).material) ? (node as UI3Mesh).material : [(node as UI3Mesh).material];
            materials.forEach( material => {
                // console.log("traverseMaterialsAndUpdate material =",material);
                // @ts-ignore
                if (material.map) material.map.encoding = self.encoding;
                // @ts-ignore
                if (material.emissiveMap) material.emissiveMap.encoding = self.encoding;
                if (material.hasOwnProperty("envMapIntensity")) { // @ts-ignore
                    material.envMapIntensity = self.envMapIntensity;
                }
                //@ts-ignore
                const matPropProcessed = self.updateMaterialProps(material, node);
               // const matPropProcessed = false;

                if (matPropProcessed || material.hasOwnProperty("map") || material.hasOwnProperty("emissiveMap") || material.hasOwnProperty("envMapIntensity")) {
                    material.needsUpdate = true;
                    console.log("traverseMaterialsAndUpdate =", matPropProcessed);
                }
            });
        });
    }

    get enableUpdate(): boolean {
        return this._enableUpdate;
    }

    set enableUpdate(value: boolean) {
        this._enableUpdate = value;
    }

}
