import React, {useEffect, useRef, useState} from "react";
import Modal from "react-bootstrap/Modal";
import {Container, Row, Col} from "react-bootstrap";
import {ThreeDPartsViewer} from "./ThreeDPartsViewer";
import {ThreeDTwoObjectsViewer} from "./ThreeDTwoObjectsViewer";
import {CanvasViewer} from "./CanvasViewer";
import {ThreeDAudioPlayer} from "./ThreeDAudioPlayer";
import {BackToObjectView} from "./BackToObjectView";
import ThreeDInfo from "./ThreeDInfo";
import {isMobile, isTablet} from "react-device-detect";

import {
    getCompareImageIDbyID,
    getCompareInfo,
    getCompareTextSettings,
    getEnglishTitleByID,
    getGeneralTextAlignment,
    getGeneralTextSettings,
    getInitialInfoData,
    getItalianTitleByID,
    getPartInfo,
    getPartsImageIDbyID,
    getPartsInfoArrayByLanguage,
    getTitleByIDLanguage
} from "../Viewer3DModal/utils/Modal3DDBQuery";
import {LANGUAGE} from "../../client-data/GlobalConstants";
import ResetToInitialInfo from "./ResetToInitialInfo";
import ExtraFuntionalitiesJs from "./ExtraFuntionalities";
import ExtraFuntionalities from "./ExtraFuntionalities";
import ThreeDViewerInstructions from "./ThreeDViewerInstructions";
import ToastOverlayWithBtn from "../../util/ToastOverlayWithBtn";
import {MOBILE_TOAST_NOTIFICATION_TEXT} from "../../client-data/clientOptions";


// const PARTS_THUMBNAIL_TITLE = "OBJECT PARTS";
const PARTS_THUMBNAIL_TITLE = "DETAIL";
const OTHER_OBJECTS_THUMBNAIL_TITLE = "COMPARE";

const ThreeDViewerModal = (props) => {
    const [partIndex, setPartIndex] = useState("01");
    const [obj2Index, setObj2Index] = useState(null);
    const [showComparePage, setComparePage] = useState(false);
    const [objViewerStyle, setObjViewerStyle] = useState(6);
    const [txtViewerStyle, setTxtViewerStyle] = useState(3);
    const [threeDViewerClassName, setThreeDViewerClassName] = useState("g-0 three-d-object-viewer");
    const [partsImageData, setPartsImageData] = useState(null);
    const [partsArrayLength, setPartsArrayLength] = useState(0);
    const [compareData, setCompareData] = useState(null);
    const [language, setLanguage] = useState(props.language);
    const [infoHeight, setInfoHeight] = useState('60vh');
    const [allPartsInfo, setAllPartsInfo] = useState(null);
    const [textSettings, setTextSettings] = useState(null);
    const [isInitialInfoRequested, setIsInitialInfoRequested] = useState(true);
    const bodyRef = useRef(null);
    const [bodyHeight, setBodyHeight] = useState(0);
    // const [bodyWidth, setBodyWidth] = useState(0);
    const [showNotification, setShowNotification] = useState(true);


    const hideModal = (evt) => {
        props.closeModal(evt);
    };

    const partAudioURLExist = (partID, allPartsInfoRecords) => {
        // console.log("[ThreeDViewerModal/partAudioURLExist/partID]", allPartsInfo, partID);
        // const records = allPartsInfo ? allPartsInfo : allPartsInfoRecords
        const records = allPartsInfoRecords ? allPartsInfoRecords : allPartsInfo;
        if (records) {
            for (let i = 0; i < records.length; i++) {
                const record = records[i];
                // console.log("[ThreeDViewerModal/partAudioURLExist]", record);
                if (record && record.id === partID) {
                    if (record.audioURL) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    const compareAudioURLExist = (id1, id2, language) => {
        const record = getCompareInfo(id1, id2, language);
        if (record && record.audioURL) {
            return true;
        }
        return false;
    }

    /*
    This method updates the id when the thumbnail of a Part of the Object is clicked
    And calls the Single Viewer to show that part!
    * */
    const updatePart = (partID) => {
        // console.log("[ThreeDViewerModal] - updatePart", id);
        setPartIndex(partID);
        setIsInitialInfoRequested(false);
        showPartsClicked(partID);
    }

    /*
    This method updates the id when the thumbnail of an Object is clicked
    And calls the Double Viewer
    * */
    const updateObj = (id2) => {
        // console.log("[ThreeDViewerModal - updateObj]", id);
        setObj2Index(id2);
        onOtherObjectClicked(id2);
    }

    const showPartsClicked = (partID) => {
        // console.log("[ThreeDViewerModal/showPartsClicked]", partID);
        infoComponentHeightHandler(false, partAudioURLExist(partID, getPartsDataArrayByLanguage()));
        setTextSettings(getGeneralTextSettings(props.objectID));
        closeNotification();
        props.showPartsClicked(partID);
    }

    const onOtherObjectClicked = (id2) => {
        setComparePage(true);
        setObjViewerStyle(9);
        setTxtViewerStyle(0);
        setThreeDViewerClassName("gx-4 three-d-gap three-d-object-viewer");
        setObj2Index(id2);
        infoComponentHeightHandler(true, compareAudioURLExist(props.objectID, id2, language));
        setTextSettings(getCompareTextSettings(props.objectID, id2));
        closeNotification();
        props.compareOtherObjectClicked(id2);
    }

    const onClickBack = () => {
        setComparePage(false);
        setObjViewerStyle(6);
        setTxtViewerStyle(3);
        setThreeDViewerClassName("g-0 three-d-object-viewer");
        infoComponentHeightHandler(false, partAudioURLExist(partIndex, null));
        setTextSettings(getGeneralTextSettings(props.objectID));
        props.modal3DBackBtn();
    }


    const getTitleByLanguage = () => {
        const title = getTitleByIDLanguage(props.objectID, language);
        if (title) {
            return title;
        }
        return '<div class="three-d-title-empty"></div>';
    }

    const englishOnClickHandler = () => {
        closeNotification();
        setCompareData(getCompareImageIDbyID(props.objectID, language));
        setLanguage(LANGUAGE.EN);
        props.setLanguage(LANGUAGE.EN);
    }

    const italianOnClickHandler = () => {
        closeNotification();
        setCompareData(getCompareImageIDbyID(props.objectID, language));
        setLanguage(LANGUAGE.IT);
        props.setLanguage(LANGUAGE.IT);
    }

    const getPartsDataArrayByLanguage = () => {
        return getPartsInfoArrayByLanguage(props.objectID, language);
    }

    const infoComponentHeightHandler = (isCompareCalled, audioURLExists) => {
        // console.log("[ThreeDViewerModal] - isCompareCalled/audioURLExists", isCompareCalled, audioURLExists);
        if (isCompareCalled) {
            if (audioURLExists) {
                // console.log("[ThreeDViewerModal] - 30vh/isCompareCalled/audioURLExists", isCompareCalled, audioURLExists);
                setInfoHeight('30vh');
            } else {
                // console.log("[ThreeDViewerModal] - 40vh/isCompareCalled/audioURLExists", isCompareCalled, audioURLExists);
                setInfoHeight('40vh');
            }

        } else {
            // const infoData = getPartInfo(props.objectID, partIndex, language)
            if (audioURLExists) {

                // console.log("[ThreeDViewerModal] - 60vh/isCompareCalled/audioURLExists", audioURLExists);
                setInfoHeight('60vh');
            } else {
                // console.log("[ThreeDViewerModal] - 70vh/isCompareCalled/audioURLExists", audioURLExists);
                setInfoHeight('70vh');
            }
        }
    }

    /* This method is called to set the information Data of the first page either
    the initial data of the project or the data of a particular part  */
    const getInfoData = (aLanguage) => {
        // console.log("[ThreeDViewerModal/getInfoData] is called!");
        if (isInitialInfoRequested) {
            const initialInfoData = getInitialInfoData(props.objectID, aLanguage);
            const doesAudioExist = (initialInfoData && initialInfoData.audioURL) ? true : false;
            return initialInfoData;
        } else {
            return getPartInfo(props.objectID, partIndex, aLanguage);
        }
    }

    const doesInitialAudioFileExist = () => {
        const record = getInitialInfoData(props.objectID, language);
        return (record && record.audioURL) ? true : false;
    }

    const onClickResetHandler = () => {
        console.log("[ThreeDViewerModal - onClick Reset] is called!");
        setIsInitialInfoRequested(true);
        infoComponentHeightHandler(false, doesInitialAudioFileExist());
        closeNotification();
        props.onClickReset();
    }

    const displayTitle = () => {
        if((!isMobile || isTablet)) {
            if (!showComparePage && getEnglishTitleByID(props.objectID) && getItalianTitleByID(props.objectID)) {
                return getTitleByLanguage()
            } else if (showComparePage) {
                return getCompareTitle(obj2Index);
            }
        }

        return "<div class='three-d-title-empty'></div>"
    }

    const getCompareTitle = (id2) => {
        const record = getCompareInfo(props.objectID, id2, language);
        // console.log("[ThreeDViewerModal/getCompareTitle] is called", record);
        return (record && record.title) ? record.title : "<div class='three-d-title-empty'>Don't know</div>";

    }

    const closeNotification = () => {
        // console.log("[ThreeDViewerModal/closeNotification] is called!");
        setShowNotification(false);
    }

    /* This hook-method is only called when this component is mounted, only once!*/
    useEffect(() => {
        // console.log("[ThreeDViewerModal]/useEffect 1st is running!");
        if (!partsImageData) {
            const array = getPartsImageIDbyID(props.objectID);
            setPartsImageData(array);
            if (array) {
                setPartsArrayLength(array.length);
            }
        }

        if (!compareData) {
            setCompareData(getCompareImageIDbyID(props.objectID, language));
        }

        if (isMobile && !isTablet) {
            // console.log("ThreeDViewerModal isMobile!!", isMobile, isTablet);
            setObjViewerStyle(12);
        }

        // console.log("[ThreeDViewerModal]/useEffect 1st is running!", );
        if (!allPartsInfo) {
            const allPartsInfoRecords = getPartsInfoArrayByLanguage(props.objectID, language);
            if (allPartsInfoRecords) {
                setAllPartsInfo(allPartsInfoRecords);
            }
        }

        if (!textSettings) {
            setTextSettings(getGeneralTextSettings(props.objectID));
        }

        /* Get the right height of the first-column of info data
        (depending on it having an audio file or not) */
        infoComponentHeightHandler(false, doesInitialAudioFileExist());

        // Get the height of modal-body
        if(bodyRef.current) {
            const height = bodyRef.current.offsetHeight;
            // console.log("[ThreeDViewerModal] width", height);
            setBodyHeight(height);
        }
    }, []);

    /*This useEffect hook runs every time anything is rendered or re-rendered */
    useEffect(() => {
        // console.log("[ThreeDViewerModal]/useEffect 2nd is running!");
        props.modal3DAfterRenderCallback();
    },);

    return (
        <Modal
            dialogClassName="primaryModal modal-dialog modal-dialog-centered modal-dialog-scrollable modal-max-size"
            show={props.showModal}
            onHide={hideModal}
            size="xl"
            scrollable={"false"}
            backdrop="static"
            className="three-d-modal"
            centered
            onClick={closeNotification}
            onTouchStart={closeNotification}
        >
            <Modal.Header className="three-d-modal-header" closeButton>
                <Container fluid>
                    <Row className="three-d-header-container">
                        <Col md={10} style={{margin: "auto"}}>
                            <div className="three-d-viewer-title" dangerouslySetInnerHTML={{__html: displayTitle()}}>
                            </div>
                        </Col>
                        {(!isMobile || isTablet)
                            ?
                            <Col md={2} style={{height: "2.5rem"}} className="three-d-lang-btn-container">
                                <ThreeDViewerInstructions height={bodyHeight} onClick={closeNotification}/>
                                <button className="three-d-lang-btn" onClick={englishOnClickHandler}>EN</button>
                                <button className="three-d-lang-btn" onClick={italianOnClickHandler}>IT</button>
                            </Col>
                            :
                            null
                        }

                    </Row>
                </Container>

            </Modal.Header>
            <Modal.Body id="modal3dViewBody" ref={bodyRef}>
                <div id="main3DViewModalGuiDat"></div>
                <ToastOverlayWithBtn text={MOBILE_TOAST_NOTIFICATION_TEXT} show={showNotification}/>
                <Container fluid>
                    <Row className="three-d-grid-1st-row">
                        {!showComparePage && (!isMobile || isTablet) ?
                            <Col md={txtViewerStyle} className="three-d-info-container" id="Modal3DText">
                                <ThreeDInfo txtViewerStyle={txtViewerStyle}
                                            infoData={getInfoData(language)}
                                            infoHeight={infoHeight}
                                            textSettings={textSettings}
                                />
                            </Col>

                            :
                            null
                        }
                        <Col md={objViewerStyle} className={threeDViewerClassName}>
                            <div className="three-d-viewer" id="threeDViewer">
                                {/*Object goes here!*/}
                                <CanvasViewer onClick={closeNotification} />
                            </div>
                        </Col>
                        {(!isMobile || isTablet)
                            ?
                            <Col md={3} className="gx-4 three-d-thumbnails-container">
                                {/* Third Column */}
                                {!showComparePage
                                    ?
                                    <>
                                        <Row className="gx-0 three-d-parts-container">
                                            <h5 className="three-d-sub-title">
                                                <b>{PARTS_THUMBNAIL_TITLE}</b>
                                            </h5>
                                            {/* This shows a thumbnail slider with all the 3D-Object parts */}
                                            <ThreeDPartsViewer
                                                itemIndex={partIndex}
                                                updateIndex={updatePart}
                                                partsData={partsImageData}
                                                thumbnailCount={partsArrayLength}
                                            />
                                            {/*<ExtraFuntionalities onClick={onClickResetHandler} width={bodyWidth}/>*/}
                                            <ResetToInitialInfo onClick={onClickResetHandler}/>
                                        </Row>
                                    </>

                                    :
                                    <Row className="gx-0 three-d-compare-container">
                                        <Row className="gx-0 three-d-back-container">
                                            <BackToObjectView onClickBack={onClickBack}/>
                                        </Row>
                                        <Row className="gx-0 three-d-compare-info-container">
                                            <ThreeDInfo
                                                txtViewerStyle={txtViewerStyle}
                                                infoData={getCompareInfo(props.objectID, obj2Index, language)}
                                                showComparePage={showComparePage}
                                                infoHeight={infoHeight}
                                                textSettings={textSettings}
                                            />
                                        </Row>
                                    </Row>
                                }
                                <Row className="gx-0">
                                    <div className="three-d-grid-objects-title">
                                        <h5 className="three-d-sub-title"><b>{OTHER_OBJECTS_THUMBNAIL_TITLE}</b></h5>
                                    </div>
                                    {/*Objects thumbnails*/}
                                    {/* This shows a thumbnail slider with all the other available objects */}
                                    <ThreeDTwoObjectsViewer
                                        itemIndex={obj2Index}
                                        updateIndex={updateObj}
                                        // compareData={compareData}
                                        compareData={getCompareImageIDbyID(props.objectID, language)}
                                    />
                                </Row>
                            </Col>
                            :
                            null
                        }
                    </Row>
                    <div id="slider3DModal"></div>
                </Container>
            </Modal.Body>
        </Modal>
    )
};

export default ThreeDViewerModal;
