import * as THREE from "three"
import {ViewerOptionsTypes} from "./types/ViewerTypes";

export class MainCamera {


    private readonly _camera: THREE.PerspectiveCamera;

    constructor(width: number, height: number, private modal3dOptions:ViewerOptionsTypes,pos = new THREE.Vector3(0, 0, 1), near = .01, far = 100) {
        this._camera = new THREE.PerspectiveCamera(modal3dOptions.cameraFOV, width / height, near, far);
        this._camera.position.copy(pos);
    }

    get camera(): THREE.PerspectiveCamera {
        return this._camera;
    }

    dispose = ()=> {


        // @ts-ignore
        this._camera = null;
    }


}
