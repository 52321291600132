import React from "react";
import Carousel from "react-bootstrap/Carousel";
import DisplayMediaComponent from "./DisplayMediaComponent";
import Modal from "react-bootstrap/Modal";

/**
 * This component creates a Carousel of media: image, video, and/or iframe
 * This component doesn't show an annotation modal
 * @param props
 * @returns {*}
 * @constructor
 */
const OnlyMediaCarousel = (props) => {


    let displayCarouselItems = null;
    if (props.annotationData.mediaObjects && props.annotationData.mediaObjects.length > 0) {
        var isSingleMediaObject = props.annotationData.mediaObjects.length === 1;
        // console.log("[OnlyMediaCarousel]: isSingleMediaObject: ", isSingleMediaObject);
        displayCarouselItems = props.annotationData.mediaObjects.map((mediaObject, index) => {
            if (mediaObject.type === "image") {
                // console.log("[AnnotationModal] image media", mediaObject);
                let imageMedia = <DisplayMediaComponent
                    isAnnotationVideo={null}
                    videoURL={null}
                    iframeSource={null}
                    iiifSource={null}
                    iiifMultiSource={null}
                    imageUrl={process.env.PUBLIC_URL + mediaObject.link}
                    carouselUsed={true}
                    isMediaUsed={props.annotationData.isMediaUsed}
                />;

                return (
                    isSingleMediaObject ?
                        {imageMedia}
                        :
                        <Carousel.Item key={mediaObject.link}>
                            {imageMedia}
                        </Carousel.Item>
                )
            } else if (mediaObject.type === "iframe") {
                // console.log("[AnnotationModal] iframe media", mediaObject);
                let iframeMedia = <DisplayMediaComponent
                    isAnnotationVideo={null}
                    videoURL={null}
                    iframeSource={mediaObject.link}
                    iiifSource={null}
                    iiifMultiSource={null}
                    imageUrl={null}
                    carouselUsed={true}
                    isMediaUsed={props.annotationData.isMediaUsed}
                />;
                return (
                    isSingleMediaObject ?
                        {iframeMedia}
                        :
                        <Carousel.Item key={mediaObject.link}>
                            {iframeMedia}
                        </Carousel.Item>
                )
            } else if (mediaObject.type === "iiifSource") {
                //    iiifSource
                let iiifSourceMedia = <DisplayMediaComponent
                    isAnnotationVideo={null}
                    videoURL={null}
                    iframeSource={null}
                    iiifSource={mediaObject.link}
                    iiifMultiSource={null}
                    imageUrl={null}
                    carouselUsed={true}
                    isMediaUsed={props.annotationData.isMediaUsed}
                />;
                return (
                    isSingleMediaObject ?
                        <div key={mediaObject.link}>
                            {iiifSourceMedia}
                        </div>
                        :
                        <Carousel.Item key={mediaObject.link}>
                            {iiifSourceMedia}
                        </Carousel.Item>
                )
            } else if (mediaObject.type === "iiifMultiSource") {
                //iiifMultiSource
                let iifMultiSourceMedia = <DisplayMediaComponent
                    isAnnotationVideo={null}
                    videoURL={null}
                    iframeSource={null}
                    iiifSource={null}
                    iiifMultiSource={mediaObject.link}
                    imageUrl={null}
                    carouselUsed={true}
                    isMediaUsed={props.annotationData.isMediaUsed}
                />;
                return (
                    isSingleMediaObject ?
                        <div key={mediaObject.link}>
                            {iifMultiSourceMedia}
                        </div>
                        :
                        <Carousel.Item key={mediaObject.link}>
                            {iifMultiSourceMedia}
                        </Carousel.Item>
                )
            } else {
                // console.log("[AnnotationModal] video media", mediaObject);
                //Video Media
                let videoMedia = <DisplayMediaComponent
                    isAnnotationVideo={true}
                    videoURL={process.env.PUBLIC_URL + mediaObject.link}
                    iframeSource={null}
                    iiifSource={null}
                    iiifMultiSource={null}
                    imageUrl={null}
                    carouselUsed={true}
                    isMediaUsed={props.annotationData.isMediaUsed}
                />;
                return (
                    isSingleMediaObject ?
                        <div key={mediaObject.link}>
                            {videoMedia}
                        </div>
                        :
                        <Carousel.Item key={mediaObject.link}>
                            {videoMedia}
                        </Carousel.Item>
                )
            }
        })
    }

    let displayComponent = null;
    if(isSingleMediaObject) {
        displayComponent =  displayCarouselItems
    } else {
        displayComponent = <Carousel>{displayCarouselItems}</Carousel>

    }

    return (
        <div className="annotation-modal">
            <Modal
                dialogClassName={"primaryModal modal-dialog annotation-modal-classname modal-dialog-scrollable"}
                show={props.showMediaCarousel}
                onHide={props.mediaCarouselCloseBtnHandler} size={props.annotationModalSize} centered={true}
                className={"bsh-modal-color"}
                backdrop="static">
                <Modal.Header closeButton style={{border: 'none'}}>
                </Modal.Header>
                <Modal.Body>
                    {displayComponent}
                </Modal.Body>
            </Modal>
        </div>
    )

}

export default OnlyMediaCarousel;