import * as THREE from "three";

export class RenderingValidationSingleton {


    private static instance: RenderingValidationSingleton;
    private _renderingFlag = 0; //used for any kind of rendering request (if > 0) - not used for video players
    private _videoRenderingFlag = 0; //used for any kind of rendering request (if > 0) - not used for video players
    private _videosScreensRecord: Record<number, THREE.Mesh> = {};
    private _videoScreensArray: Array<THREE.Mesh> = [];
    private _invalidateOnce = 0;


    private constructor() {
    }

    public static getInstance(): RenderingValidationSingleton {
        if (!RenderingValidationSingleton.instance) {
            RenderingValidationSingleton.instance = new RenderingValidationSingleton();
        }

        return RenderingValidationSingleton.instance;
    }


    get renderingFlag(): number {
        return this._renderingFlag;
    }

    resetRenderingFlag = () => {
        this._renderingFlag = 0;
    }

    incRenderingFlag = ()=> {
        this._renderingFlag++;
    }

    decRenderingFlag = ()=> {
        this._renderingFlag--;
        if (this._renderingFlag < 0) {
            this._renderingFlag = 0;
        }
    }



    get videoRenderingFlag(): number {
        return this._videoRenderingFlag;
    }

    resetVideoRenderingFlag = () => {
        this._videoRenderingFlag = 0;
        this._videosScreensRecord = {};
        this._videoScreensArray.length = 0;
    }

    incVideoRenderingFlag = (videoScreen: THREE.Mesh)=> {

        //this._videoRenderingFlag++;

        if (!(videoScreen.id in this._videosScreensRecord)){
            this._videosScreensRecord[videoScreen.id] = videoScreen;
            this._videoScreensArray = Object.values(this._videosScreensRecord);
            this._videoRenderingFlag = this._videoScreensArray.length;
            console.log("incVideoRenderingFlag=", this._videoRenderingFlag);
        }
    }

    decVideoRenderingFlag = (videoScreen:THREE.Mesh)=> {
        // this._videoRenderingFlag--;
       /* if (this._videoRenderingFlag < 0) {
            this._videoRenderingFlag = 0;
        }*/

        if ((videoScreen.id in this._videosScreensRecord)){
            delete this._videosScreensRecord[videoScreen.id];
           this._videoScreensArray = Object.values(this._videosScreensRecord);
           this._videoRenderingFlag = this._videoScreensArray.length;
            console.log("decVideoRenderingFlag=", this._videoRenderingFlag);

        }
    }

    invalidateOnce = () => {
        this._invalidateOnce ++;
    }
    isInvalidatedOnce = ()=> {
        if (this._invalidateOnce > 0){
            this._invalidateOnce--;
            return true;
        }
        return false;

    }

    get videoScreensArray(): Array<THREE.Mesh> {
        return this._videoScreensArray;
    }
}
