import VideoRecord from "./VideoRecord";


export default class VideosMeshButtonObj {

    public meshButtonID: string;
    public videosList: VideoRecord[] = [];

    constructor(videoListMeshBtnObj: VideosMeshButtonObj) {
        this.meshButtonID = videoListMeshBtnObj.meshButtonID;
        // console.log("[VideosMeshButtonObj] videosListRec: ", videoListMeshBtnObj.videosList);
        // for(let videosListRec in videoListMeshBtnObj.videosList) {
        //      console.log("[VideosMeshButtonObj] videosListRec: " + videosListRec);
        //     this.videosList.push(new VideoRecord(videosListRec));
        // }

        for(let i =0;i<videoListMeshBtnObj.videosList.length; i++) {
            // console.log("[VideosMeshButtonObj] ", videoListMeshBtnObj.videosList[i]);
            this.videosList.push(new VideoRecord(videoListMeshBtnObj.videosList[i]));
        }
    }
}