import React, {Component} from "react";
import Modal from "react-bootstrap/Modal";
import ContactLink from "./ContactLink";
import Carousel from "react-bootstrap/Carousel";
import ItemAnnotationModal from "./ItemAnnotationModal";
import ReactGa from "react-ga4";
import DisplayMediaComponentProxy from "./DisplayMediaComponentProxy";
import ContactLinksModal from "./ContactLinksModal";
import {GALLERY_LINK_LABEL} from "./../../client-data/AnnotationDB";

class GalleryAnnotation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showChildWorks: false,
            showAnnotationModal: true,
            singleAnnotationItem: false,
        }



        this.showWorksHandler = this.showWorksHandler.bind(this);
        this.goToPage = this.goToPage.bind(this);
        this.hideChildWorksHandler = this.hideChildWorksHandler.bind(this);
        this.displayItemsInCarousel = this.displayItemsInCarousel.bind(this);
        this.createCarouselItem = this.createCarouselItem.bind(this);


        //This is part of the Google Analytics Module
        // console.log("ReactGa", this.props.annotationData.paintingId);
        ReactGa.event({
            category: 'Annotation',
            action: `Annotation is clicked: ${this.props.annotationData.paintingId}`,
        })
    }


    showWorksHandler = () => {
        this.setState({
            showChildWorks: true,
            showAnnotationModal: true
        });

    }

    goToPage = (urlLink) => {
        window.open(urlLink, '_blank');
    }

    hideChildWorksHandler = () => {
        this.setState({
            showChildWorks: false,
            showAnnotationModal: false
        })
    }

    displayItemsInCarousel = () => {
        if (this.props.annotationData.childPaintings && this.props.annotationData.childPaintings.length > 0) {

            if(this.props.annotationData.childPaintings.length == 1) {
                let singleChildAnnotationDate = this.props.annotationData.childPaintings[0];
                return <ItemAnnotationModal key={`annotation_modal_${singleChildAnnotationDate.paintingId}`}
                                                               showAnnotationModal={this.state.showAnnotationModal}
                                                               hideAnnotationModal={this.hideChildWorksHandler}
                                                               annotationModalSize={this.props.annotationModalSize}
                                                               annotationData={singleChildAnnotationDate}
                                                               isCarouselUsed={true}/>
            }
            return <Carousel indicators={false}>
                {this.createCarouselItem()}
            </Carousel>
        }
    }

    createCarouselItem = () => {
        let displayCarouselItems = [];
        this.props.annotationData.childPaintings.map((childPainting, index) => {
            // console.log("[GalleryAnnotation] child painting Object", childPainting,)
            displayCarouselItems.push(
                // <div key={childPainting.paintingId}>{childPainting.paintingId}</div>
                <Carousel.Item key={`carousel_item_${childPainting.paintingId}`}>
                    {/*<div key={childPainting.paintingId}>{childPainting.paintingId}</div>*/}
                    <ItemAnnotationModal key={`annotation_modal_${childPainting.paintingId}`}
                                         showAnnotationModal={this.state.showAnnotationModal}
                                         hideAnnotationModal={this.hideChildWorksHandler}
                                         annotationModalSize={this.props.annotationModalSize}
                                         annotationData={childPainting}
                                         isCarouselUsed={true}/>
                </Carousel.Item>
            )
        })
        return displayCarouselItems;
    }


    render() {

        const works = <ContactLink key="galleryWorks" contactName={GALLERY_LINK_LABEL} isVerticalBar={false} hrefLink="#"
                                   onClickHandler={this.showWorksHandler}/>;
        // console.log("[GalleryAnnotation] displayCarouselItems", this.displayItemsInCarousel());

        // if(this.props.annotationData.childPaintings) {
        //     console.log("[GalleryAnnotation]: PaintingID", this.props.annotationData.childPaintingId.length);
        //     if(this.props.annotationData.childPaintings.length > 0 && this.props.annotationData.childPaintings.length == 1) {
        //         this.setState({singleAnnotationItem: true});
        //     }
        //
        // }

        if (this.state.showChildWorks) {
            return (
                <div className="annotation-modal">
                    <Modal
                        dialogClassName={"primaryModal modal-dialog annotation-modal-classname modal-dialog-scrollable"}
                        show={this.state.showChildWorks}
                        onHide={this.hideChildWorksHandler} size={this.props.annotationModalSize} centered={true}
                        backdrop="static">
                        <Modal.Header closeButton style={{border: 'none'}}>
                        </Modal.Header>
                        <Modal.Body>

                            {/*<Carousel indicators={false}>*/}
                            {/*    {this.displayItemsInCarousel()}*/}
                            {/*</Carousel>*/}
                            {this.displayItemsInCarousel()}

                        </Modal.Body>
                    </Modal>
                </div>
            )

        } else {
            return (
                <div className="annotation-modal">
                    <Modal
                        dialogClassName={"primaryModal modal-dialog annotation-modal-classname modal-dialog-scrollable"}
                        show={this.props.showAnnotation}
                        onHide={this.props.hideGalleryAnnotation} size={this.props.annotationModalSize} centered={true}
                        backdrop="static">
                        <Modal.Header closeButton style={{border: 'none'}}>
                        </Modal.Header>
                        <Modal.Body>

                            <div>
                                <div className="annotation-main-container-modal">
                                    <div className="annotation-media-container">
                                        <div className="annotation-image-wrapper">
                                            <DisplayMediaComponentProxy annotationData={this.props.annotationData} isCarouselUsed={false}/>
                                        </div>
                                    </div>
                                    <div className="annotation-text-container">
                                        <div className="annotation-header-text-container"
                                             dangerouslySetInnerHTML={{__html: this.props.annotationData.headerText}}>
                                        </div>
                                        <div className="annotation-body-text-container">
                                            <p dangerouslySetInnerHTML={{__html: this.props.annotationData.bodyText}}></p>
                                        </div>
                                    </div>
                                    <div className="annotation-contact-container">
                                        <ContactLinksModal key="hasMediaCarouselLink" hasMediaCarouselLink={works} annotationData={this.props.annotationData}/>
                                    </div>
                                </div>
                            </div>

                        </Modal.Body>
                    </Modal>
                </div>
            )
        }

    }
}

export default GalleryAnnotation;