import React, {Component} from 'react';

import Modal from "react-bootstrap/Modal";

import {thisIsMobile} from "../../client-data/GlobalConstants";


class GeniallyAnnotationModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            ...this.props.annotationData,
        }

    }

    onShow = () => {
        this.setState({
            ...this.props.annotationData
        })
    }


    render() {
        console.log("GeniallyAnnotationModal render!", this.props.showModal, this.props.annotationModalSize);
        // const modalSize = thisIsMobile ? "md" : "lg";
        return (
            <Modal
                show={this.props.showModal} onShow={() => this.onShow()}
                onHide={this.props.hideAnnotationModal}
                size="lg"
                centered="true"
            >
                <Modal.Header closeButton style={{border: 'none'}}>
                </Modal.Header>
                <Modal.Body>
                    <div className="genial-container">
                        <iframe title="35-Hijiri"
                                className="genial-iframe"
                                src={this.props.annotationData.geniallyURL}
                                type="text/html"
                                allowscriptaccess="always"
                                allowFullScreen
                                allownetworking="all">

                        </iframe>
                    </div>
                </Modal.Body>

            </Modal>
        )


    }
}

// style={{display:"flex", flexWrap:"nowrap", justifyContent:"center"}}>
// width="600px"
// height="600px"

// <div className="annotation-modal">
//     <Modal
//         dialogClassName={"primaryModal modal-dialog annotation-modal-classname modal-dialog-scrollable"}
//         show={this.props.showAnnotationModal} onShow={() => this.onShow()}
//         onHide={this.props.hideAnnotationModal} size={this.props.annotationModalSize} centered={true}
//         backdrop="static">
//         <Modal.Header closeButton style={{border: 'none'}}>
//         </Modal.Header>
//         <Modal.Body>
//             <div>
//                 <div className="annotation-main-container-modal">
//
//                     <DisplayMediaComponentProxy annotationData={this.props.annotationData}  isCarouselUsed={false}/>
//
//                     <div className="annotation-text-container">
//                         <div className="annotation-header-text-container"
//                              dangerouslySetInnerHTML={{__html: this.state.headerText}}>
//                         </div>
//                         <div className="annotation-body-text-container"
//                              style={{textAlign: this.state.annotationTextAlignment,
//                                  lineHeight: this.state.annotationTextLineHeight}}
//                         >
//                             <p dangerouslySetInnerHTML={{__html: this.state.bodyText}}></p>
//                         </div>
//                     </div>
//                     <ContactLinksModal key="hasMediaCarouselLink" hasMediaCarouselLink={hasMediaCarouselLink}
//                                        annotationData={this.props.annotationData}/>
//                 </div>
//             </div>
//         </Modal.Body>
//     </Modal>
// </div>


export default GeniallyAnnotationModal;