import {Color} from "three";
import * as THREE from "three";

function generateTexture() {

    const canvas = document.createElement('canvas');
    canvas.width = 2;
    canvas.height = 2;

    const context = canvas.getContext('2d');
    context.fillStyle = 'white';
    context.fillRect(0, 1, 2, 1);

    return canvas;

}

//const texture = new THREE.CanvasTexture( generateTexture() );

export const MATERIAL_PROPS_DB = [


    {
        materialName: "GlassMat",
        glass: true,
        glassMatFunc: (envMap) => {
            return new THREE.MeshPhysicalMaterial({
                name: `GlassMat-MPM`,
                color: new Color( 0.29119921875000004, 0.5729965745192305, 0.8 ),
                emissive: new THREE.Color(0.20969663085937498, 0.21999999999999997, 0.21999999999999997),
                metalness: .01,
                roughness: 0,
                // alphaMap: texture,
                // alphaTest: 0.5,
                //envMap: envMap, //this.backgroundMap,
                envMapIntensity: 0.5,
                depthWrite: false,
                transmission: 1, // use material.transmission for glass materials
                opacity: 0.4, // set material.opacity to 1 when material.transmission is non-zero
                transparent: true,
                //ior: 1.5,
                //specularIntensity: 1,
                //specularColor: 0xffffff,
                //side: THREE.DoubleSide,
                //alphaMap: texture,
                toneMapped: true,
            });
        },
    },
    {
        materialName: "TableTopGlassMat",
        glass: true,
        glassMatFunc: (envMap) => {
            return new THREE.MeshPhysicalMaterial({
                name: `TableTopGlassMat-MPM`,
                color: new THREE.Color(0x000000),
                emissive: new THREE.Color(0, 0, 0),
                metalness: .01,
                roughness: 0.13,
                // alphaMap: texture,
                // alphaTest: 0.5,
                //envMap: envMap, //this.backgroundMap,
                envMapIntensity: 2.88,
                //depthWrite: false,
                transmission: 1, // use material.transmission for glass materials
                opacity: .34, // set material.opacity to 1 when material.transmission is non-zero
                transparent: true,
                ior: 1.5,
                specularIntensity: 1,
                specularColor: 0xffffff,
                side: THREE.DoubleSide,
                //alphaMap: texture,
            });
        },
    },


    {
        materialName: "LightMat",
        toneMapped: 'N',
    },

    {
        materialName: "WallsMat",
        aOIntensity: .8,
        envMapIntensity: 1.6,
        toneMapped: 'Y',
    },
    {
        materialName: "BustPlinths",
        aOIntensity: 1.0,
        envMapIntensity: 1.6,
        toneMapped: 'N',
    },
    {
        materialName: "HDRIMat",
        toneMapped: 'N',
    },

    {
        materialName: "CelingMat",
        aOIntensity: 1.3,
        envMapIntensity: 0.01,

    },
    {
        materialName: "FloorMat",
        aOIntensity: 1.14,
        envMapIntensity: 2.67,
        roughness:0.27,
        toneMapped: 'N'
    },
    {
        materialName: "ShoeBoxMat",
        aOIntensity: 0.66,
        envMapIntensity:1.0,
        toneMapped: 'Y',
    },
    {
        materialName: "_texture",
        toneMapped: 'N',
    },

    {
        materialName: "_texture.002",
        toneMapped: 'N',
    },
    {
        materialName: "EmmisiveWallMat",
        toneMapped: 'N',
    },

    {
        materialName: "_texture.001",
        toneMapped: 'N',
    },

   /* {
        materialName:"byronbust_thorvaldsen_murrays_mtl",
        aOIntensity:0.9823465059092082,

        envMapIntensity:0.2696702011971902,
        roughness:1,
        metalness:1,
        toneMapped:"Y",

    },
    {
        materialName:"byronbust_bartolini_ly_mtl",
        aOIntensity:1.04655195498617,

        envMapIntensity:0.4943892729665564,
        roughness:1,
        metalness:1,
        toneMapped:'Y',

    }, */

    {
        materialName:"StairsMat",
        aOIntensity:0.8539356077552847,
        envMapIntensity:5.363302494636157,
    },

    {
        materialName:"DoorBttomMat",
        aOIntensity:0.9,

        envMapIntensity:7,

    },

    {
        materialName:"MainDoorMat",
        aOIntensity:0.6613192605243994,
        envMapIntensity:6.262178781713621,

    },

    {
        materialName:"DoorHandle",
        aOIntensity:1,
        envMapIntensity:3.2659244914554058,
        toneMapped:'N',
    },

    {
        materialName:"StairCaseAndWindowFrameMat",
        aOIntensity:0.37239473967807146,
        envMapIntensity:7,
        roughness: 0.5
    },

    {
        materialName:"DoorOpenMat",
        toneMapped:'Y',

    },

    {
        materialName:"CeilingBarsMat",
        aOIntensity:0.5971138114474376,
        envMapIntensity:5.662927923661979,
    },

    {
        materialName:"byronbust_thorvaldsen_murrays_mtl",
        toneMapped:'N',

    },
    {
        materialName:"byronbust_bartolini_ly_mtl",
        toneMapped:'N',
    },
    {
        materialName:"bartolini_academia_mtl",
        toneMapped:'N',
    },
    {
        materialName:"byronbust_bartolini_npg_mtl",
        toneMapped:'N',
    },
    {
        materialName:"thorvaldsen_keats_shelley_mtl",
        toneMapped:'N',
    },

    {
        materialName:"LightBulbEmissiveMat",
        emmissiveIntensity:3.0283622398495185,
        toneMapped:'N',

    },

    {
        materialName: "wall_text_2K_01",
        toneMapped: 'N',
    },


        {
            materialName:"IMAGE_UV_01",
            toneMapped:'N',
        },







    /*
[
{
 materialName:"LightBulbEmissiveMat",
emmissiveIntensity:3.0283622398495185,
toneMapped:'N',

 },

 ]
     */


]
