import * as THREE from "three";
import {ViewerOptionsTypes} from "./types/ViewerTypes";


export class MultiViewRenderer {
    private readonly _renderer: THREE.WebGLRenderer;
    private _size: DOMRect;

    constructor(private container: HTMLElement, private modal3dOptions:ViewerOptionsTypes) {

        this._renderer = new THREE.WebGLRenderer({

            antialias: true,
            alpha: true
        })
        this._size = this.container.getBoundingClientRect();
        // console.log("mainContainer", this._size);
        this._renderer.setSize(this._size.width, this._size.height);
        this._renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
        this._renderer.shadowMap.enabled = true;
        this._renderer.shadowMap.type = THREE.PCFSoftShadowMap;
        this.physicallyCorrectLights = modal3dOptions.physicallyCorrectLights;
        this.outputEncoding = modal3dOptions.outputEncoding;
        this.toneMapping = modal3dOptions.toneMapping;
        this.toneMappingExposure = modal3dOptions.exposure;
        container.appendChild(this._renderer.domElement);
    }

    get renderer(): THREE.WebGLRenderer {
        return this._renderer;
    }

    get rendererCanvas(): HTMLCanvasElement {
        return this.renderer.domElement;
    }

    onWindowResize() {
        this._size = this.container.getBoundingClientRect();
        //console.log("mainContainer3DModal",this._size);
        this._renderer.setSize(this._size.width, this._size.height)
        this._renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
    }


    get size(): DOMRect {
        return this._size;
    }

    initViewPort(rect: DOMRect, offsetX: number, offsetY: number, leftSceneOffset: number = 1, rightSceneOffset: number = 1) {

        this._renderer.setViewport(offsetX+rightSceneOffset, offsetY, rect.width - leftSceneOffset, rect.height);
        this._renderer.setScissor(offsetX + rightSceneOffset, offsetY, rect.width - leftSceneOffset, rect.height);
    }

    dispose = () => {
        this._renderer.dispose();
        this._renderer.forceContextLoss();
    }

    set physicallyCorrectLights(val:boolean) {
        this._renderer.physicallyCorrectLights = val;
    }

    set outputEncoding(val:number) {
        this._renderer.outputEncoding = val;
    }

    set toneMapping(val:string) {

        switch (val) {
            case 'LinearToneMapping':
                // console.log("toneMapping=",val);
                this.renderer.toneMapping = THREE.LinearToneMapping;
                break;
            case 'ReinhardToneMapping':
                // console.log("toneMapping=",val);
                this.renderer.toneMapping = THREE.ReinhardToneMapping;
                break;
            case 'CineonToneMapping':
                // console.log("toneMapping=",val);
                this.renderer.toneMapping = THREE.CineonToneMapping;
                break;
            case 'ACESFilmicToneMapping':
                // console.log("toneMapping=",val);
                this.renderer.toneMapping = THREE.ACESFilmicToneMapping;
                break;
            default:
                // console.log("toneMapping=",val);
                this.renderer.toneMapping = THREE.NoToneMapping;
        }
    }

    set toneMappingExposure(val:number) {
        this.renderer.toneMappingExposure = val;
    }
}
