import React from "react";
import {SCROLLBAR_ORIENTATION} from "../../client-data/GlobalConstants";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import VerticalThumbnailScrollBar from "../galleryslider/VerticalThumbnailScrollBar";


const THREE_D_PARTS_VIEWER = "threeDParts";
const TOOLTIP_TXT = "3d-viewer-parts";
// const TOOLTIP_TXT = `Hover over thumbnails to activate scrolling up and down.`;
const PLACEMENT_OF_TOOLTIP = "bottom";

export const ThreeDPartsViewer = (props) => {

    const updateItemIndex = (index) => {
        props.updateIndex(index);
    }

    // if(props.partsData) {
    //     console.log("[ThreeDPartsViewer]", props.partsData, props.partsData.length);
    // }

    return (
        <VerticalThumbnailScrollBar
            thumbnailSlides={props.partsData}
            thumbnailCount={props.thumbnailCount}
            prefix={THREE_D_PARTS_VIEWER}
            intemIndex={props.itemIndex}
            onClick={updateItemIndex}
            scrollbarOrientaion={SCROLLBAR_ORIENTATION.VERTICAL_SCROLLBAR}
            toolTipTxt={TOOLTIP_TXT}
            placement={PLACEMENT_OF_TOOLTIP}
        />
    )

}