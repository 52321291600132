import React, {useEffect, useState} from "react";

import {Row, Col} from "react-bootstrap";
import {ThreeDAudioPlayer} from "./ThreeDAudioPlayer";

const ThreeDInfo = (props) => {
    // console.log("ThreeDInfo ", props.textSettings);
    const textStyling = {
        textAlign: props.textSettings ? props.textSettings.textAlignment : null,
        lineHeight: props.textSettings ? props.textSettings.lineHeight : null,
        letterSpacing: props.textSettings ? props.textSettings.letterSpacing : null,
        maxHeight: props.infoHeight,
        fontSize: props.textSettings ? props.textSettings.fontSize : null,
    }

    // if(textStyling) {
    //     console.log("[ThreeDInfo/TextSettings]", textStyling.textAlign, textStyling.letterSpacing, textStyling.lineHeight);
    // }

    return (
        <>
            <Row className="gx-0">
                <div style={textStyling} className="three-d-text three-d-scrollbar three-d-in-box"
                     dangerouslySetInnerHTML={{__html: props.infoData ? props.infoData.description : null}}
                >
                </div>
            </Row>
            <Row className="gx-0 w-100">
                <ThreeDAudioPlayer audioInfo={props.infoData ? props.infoData.audioURL : null}/>
            </Row>
        </>

    )
}

export default ThreeDInfo;