import * as THREE from "three";

export type MeshButtonCallback = ((button_id: string) => void) ;

export enum HoverState {
    HOVER_OVER,
    HOVER_OUT,

}

export type ButtonMaterials = {
    playMaterial: THREE.MeshPhysicalMaterial,
    pauseMaterial: THREE.MeshPhysicalMaterial,
    transMaterial: THREE.MeshPhysicalMaterial,
}


export const PLAY_MATERIAL_COLOR = new THREE.Color(0xffffff);
export const PAUSE_MATERIAL_COLOR = new THREE.Color(0xffffff);
export const TRANS_MATERIAL_COLOR = new THREE.Color(0x00ff00);

export const VIDEO_BUTTON_Y_OFFSET = .01;

export enum MeshButtonType {
    BasicMeshButton,
    AnimatedMeshButton,

}

export type MeshButtonDBType = {
    button_id: string,
    meshButtonType: MeshButtonType,
    pauseImagePath?: string, // if populated then must be set to the pause image path, currently is used for VideoMeshButtons
    useOutliner?:boolean, // when hovering there is an outliner. default is DEFAULT_BUTTON_OUTLINER_COLOR = new THREE.Color(0xFFFFFF) in client options
    outlinerColor?:THREE.Color, //  // the size of the button outlined (extends beyond the button mesh. Default is DEFAULT_BUTTON_OUTLINER_SIZE = new THREE.Vector3(1.1,1.1,1); in client options
    outlinerSize?: THREE.Vector3

}

export type ButtonClickResultType = {
    result: Boolean,
    callBack: (() => void) | null,
}
