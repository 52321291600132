import * as THREE from "three";
import {VideoMeshButton} from "./VideoMeshButton";
import {VideoGroupMeshButton} from "./VideoGroupMeshButton";
import {ButtonClickResultType, MeshButtonType} from "./ButtonsTypes";
import {DEFAULT_VIDEO_PAUSE_IMAGE_PATH} from "../../../client-data/GlobalConstants";
import {MeshButtonsDB} from "../../../client-data/ts/MeshButtonsDB";
import {DEFAULT_MESH_BUTTON_TYPE} from "../../../client-data/clientOptions";

const TEXTURE_LOADER = new THREE.TextureLoader();

export class VideoMeshButtonContainer {

    private raycaster = new THREE.Raycaster();
    private mouseVector = new THREE.Vector3();
    private meshesObj: Record<string, VideoMeshButton> = {};
    private groupMeshesObj: Record<string, VideoGroupMeshButton> = {};
    private selectedObject: THREE.Object3D | null = null;
    private selectedVideoButtonId: string | null = null;
    private selectedVideoGroupButtonId: string | null = null;
    private selectedMeshId: number | null = null;

    constructor(private scene: THREE.Scene, private camera: THREE.Camera, private buttons: Array<THREE.Mesh>, private groupButtons: Array<THREE.Mesh>) {
        const self = this;



        buttons.forEach(btn => {

            const image = self.getPauseImage(btn.userData.videoButtonId);

            this.meshesObj[btn.userData.videoButtonId] = new VideoMeshButton(this.scene, btn,image);
        });

        groupButtons.forEach(btn => {

            const image = self.getPauseImage(btn.userData.videoGroupButtonId);
            this.groupMeshesObj[btn.userData.videoGroupButtonId] = new VideoGroupMeshButton(this.scene, btn, image);
        })

    }

    static getMeshButtonPauseImagePath = (buttonId: string): string | undefined => {

        const item = MeshButtonsDB.find(e => e.button_id === buttonId);

        if (item) {
            return item.pauseImagePath;
        }

        return DEFAULT_VIDEO_PAUSE_IMAGE_PATH;

    }

    getPauseImage(videoButtonId: string): THREE.Texture {

        const imagePath = VideoMeshButtonContainer.getMeshButtonPauseImagePath(videoButtonId);

        const image =  TEXTURE_LOADER.load( process.env.PUBLIC_URL + imagePath,undefined,undefined,(e) => {
            //no way we can use the default image because this loader is async and returns proxy  which can't be detected if null
            console.error(`Cannot find the Pause Image for video Button ${videoButtonId}. Will use default image ${DEFAULT_VIDEO_PAUSE_IMAGE_PATH}`)

        });

        image.flipY = false
        return image;

    }

    getVideoMeshButton = (videoButtonId: string) => {
        const btnRec = this.meshesObj[videoButtonId];
        if (btnRec) {
            return btnRec;
        }
        return null;

    }

    getVideoGroupMeshButton = (videoGroupButtonId: string) => {
        const btnRec = this.groupMeshesObj[videoGroupButtonId];
        if (btnRec) {
            return btnRec;
        }
        return null;

    }


    meshSelected = (videoButtonId: string, node: THREE.Object3D): void => {

        const btnRec = this.meshesObj[videoButtonId];

        if (btnRec) {
            btnRec.buttonClicked();
        }
    }

    groupMeshSelected = (videoGroupButtonId: string, node: THREE.Object3D): void => {

        const btnRec = this.groupMeshesObj[videoGroupButtonId];

        if (btnRec) {
            btnRec.buttonClicked();
        }
    }


    hoverOver = (videoButtonId: string | null, videoGroupButtonId: string | null): void => {

        if (videoButtonId) {

            const btnRec = this.getVideoMeshButton(videoButtonId)

            if (btnRec) {
                btnRec.hoverOver();
            }
        }
        else  if (videoGroupButtonId) {

            const btnRec = this.getVideoGroupMeshButton(videoGroupButtonId)

            if (btnRec) {
                btnRec.hoverOver();
            }
        }

    }

    hoverOut = (videoButtonId: string | null, videoGroupButtonId: string | null): void => {

        if (videoButtonId) {

            const btnRec = this.getVideoMeshButton(videoButtonId)

            if (btnRec) {
                btnRec.hoverOut();
            }
        }
        else  if (videoGroupButtonId) {

            const btnRec = this.getVideoGroupMeshButton(videoGroupButtonId)

            if (btnRec) {
                btnRec.hoverOut();
            }
        }
    }

    onDocumentMouseMove = (intersects: THREE.Intersection): ButtonClickResultType => {

            let res = intersects;
            const videoButtonId = res.object.userData.videoButtonId;
            const videoGroupButtonId = res.object.userData.videoGroupButtonId;
            if ((videoGroupButtonId && (videoGroupButtonId in this.groupMeshesObj)) || (videoButtonId && (videoButtonId in this.meshesObj))) {
                if (this.selectedMeshId) {
                    if (this.selectedMeshId !== res.object.id) {
                        const vId = this.selectedVideoButtonId;
                        const vgId = this.selectedVideoGroupButtonId;

                        this.selectedMeshId = res.object.id
                        this.selectedVideoButtonId = videoButtonId;
                        this.selectedVideoGroupButtonId = videoGroupButtonId;

                        return {
                            result:true,
                            callBack: () => {
                                this.hoverOut(vId, vgId);
                                this.hoverOver(videoButtonId, videoGroupButtonId);
                            }
                        }
                    }
                    else {

                        return {
                            result:true,
                            callBack: null
                        }

                    }
                } else {

                    this.selectedMeshId = res.object.id
                    this.selectedVideoButtonId = videoButtonId;
                    this.selectedVideoGroupButtonId = videoGroupButtonId;
                    return {
                        result:true,
                        callBack: () => {
                            this.hoverOver(videoButtonId, videoGroupButtonId);
                        }
                    }
                }

            } else {

                if (this.selectedMeshId) {

                    const vId = this.selectedVideoButtonId;
                    const vgId = this.selectedVideoGroupButtonId;

                    this.selectedMeshId = null;
                    this.selectedVideoButtonId = null;
                    this.selectedVideoGroupButtonId = null;

                    return {
                        result:false,
                        callBack: () => {
                            this.hoverOut(vId, vgId);
                        }
                    }
                }
                return {
                    result:false,
                    callBack: null
                };
            }


    }


    onDocumentMouseDown = (intersects: THREE.Intersection): ButtonClickResultType => {

        let res = intersects;
        const videoButtonId = res.object.userData.videoButtonId;
        const videoGroupButtonId = res.object.userData.videoGroupButtonId;

        if (videoGroupButtonId && (videoGroupButtonId in this.groupMeshesObj)) {

            this.selectedObject = res.object;
            const selObj = this.selectedObject;
            return {
                result: true,
                callBack: () => {
                    this.groupMeshSelected(videoGroupButtonId, selObj);
                }
            };

        } else if (videoButtonId && (videoButtonId in this.meshesObj)) {

            this.selectedObject = res.object;


            const selObj = this.selectedObject;
            return {
                result: true,
                callBack: () => {
                    this.meshSelected(selObj.userData.videoButtonId, selObj);
                }
            };
        }


        return {
            result: false,
            callBack: null
        }
    }

    getIntersects = (x: number, y: number): THREE.Intersection[] => {

        x = (x / window.innerWidth) * 2 - 1;
        y = -(y / window.innerHeight) * 2 + 1;

        this.mouseVector.set(x, y, 0.5);
        this.raycaster.setFromCamera(this.mouseVector, this.camera);

        return this.raycaster.intersectObjects([...this.buttons, ...this.groupButtons], true);

    }
}
