import {
    ImageIDRecordType, InfoRecordType,
    Modal3DCompareType,
    Modal3DId,
    Modal3DObjectType,
    PartsObjectType, TextAlignmentType
} from "../types/ViewerTypes";
import {Modal3DObjectsDB} from "../../../client-data/ts/Modal3DObjectsDB";
import {Modal3DCompareDB} from "../../../client-data/ts/Modal3DCompareDB";
import {LANGUAGE, THREE_D_TEXT_DEFAULT_SETTINGS} from "../../../client-data/GlobalConstants";
import {Nullable} from "../types/types";
import {exists} from "node:fs";

export function getModal3DObjectById(id: string): Modal3DObjectType | null {

    const rec = Modal3DObjectsDB[id];
    if (rec) {
        return rec;
    }
    return null;

}

export function getModal3DObjectPartById(id: string, partId: string): Nullable<PartsObjectType> {
    const obj = getModal3DObjectById(id);
    if (!obj) return null;
    const part = obj.partsOfObject[partId];
    if (part) return part;
    return null;
}

export function getModal3DCompareByIds(id1: string, id2: string): Modal3DCompareType | null {

    const recs = Modal3DCompareDB.filter((rec) => ((rec.id1 === id1 && rec.id2 === id2)) || (rec.id1 === id2 && rec.id2 === id1));

    if (recs && recs.length > 0) {
        return recs[0];
    }

    return null;
}

export function getModal3DCompareByOrderedIds(id1: string, id2: string): Modal3DCompareType | null {

    const recs = Modal3DCompareDB.filter((rec) => ((rec.id1 === id1 && rec.id2 === id2)));

    if (recs && recs.length > 0) {
        return recs[0];
    }

    return null;
}

export function getPartsObjByObjID(id: string): Record<Modal3DId, PartsObjectType> | null {
    const record = Modal3DObjectsDB[id];
    const partRecords = record["partsOfObject"]
    // const aRecord = Object.keys(Modal3DObjectsDB)
    // const aRecord = Object.values(Modal3DObjectsDB)
    // .filter(key => key === id);
    // console.log("Modal3DDBQuery - getPartsObjByObjID", record, partRecords);
    if (partRecords) {
        return partRecords;
    }
    return null;
}

export function getPartsImageIDbyID(id: string): ImageIDRecordType[] | ImageIDRecordType | null {
    const partsRecords = getPartsObjByObjID(id);
    const record: ImageIDRecordType[] = [];
    if (partsRecords) {
        for (let key in partsRecords) {
            // console.log("getPartsImageIDbyID", key, partsRecords[key].imageURL);
            const anID = key;
            const imageURL = `${process.env.PUBLIC_URL}/${partsRecords[key].imageURL}`;
            record.push({id: anID, imageURL: imageURL});
        }
        return record;
        // Object.keys(partsRecords).forEach(key => {
        //     const value = partsRecords[key];
        // })
    }

    return null;
}

export function getCompareImageIDbyID(id: string, language: number): ImageIDRecordType[] | null {
    // The record exist in the compare-db
    const objectRecord = Modal3DObjectsDB;
    const result: ImageIDRecordType[] = [];
    if (objectRecord) {
        for (let key in objectRecord) {
            // const compareRecord = getModal3DCompareByIds(id, key)
            /* The order of the compare IDs is important for getting the right title */
            const compareRecord = getModal3DCompareByOrderedIds(id, key);
            // console.log("Modal3DDBQuery - getCompareImageIDbyID", compareRecord);
            if (compareRecord) {
                const anID = key;
                const imageURL = `${process.env.PUBLIC_URL}/${objectRecord[key].imageURL}`;
                let tooltipTxt = null;
                switch (language) {
                    case LANGUAGE.EN:
                        if (compareRecord.en) {
                            tooltipTxt = compareRecord.en.tooltipTxt;
                            break;
                        }
                    case LANGUAGE.IT:
                        if (compareRecord.it) {
                            tooltipTxt = compareRecord.it.tooltipTxt;
                            break;
                        }
                }
                result.push({id: anID, imageURL: imageURL, tooltipTxt: tooltipTxt});
            }
        }
        // console.log("Modal3DDBQuery - getCompareImageIDbyID", result);
        return result;
    }
    return null;
}

export function getTitleByIDLanguage(id: string, language: number): string | null {
    const record = Modal3DObjectsDB[id];
    if (record) {
        // const englishRecord = record.en;
        switch (language) {
            case LANGUAGE.EN:
                if (record.en) {
                    return record.en.title;
                }
            case LANGUAGE.IT:
                if (record.it) {
                    return record.it.title;
                }
            default:
                return null;
        }
    }
    return null;
}

export function getEnglishTitleByID(id: string): string | null {
    const record = Modal3DObjectsDB[id];
    if (record) {
        const englishRecord = record.en;
        if (englishRecord) {
            // console.log("Modal3DDBQuery - getEnglishTitleByID", record, englishRecord.title);
            return englishRecord.title;
        }
    }
    return null;
}

export function getItalianTitleByID(id: string): string | null {
    const record = Modal3DObjectsDB[id];
    if (record) {
        const englishRecord = record.it;
        if (englishRecord) {
            // console.log("Modal3DDBQuery - getEnglishTitleByID", record, englishRecord.title);
            return englishRecord.title;
        }
    }
    return null;
}

export function getPartInfo(id: string, partID: string, language: number): InfoRecordType | null {
    const record = Modal3DObjectsDB[id];
    if (record) {
        if (partID) {
            const partRecord = record.partsOfObject[partID];
            // console.log("getPartInfo", partRecord);
            if (partRecord) {
                switch (language) {
                    case LANGUAGE.EN:
                        if (partRecord.en) {
                            const description = partRecord.en.description;
                            const audioURL = partRecord.en.audioURL ? `${process.env.PUBLIC_URL}/${partRecord.en.audioURL}` : null;
                            // console.log("getPartInfo", description, audioURL);
                            return {description: description, audioURL: audioURL};
                        }

                    case LANGUAGE.IT:
                        if (partRecord.it) {
                            const description = partRecord.it.description;
                            const audioURL = partRecord.it.audioURL ? `${process.env.PUBLIC_URL}/${partRecord.it.audioURL}` : null;
                            return {description: description, audioURL: audioURL};
                        }
                }
            }
        }
    }
    return null;
}

export function getPartsInfoArrayByLanguage(id: string, language: number): InfoRecordType[] | null {
    const record = Modal3DObjectsDB[id];
    if (record) {
        const partsRecords = record.partsOfObject;
        if (partsRecords) {
            // console.log("[Modal3DDBQuery - getPartsInfoArrayByLanguage]", partsRecords);
            const records = [];
            for (let key in partsRecords) {
                const partsRecord = partsRecords[key];
                switch (language) {
                    case LANGUAGE.EN:
                        if (partsRecord.en) {
                            const partID = key;
                            const description = partsRecord.en.description;
                            const audioURL = partsRecord.en.audioURL ? `${process.env.PUBLIC_URL}/${partsRecord.en.audioURL}` : null;
                            // console.log("getPartInfo", description, audioURL);
                            records.push({id: partID, description: description, audioURL: audioURL});
                            break;
                        }

                    case LANGUAGE.IT:
                        if (partsRecord.it) {
                            const description = partsRecord.it.description;
                            const audioURL = partsRecord.it.audioURL ? `${process.env.PUBLIC_URL}/${partsRecord.it.audioURL}` : null;
                            records.push({id: key, description: description, audioURL: audioURL});
                            break;
                        }
                }

            }
            return records;
        }
    }

    return null;
}

/* Here we respect the order of the IDs, cause the client wants to have two different texts for the two orders */
export function getCompareInfo(id1: string, id2: string, language: number): InfoRecordType | null {
    // const record = getModal3DCompareByIds(id1, id2);
    const record = getModal3DCompareByOrderedIds(id1, id2);
    // console.log("[Modal3DDBQuerygetCompareInfo/] id1/id2/record", id1, id2, record);
    if (record) {
        // console.log("[Modal3DDBQuery - getCompareInfo]", record);
        switch (language) {
            case LANGUAGE.EN:
                if (record.en) {
                    const title = record.en.title;
                    const description = record.en.description;
                    // const audioURL = record.en.audioURL ? `${process.env.PUBLIC_URL}/${record.en.audioURL}` : null;
                    record.en.audioURL = record.en.audioURL ? `${process.env.PUBLIC_URL}/${record.en.audioURL}` : null;
                    return record.en;
                }

            case LANGUAGE.IT:
                if (record.it) {
                    const title = record.it.title;
                    const description = record.it.description;
                    const audioURL = record.it.audioURL ? `${process.env.PUBLIC_URL}/${record.it.audioURL}` : null;
                    return {title: title,description: description, audioURL: audioURL};
                }
        }
    }
    return null;
}

export function getGeneralTextSettings(id: string): TextAlignmentType {
    const record = Modal3DObjectsDB[id];
    const textAlignmentRecord: TextAlignmentType = {
            textAlignment: THREE_D_TEXT_DEFAULT_SETTINGS.TXT_ALIGNMENT,
            letterSpacing: THREE_D_TEXT_DEFAULT_SETTINGS.LETTER_SPACING,
            lineHeight: THREE_D_TEXT_DEFAULT_SETTINGS.LINE_HEIGHT,
            fontSize: THREE_D_TEXT_DEFAULT_SETTINGS.FONT_SIZE,
        }
    ;
    // console.log("[Modal3DDBQuery/getGeneralTextAlignment/default] before", id, textAlignmentRecord);

    if (record) {
        if (record.textAlignment) {
            // console.log("[Modal3DDBQuery/getGeneralTextAlignment/textAlignment]: id, record, default", id, record.textAlignment, textAlignmentRecord.textAlignment);
            textAlignmentRecord.textAlignment = record.textAlignment;
        }
        if (record.lineHeight) {
            // console.log("[Modal3DDBQuery/getGeneralTextAlignment/lineHeight]: id, record, default", id, record.lineHeight, textAlignmentRecord.lineHeight);
            textAlignmentRecord.lineHeight = record.lineHeight;
        }
        if (record.letterSpacing) {
            // console.log("[Modal3DDBQuery/getGeneralTextAlignment/letterSpacing]: id, record, default", id, record.letterSpacing, textAlignmentRecord.letterSpacing);
            textAlignmentRecord.letterSpacing = record.letterSpacing;
        }
    }
    // console.log("[Modal3DDBQuery/getGeneralTextAlignment/default] after", id, textAlignmentRecord.textAlignment, textAlignmentRecord.letterSpacing, textAlignmentRecord.lineHeight);
    return textAlignmentRecord;
}

/* Get text settings from the Compare-DB, and if some or all settings are missing, they should  be taken from defaults we have  */
export function getCompareTextSettings(id1: string, id2: string): TextAlignmentType {
    const textAlignmentRecord: TextAlignmentType = {
        textAlignment: THREE_D_TEXT_DEFAULT_SETTINGS.TXT_ALIGNMENT,
        letterSpacing: THREE_D_TEXT_DEFAULT_SETTINGS.LETTER_SPACING,
        lineHeight: THREE_D_TEXT_DEFAULT_SETTINGS.LINE_HEIGHT,
        fontSize: THREE_D_TEXT_DEFAULT_SETTINGS.FONT_SIZE,
    }
    // const record = getModal3DCompareByIds(id1, id2);
    const record = getModal3DCompareByOrderedIds(id1, id2);
    // console.log("[Modal3DDBQuery/getGeneralTextAlignment/textAlignment]: id1/id2/record", id1,id2, record);
    if (record) {
        if (record.textAlignment) {
            // console.log("[Modal3DDBQuery/getGeneralTextAlignment/textAlignment]: id, record, default", id, record.textAlignment, textAlignmentRecord.textAlignment);
            textAlignmentRecord.textAlignment = record.textAlignment;
        }
        if (record.lineHeight) {
            // console.log("[Modal3DDBQuery/getGeneralTextAlignment/lineHeight]: id1/id2/lineHeight", id1, id2,record.lineHeight);
            textAlignmentRecord.lineHeight = record.lineHeight;
        }
        if (record.letterSpacing) {
            // console.log("[Modal3DDBQuery/getGeneralTextAlignment/letterSpacing]: id, record, default", id, record.letterSpacing, textAlignmentRecord.letterSpacing);
            textAlignmentRecord.letterSpacing = record.letterSpacing;
        }
        if (record.fontSize) {
            // console.log("[Modal3DDBQuery/getGeneralTextAlignment/letterSpacing]: id, record, default", id, record.letterSpacing, textAlignmentRecord.letterSpacing);
            textAlignmentRecord.letterSpacing = record.letterSpacing;
        }
    }

    // console.log("[Modal3DDBQuery/getCompareTextSettings] id1/id2/textAlignment:", id1, id2, textAlignmentRecord);
    return textAlignmentRecord;
}

export function getInitialInfoData(id: string, language: number): InfoRecordType | null {
    const record = Modal3DObjectsDB[id];
    let infoRecord = null;
    if (record) {
        // console.log("[Modal3DDBQuery - getInitialInfoData]", record);

        switch (language) {
            case LANGUAGE.EN:
                if (record.en) {
                    const description = record.en.description;
                    const audioURL = record.en.audioURL ? `${process.env.PUBLIC_URL}/${record.en.audioURL}` : null;
                    // console.log("getPartInfo", description, audioURL);
                    infoRecord = {id: id, description: description, audioURL: audioURL};
                    break;
                }

            case LANGUAGE.IT:
                if (record.it) {
                    const description = record.it.description;
                    const audioURL = record.it.audioURL ? `${process.env.PUBLIC_URL}/${record.it.audioURL}` : null;
                    infoRecord = {id: id, description: description, audioURL: audioURL};
                    break;
                }
        }
        // console.log("[Modal3DDBQuery - getInitialInfoData], infoRecord", infoRecord);
        return infoRecord;
    }
    return null;
}

