import {MultiViewProcessor} from "./MultiViewProcessor";
import * as THREE from 'three';
import {MultiViewSingleScene} from "./MultiViewSingleScene";
import {
    EnvironmentMapsLoaderDoubleResults,
    EnvironmentTextureDoubleResults, Modal3DCompareType, Modal3DObjectType,
    ModelWithId,
    ModelWithIdRecord
} from "./types/ViewerTypes";
import {MODAL3D_OPTIONS} from "../../client-data/ts/Modal3DViewOptions";
import {EnvironmentLoader} from "./loaders/EnvironmentLoader";
import {EnvironmentTextureLoader} from "./loaders/EnvironmentTextureLoader";
import {GLTFImporter} from "./loaders/GLTFImporter";
import {Nullable} from "./types/types";
import * as TWEEN from "@tweenjs/tween.js";
import {RenderingValidationSingleton} from "../Rendering/ts/RenderingValidationSingleton";
import {MaterialPropProcessor} from "./MaterialPropProcessor";
import {getModal3DObjectPartById} from "./utils/Modal3DDBQuery";
//import {Vector3} from "three";




const geometries = [
    new THREE.BoxGeometry(1, 1, 1),
    new THREE.SphereGeometry(0.5, 12, 8),
    new THREE.DodecahedronGeometry(0.5),
    new THREE.CylinderGeometry(0.5, 0.5, 1, 12)
];

const geometry = geometries[0];

// const material = new THREE.MeshBasicMaterial({
const material = new THREE.MeshStandardMaterial({

    /*color: new THREE.Color().setHSL( Math.random(), 1, 0.75 ),*/
    color: new THREE.Color(0xff0000)

});

const VEC_UP = new THREE.Vector3(0,1,0);
const VEC_ZERO = new THREE.Vector3(0,0,0);

const geometryRight = geometries[3];

const materialRight = new THREE.MeshStandardMaterial({

    /*color: new THREE.Color().setHSL( Math.random(), 1, 0.75 ),*/
    color: new THREE.Color(0xff0000)

});

export class MultiViewMain {

    private _MVProcessor: MultiViewProcessor | null = null;
    // private envMaps: EnvironmentMapsLoaderDoubleResults | null = null;
    private _envTextures: EnvironmentTextureDoubleResults | null = null;

    private _models3D: Nullable<ModelWithIdRecord> = null;

    private _currentMainModelInModal: Nullable<string> = null;
    private envMaps: EnvironmentMapsLoaderDoubleResults | null = null;

    constructor(private useGui: boolean = false) {


    }

    init3dView = () => {
        this._init3dView();

        if (this.MVProcessor) {
            if (this._envTextures) {
                this.envMaps = this.MVProcessor.createEnvMapFromTexture(this._envTextures);

                if (this.sceneRight) {
                    if (this.envMaps.colorMap) this.sceneRight.scene.environment = this.envMaps.colorMap;
                    if (this.envMaps.backgroundMap) this.sceneRight.scene.background = this.envMaps.backgroundMap;


                }

                if (this.sceneLeft) {
                    if (this.envMaps.colorMap) this.sceneLeft.scene.environment = this.envMaps.colorMap;
                    if (this.envMaps.backgroundMap) this.sceneLeft.scene.background = this.envMaps.backgroundMap;
                }


                // console.log("init3dView 2 env maps=", envMaps);
            } /*else {
                this.setEnvironmentTexture('adams_place_bridge_02k.hdr', 'adams_place_bridge_02k.hdr').then(() => {

                    if (this.envTextures) {
                        const envMaps = this.MVProcessor!.createEnvMapFromTexture(this.envTextures);

                        if (this.sceneRight) {
                            if (envMaps.colorMap) this.sceneRight.scene.environment = envMaps.colorMap;
                            if (envMaps.backgroundMap) this.sceneRight.scene.background = envMaps.backgroundMap;
                        }

                        if (this.sceneLeft) {
                            if (envMaps.colorMap) this.sceneLeft.scene.environment = envMaps.colorMap;
                            if (envMaps.backgroundMap) this.sceneLeft.scene.background = envMaps.backgroundMap;
                        }
                        console.log("init3dView 2 env maps=", envMaps);
                    }
                })
            }*/

        }
    }


    private _init3dView = () => {
        this._MVProcessor = new MultiViewProcessor('canvasContainer3DModel', 'leftContent3DModal', 'rightContent3DModal',
            'slider3DModal', "threeDViewer", "container3dModal", "modal3dViewBody", MODAL3D_OPTIONS, this.useGui);

        const mainContainer = this._MVProcessor.mainContainer;
        const contentLeft = this._MVProcessor.contentLeft;
        const contentRight = this._MVProcessor.contentRight;
        const slider = this._MVProcessor.sliderElement;

        const multiViewRenderer = this._MVProcessor.multiViewRenderer;

        const sceneLeft = this._MVProcessor.sceneLeft;
        sceneLeft.scene.background = new THREE.Color(0xEAE5E2);
        this._MVProcessor.initCameraLeft()
        sceneLeft.sceneCamera!.camera.position.z = .85;
       // sceneLeft.scene.add(new THREE.Mesh(geometry, material));
        sceneLeft.onWindowResize();

        const sceneRight = this._MVProcessor.sceneRight;

        sceneRight.scene.background = new THREE.Color(0xEAE5E2);
        this._MVProcessor.initCameraRight();
        sceneRight.sceneCamera!.camera.position.z = 1.5;
        this._MVProcessor.sceneRight.orbitControls!.autoRotate = true;
        //this._MVProcessor.sceneRight.orbitControls!.screenSpacePanning  = false;
        // sceneRight.sceneCamera!.camera.position.y = -0.3;
        // sceneRight.sceneCamera!.camera.position.y = 0.2;
       // sceneRight.scene.add(new THREE.Mesh(geometryRight, materialRight));
        sceneRight.onWindowResize();

        this._MVProcessor.renderer.setScissorTest(true);

        this._MVProcessor.setSingleView();
        // this._MVProcessor.setDoubleView();

        this._MVProcessor.animate();
        this._MVProcessor.alwaysRender = false;
    }

    addModelsToSceneRight = (rec: Modal3DObjectType, id: string)=> {
        // console.log("addModelsToSceneRight=", rec)
        if (this.models3D) {
            if (this.sceneRight) {
                this.currentMainModelInModal = id;
                let theMesh = this.models3D[rec.modelName] as THREE.Mesh;
                theMesh.receiveShadow = true;
                theMesh.castShadow = true;
                this.sceneRight.scene.add(theMesh);
                this.MVProcessor!.sceneRight.sceneCamera!.camera.position.copy(rec.position);
                this.MVProcessor!.sceneRight.orbitControls?.update();
                const mat = this.getMaterial(theMesh.material);
              //  const light = this.addSpotLight(this.sceneRight.scene, theMesh);
                if (this.envMaps) {
                    const materialPropProcessor = new MaterialPropProcessor(this.sceneRight.scene, this.envMaps.colorMap, MODAL3D_OPTIONS.textureEncoding, MODAL3D_OPTIONS.envMapIntensity);
                }
                if (this.MVProcessor?.datGui) {
                    const materialFolderMain = this.MVProcessor?.datGui.addFolder(`Materials`);
                    // @ts-ignore
                    materialFolderMain.addMaterial(`${mat.name}-${mat.id}`,mat,null);

                    // @ts-ignore
                   // this.MVProcessor?.datGui.addLight("Spot Light",light);


                }

                RenderingValidationSingleton.getInstance().invalidateOnce();
            }
        }
    }



    addModelsToSceneLeft = (rec: Modal3DObjectType)=> {

        if (this.models3D) {
            if (this.sceneLeft) {
                this.sceneLeft.scene.clear();
                this.sceneLeft.scene.add(this.models3D[rec.modelName] as THREE.Mesh);
                this.MVProcessor!.sceneLeft.sceneCamera!.camera.position.copy(rec.position);
                this.MVProcessor!.sceneLeft.orbitControls?.update();
                if (this.envMaps) {
                    const materialPropProcessor = new MaterialPropProcessor(this.sceneLeft.scene, this.envMaps.colorMap, MODAL3D_OPTIONS.textureEncoding, MODAL3D_OPTIONS.envMapIntensity);
                }
                RenderingValidationSingleton.getInstance().invalidateOnce();
            }
        }
    }

    addSpotLight = (scene: THREE.Scene, target: THREE.Object3D): THREE.Light => {
        const light = new THREE.SpotLight(0xFFFF00,8);
        light.position.set( 2, 1, 0 );
        light.castShadow = true;
        light.angle = 1.57;
        light.penumbra = 1;
        light.decay = 0.5;
        light.distance = 5;
        light.shadow.mapSize.width = 2048
        light.shadow.mapSize.height = 2048
        light.shadow.camera.near = 0.5
        light.shadow.camera.far = 10;
        light.target = target;

        scene.add(light)

        const helper = new THREE.SpotLightHelper(light, 0x0000FF)
        // const helper = new THREE.CameraHelper(light.shadow.camera)
        scene.add(helper);
        return light;
    }

    clearSceneLeft =()=> {
        if (this.sceneLeft) {
            this.sceneLeft.scene.clear();
        }
    }

    dispose = () => {
        this.currentMainModelInModal = null;
        this._MVProcessor?.dispose();
        this._MVProcessor = null;
    }

    onWindowResize = () => {
        this._MVProcessor?.onWindowResize();
    }


    get MVProcessor(): MultiViewProcessor | null {
        return this._MVProcessor;
    }

    setSingleView = () => {

        this._MVProcessor?.setSingleView();
        this._MVProcessor?.onWindowResize();
        //  this._MVProcessor.animate();
    }

    setDoubleView = () => {

        this._MVProcessor?.setDoubleView();
        this._MVProcessor?.onWindowResize();
        this.sliderBackgroundColor = "000000";
        this.sliderOpacity = .1;
        // this._MVProcessor.animate();
    }

    protected getMaterial = (material: THREE.Material | Array<THREE.Material>): THREE.Material => {
        let objMat;
        if (Array.isArray(material)) {
            objMat = material[0]
        } else {
            objMat = material;
        }
        return objMat;

    }

    async setEnvironment(envColorImage: string, backgroundImage: string) {
        if (this.MVProcessor) {
            const envMaps = await this.MVProcessor.getEnvironmentMaps(envColorImage, backgroundImage);

            console.log("env maps=", envMaps);
        }
    }

    async setEnvironmentTexture(envColorImage: string, backgroundImage: string) {

            this._envTextures = await this.getEnvironmentTextures(envColorImage, backgroundImage);

            console.log("env maps=", this._envTextures);

    }

    async getEnvironmentTextures(envColorImage: string, backgroundImage: string): Promise<EnvironmentTextureDoubleResults> {
        // return  await new Promise<void>((resolve, reject) => {
        const envLoader = new EnvironmentTextureLoader();
        const envData = EnvironmentLoader.getEnvironmentData(envColorImage, backgroundImage);
        if (!envData.colorMap) {
            throw new Error(`Cannot find Color Texture HDR ${envColorImage}`)
        }

        if (!envData.backgroundMap) {
            throw new Error(`Cannot find Background Texture HDR ${backgroundImage}`)
        }
        const envTexture = await envLoader.getEnvironmentTexture(envData)

        return envTexture

    }

    async loadModel(path:string): Promise<ModelWithIdRecord> {
        const gltfModel = await GLTFImporter.loadScene(path);
        const models: ModelWithIdRecord = {};
        gltfModel.children.forEach(model => {
            if (model.userData.model_id ) {
                models[model.userData.model_id] = model;
            }
        })

       return models
    }

    set sliderBackgroundColor(value: string) {
        if (this.MVProcessor) {
            this.MVProcessor.slider.backgroundColor = value;
        }
    }

    set sliderOpacity(value: number) {
        console.log("set opacity pre")
        if (this.MVProcessor) {
            console.log("set opacity post")
            this.MVProcessor.slider.opacity = value;
        }
    }

    get sceneRight(): MultiViewSingleScene | null {
        if (this.MVProcessor) return this.MVProcessor.sceneRight;
        return null;
    }

    get sceneLeft(): MultiViewSingleScene | null {
        if (this.MVProcessor) return this.MVProcessor.sceneLeft;
        return null;
    }


    get envTextures(): EnvironmentTextureDoubleResults | null {
        return this._envTextures;
    }

    set envTextures(value: EnvironmentTextureDoubleResults | null) {
        this._envTextures = value;
    }


    get models3D(): Nullable<ModelWithIdRecord> {
        return this._models3D;
    }

    set models3D(value: Nullable<ModelWithIdRecord>) {
        console.log("****************set 3dModel", value)
        this._models3D = value;
    }


    get currentMainModelInModal(): Nullable<string> {
        return this._currentMainModelInModal;
    }

    set currentMainModelInModal(value: Nullable<string>) {
        this._currentMainModelInModal = value;
    }

    moveRightCameraTest() {
        if (this.MVProcessor) {
            // this.MVProcessor.sceneRight.sceneCamera!.camera.setRotationFromEuler(new THREE.Euler(0,0,0));
            this.MVProcessor.sceneRight.orbitControls?.reset();
            this.MVProcessor.sceneRight.sceneCamera!.camera.position.z = .70;
            this.MVProcessor.sceneRight.sceneCamera!.camera.position.x = 0;
            this.MVProcessor.sceneRight.sceneCamera!.camera.lookAt(0,0,0);
            this.MVProcessor.sceneRight.orbitControls?.update();
            this.MVProcessor.sceneRightChange();
            this.MVProcessor.sceneRightEnd();

        }

    }
    moveRightCameraTest2() {
        if (this.MVProcessor) {
            // this.MVProcessor.sceneRight.sceneCamera!.camera.setRotationFromEuler(new THREE.Euler(0,0,0));
            this.MVProcessor.sceneRight.orbitControls?.reset();
            this.MVProcessor.sceneRight.sceneCamera!.camera.position.z = -.70;
            this.MVProcessor.sceneRight.sceneCamera!.camera.position.x = 0;
            this.MVProcessor.sceneRight.sceneCamera!.camera.lookAt(0,0,0);
            this.MVProcessor.sceneRight.orbitControls?.update();
            this.MVProcessor.sceneRightChange();
            this.MVProcessor.sceneRightEnd();

        }

    }

    resetRightCameraPos(rec: Modal3DObjectType) {
        if (this.MVProcessor) {
            // this.MVProcessor.sceneRight.sceneCamera!.camera.setRotationFromEuler(new THREE.Euler(0,0,0));
            this.MVProcessor.sceneRight.orbitControls!.autoRotate = false;
            this.MVProcessor.sceneRightChange();
            this.MVProcessor.sceneRight.orbitControls!.target = new THREE.Vector3();
            this.MVProcessor.sceneRight.orbitControls?.update();
            //this.MVProcessor.sceneRight.sceneCamera!.camera.position.set(0,0,1.5);
            //this.MVProcessor.sceneRight.orbitControls?.update();

            //this.MVProcessor.sceneRightEnd();
            const self = this;
            this.moveCameraToTargetAndRotate(this.MVProcessor.sceneRight.sceneCamera!.camera, rec.position, new THREE.Euler(), 2000, () => {
                if (self.MVProcessor) {
                    this.MVProcessor!.sceneRight.orbitControls!.enabled = true;
                    self.MVProcessor.sceneRight.orbitControls?.update();
                    self.MVProcessor.sceneRightEnd();
                }
            })
        }
    }

    moveRightCameraTestById(id: number) {
        if (this.MVProcessor) {
            // this.MVProcessor.sceneRight.sceneCamera!.camera.setRotationFromEuler(new THREE.Euler(0,0,0));
            this.MVProcessor.sceneRight.orbitControls?.reset();
            this.MVProcessor.sceneRight.sceneCamera!.camera.position.z = 0.70 * Math.cos(id * Math.PI/4);
            this.MVProcessor.sceneRight.sceneCamera!.camera.position.x =  0.70 * Math.sin(id * Math.PI/4);
            this.MVProcessor.sceneRight.sceneCamera!.camera.lookAt(0,0,0);
            this.MVProcessor.sceneRight.orbitControls?.update();
            this.MVProcessor.sceneRightChange();
            this.MVProcessor.sceneRightEnd();

        }

    }

    moveRightCameraTestById2(id: number) {
        const self = this;
        if (this.MVProcessor) {
            // this.MVProcessor.sceneRight.sceneCamera!.camera.setRotationFromEuler(new THREE.Euler(0,0,0));
            this.MVProcessor.sceneRight.orbitControls?.reset();
            const posZ = 0.70 * Math.cos(id * Math.PI/4);
            const posX =  0.70 * Math.sin(id * Math.PI/4);
            const posY = this.MVProcessor.sceneRight.sceneCamera!.camera.position.y;
            this.MVProcessor.sceneRightChange();
            this.moveCameraToTarget(this.MVProcessor.sceneRight.sceneCamera!.camera, new THREE.Vector3(posX, posY, posZ), VEC_ZERO,2000, ()=> {
                if ( self.MVProcessor) {
                    self.MVProcessor.sceneRight.orbitControls?.update();
                    self.MVProcessor.sceneRightEnd();
                }
            })


        }

    }

    moveRightCameraTestById3(partId: string) {
        if (this.currentMainModelInModal) {
            const partObj = getModal3DObjectPartById(this.currentMainModelInModal, partId);
            if (partObj) {
                const self = this;
                if (this.MVProcessor) {
                    this.MVProcessor.sceneRight.orbitControls!.autoRotate = false;
                    this.MVProcessor.sceneRight.orbitControls!.target = partObj.orbitTarget;
                    this.MVProcessor.sceneRight.orbitControls?.update();
                    this.MVProcessor.sceneRightChange();
                    this.moveCameraToTargetAndRotate(this.MVProcessor.sceneRight.sceneCamera!.camera, partObj.position, partObj.rotation, 2000, () => {
                        if (self.MVProcessor) {
                            this.MVProcessor!.sceneRight.orbitControls!.enabled = true;
                            self.MVProcessor.sceneRight.orbitControls?.update();
                            self.MVProcessor.sceneRightEnd();
                        }
                    })


                }
            }
        }

    }



    moveCameraToTarget = (camera: THREE.Camera,toPos: THREE.Vector3, lookAt = VEC_ZERO, duration = 1000, onCompleteCB?: (() => void)): (TWEEN.Tween<{t: number}>) | null => {

        const matrix4 = camera.matrix.clone();
        matrix4.lookAt(toPos, lookAt, VEC_UP);
        const quaternion = new THREE.Quaternion().setFromRotationMatrix(matrix4);

            let time = {t: 0};
            const tween = new TWEEN.Tween(time)
                .to({t: 1}, duration)
                // .easing(TWEEN.Easing.Cubic.InOut)
                // .easing(TWEEN.Easing.Exponential.In)
                .easing(TWEEN.Easing.Quintic.In)
                .onUpdate(() => {
                    const newVec = camera.position.lerp(toPos,time.t);
                   // camera.quaternion.slerp(quaternion, time.t);
                    //camera.lookAt(lookAt);
                    if ( this.MVProcessor) {
                        this.MVProcessor.sceneRight.orbitControls?.update();
                    }
                })
                .onComplete(() => {
                    camera.lookAt(lookAt);
                    if (onCompleteCB) {
                        onCompleteCB();
                    }
                })
                .start()
            return tween;

    }

    moveCameraToTargetAndRotate = (camera: THREE.Camera,toPos: THREE.Vector3, rotation: THREE.Euler, duration = 1000, onCompleteCB?: (() => void)): (TWEEN.Tween<{t: number}>) | null => {

       /* const matrix4 = camera.matrix.clone();
        matrix4.lookAt(toPos, lookAt, VEC_UP);
        const quaternion = new THREE.Quaternion().setFromRotationMatrix(matrix4);*/

       /* const localPos = camera.worldToLocal(toPos);
        console.log("moveCameraToTargetAndRotate localPoas=", localPos.clone())*/

        let time = {t: 0};
        const tween = new TWEEN.Tween(time)
            .to({t: 1}, duration)
            // .easing(TWEEN.Easing.Cubic.InOut)
            // .easing(TWEEN.Easing.Exponential.In)
            .easing(TWEEN.Easing.Quintic.In)
            .onUpdate(() => {
                const newVec = camera.position.lerp(toPos,time.t);
                // camera.quaternion.slerp(quaternion, time.t);
                //camera.lookAt(lookAt);
                if ( this.MVProcessor) {
                    this.MVProcessor.sceneRight.orbitControls?.update();
                }
            })
            .onComplete(() => {
                camera.setRotationFromEuler(rotation);
                if ( this.MVProcessor) {
                    //this.MVProcessor.sceneRight.orbitControls!.target = target;
                    //this.MVProcessor.sceneRight.orbitControls?.update();
                }
                if (onCompleteCB) {
                    onCompleteCB();
                }
            })
            .start()
        return tween;

    }

    get singleView(): boolean {
        return this.MVProcessor!.singleView
    }
}
