import * as THREE from 'three';

export class Path {

    private _currentIndex = 0;
    private arrayLength: number = 0;
    private _path: Array<THREE.Vector3> = []
    private _totalPathLength: number  = 0;
    private pathLengths: Array<number> = [];

    constructor() {

    }


    set path(value: Array<THREE.Vector3>) {
        //console.log("set Path=", value);
        this._path = value;
        this._currentIndex = 0;
        this.arrayLength = this._path.length;
        this._totalPathLength = 0;
        this.pathLengths.length = 0;
        if (this.arrayLength > 1) {
            for(let inx = 0; inx <= this.arrayLength - 2; inx++) {
                const length =  this.path[inx].distanceTo(this.path[inx+1]);
                this._totalPathLength += length;
                this.pathLengths.push(length);
            }
        }
    }

    next():THREE.Vector3 | null {
        //assume that the first entry in the array path is the start position of the camera
        this._currentIndex++;
        if (this._currentIndex <= this.arrayLength - 1) {

            const previousLength = this.pathLengths.shift();
            if (previousLength) {
                this._totalPathLength -= previousLength;
            }
            return this.path[this._currentIndex];
        }


        return null;
    }

    get path(): Array<THREE.Vector3> {
        return this._path;
    }

   lengthToWayPoint(playerPos: THREE.Vector3): number | null {
       if (this._currentIndex <= this.arrayLength - 1) {
           return playerPos.distanceTo(this.path[this.currentIndex]);
       }

        return null;
    }

    lengthFromPosToEnd(playerPos: THREE.Vector3): number  {

        const lenToNextWaypoint = this.lengthToWayPoint(playerPos);
        if (lenToNextWaypoint) {
            return lenToNextWaypoint + this.totalPathLength;
        }
        return this.totalPathLength;

    }

    get isLastWayPoint():boolean {

        return this.currentIndex === this.arrayLength -1;
    }

    get wayPointsCount(): number {
        return this.arrayLength;
    }


    get currentIndex(): number {
        return this._currentIndex;
    }

    get totalPathLength(): number {
        return this._totalPathLength;
    }
}
