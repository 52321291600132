import React from "react";

export const CanvasViewer = (props) => {

    const onClickHandler = () => {
        console.log("[CanvasViewer] clicked!");
        props.onClick();
    }

    return (
        <div id="container3dModal" onClick={onClickHandler} >
            <div id="canvasContainer3DModel" className="inner1">
                {/* Put here your Canvas directly */}
            </div>
            <div id="leftContent3DModal" className="cls_leftContent3DModal">
                Left Div
            </div>
            <div id="rightContent3DModal" className="cls_rightContent3DModal">
                Right Div
            </div>
        </div>
    )

}