import React, {useEffect, useRef, useState} from "react";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import ThumbnailImages from "./ThumbnailImages";
import DisplayTooltip from "../../client-data/DisplayTooltip";
import {USE_THREE_D_VIEWER_BORDER_BOX} from "../../client-data/clientOptions";

const VerticalThumbnailScrollBar = (props) => {
    const aRef = useRef(null);
    const gradientElement = useRef(0);
    const containerElement = useRef(0);
    const [isBigScreenSize, setIsBigScreenSize] = useState(false);


    const onClick = (index) => {
        // console.log("HorizontalThumbnailScrollBar - index:", index);
        props.onClick(index);
    }

    useEffect(() => {
        // gradientElement.current.style.width = `${containerElement.current.clientWidth - containerElement.current.clientWidth * 7/100}px`;
        // console.log("VerticalThumbnailScrollBar", containerElement.current.offsetWidth, containerElement.current.clientWidth, gradientElement.current.clientWidth);
        // console.log("[VerticalThumbnailScrollBar]");
        // console.log("[VerticalThumbnailScrollBar] window-length", window.innerWidth );
        setIsBigScreenSize(window.innerWidth >= 2000 ? true : false);
    }, []);

    return (
            <div ref={containerElement}
                 className={USE_THREE_D_VIEWER_BORDER_BOX ? "vertical-thmbnl-slider-container three-d-in-box" : "vertical-thmbnl-slider-container"}>
                <div ref={gradientElement} className="three-d-vertical-gradient-bottom"></div>
                {/*<div style={(props.thumbnailCount <= 6) && !isBigScreenSize ? {alignContent: 'center'} : {}} className="thumbnail-vertical-slider three-d-scrollbar"*/}
                <div
                    // onMouseEnter={() => {setShowTooltip(true)}}
                    style={(props.thumbnailCount <= 6) && !isBigScreenSize && USE_THREE_D_VIEWER_BORDER_BOX ? {alignContent: 'center'} : {}} className="thumbnail-vertical-slider three-d-scrollbar"
                     id="vertical-thmbnl-slider">
                    <ThumbnailImages thumbnailImages={props.thumbnailSlides}
                                     prefix={props.prefix}
                                     itemIndex={props.intemIndex}
                                     onClick={onClick}
                                     showTooltip={false}
                    />
                </div>
            </div>
    )
}

export default VerticalThumbnailScrollBar;