import React, {useEffect, useState} from "react";
import {SCROLLBAR_ORIENTATION} from "../../client-data/GlobalConstants";
import {HorizontalThumbnailScrollBar} from "../galleryslider/HorizontalThumbnailScrollBar";

const thumbnailSlides = [
    '/paintings/compare_01.png', '/paintings/compare_02.png',
    '/paintings/compare_01.png', '/paintings/compare_02.png',
    '/paintings/compare_01.png', '/paintings/compare_02.png'
];

const THREE_D_TWO_OBJECTS_VIEWER = "threeDTwoObj";
const TOOLTIP_TXT = "3d-viewer-objects";
// const TOOLTIP_TXT = `Hover over thumbnails to activate scrolling left and right.`;
const PLACEMENT_OF_TOOLTIP = "top";
export const ThreeDTwoObjectsViewer = (props) => {

    const onClick = (id) => {
        // console.log("[ThreeDTwoObjectsViewer] is clicked!", id);
        props.updateIndex(id);
    }

    return (
        <HorizontalThumbnailScrollBar thumbnailSlides={props.compareData}
                                      stylingClass={"horizontal-thmbnl-slider-container"}
                                      prefix={THREE_D_TWO_OBJECTS_VIEWER}
                                      itemIndex={props.itemIndex}
                                      onClick={onClick}
                                      scrollbarOrientaion={SCROLLBAR_ORIENTATION.HORIZONTAL_SCROLLBAR}
                                      toolTipTxt={TOOLTIP_TXT}
                                      placement={PLACEMENT_OF_TOOLTIP}
        />
    )
}