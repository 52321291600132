import {INavMeshContainer} from "./INavMeshContainer";
import * as THREE  from "three";
import {INavMeshPlayer} from "./INavMeshPlayer";
import {CompletePlayerCallBack, UpdatePlayerCallBack} from "./NavMeshUtils";
import * as YUKA from 'yuka';
import {createYukaNavMesh} from "../pathfinding/YukaUTIL";
import {YukaNavMeshPlayer} from "./YukaNavMeshPlayer";

export class YukaNavMeshContainer extends INavMeshContainer{


    public yukaNavmesh: YUKA.NavMesh

    constructor(private scene: THREE.Scene, theMesh:THREE.Mesh, loadedNavMesh: YUKA.NavMesh) {
        super( theMesh);

        // this.yukaNavmesh = createYukaNavMesh(theMesh.geometry);
        this.yukaNavmesh = loadedNavMesh;
    }

    createPlayer(player: THREE.Object3D, onInitCallBack: UpdatePlayerCallBack, onStartPlayerCallBack: UpdatePlayerCallBack, onUpdatePlayerCallBack: UpdatePlayerCallBack, onCompletePlayerCallBack: CompletePlayerCallBack): INavMeshPlayer {
        return new YukaNavMeshPlayer(this,player,this.yukaNavmesh,this._navMesh.position,onInitCallBack,onStartPlayerCallBack,onUpdatePlayerCallBack,onCompletePlayerCallBack);
    }

}
