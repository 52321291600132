import * as THREE from "three";
import {createFireParticles} from "./FireSystem";
import {createSmokeParticles} from "./SmokeSystem";
import {createWaterParticles} from "./CreateWaterParticle";
import {ParticleSystem} from "./ParticleSystem";
import {PARTICLES_LAYER_CHANEL} from "../../../client-data/GlobalConstants";

export class ParticleSysRenderer {


  /*  private particleSysFire: ParticleSystem;
    private particleSysSmoke: ParticleSystem;
    private particleSysWater: ParticleSystem;*/
    private _particles: Record<string, ParticleSystem> = {};

    constructor(private scene: THREE.Scene, private camera: THREE.Camera) {

       /* this.particleSysFire = createFireParticles(scene, camera);

        this.particleSysSmoke = createSmokeParticles(scene, camera);

        this.particleSysWater = createWaterParticles(scene, camera);*/

      /*  this.particleSysFire.pause = true;
        this.particleSysSmoke.pause = true;
        this.particleSysWater.pause = true;*/

       /* this.addParticle("Fire", this.particleSysFire);
        this.addParticle("Smoke", this.particleSysSmoke);
        this.addParticle("Water", this.particleSysWater);*/
    }

    render(t: number): boolean {
       let isRunning = false;

        for (const key in this.particles) {
            this.particles[key].step(t);
            isRunning = isRunning || this.particles[key].isRunning();
        }
        return isRunning;
    }

    addParticle(name:string, particle: ParticleSystem) {
        this.particles[name]= particle;
        particle.points.layers.set(PARTICLES_LAYER_CHANEL);
        this.camera.layers.enable(PARTICLES_LAYER_CHANEL);
    }

    get particles(): Record<string, ParticleSystem> {
        return this._particles;
    }
}
