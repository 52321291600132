import { manageColor } from '../helpers/manageDefines';
import {RenderingValidationSingleton} from "../../Rendering/ts/RenderingValidationSingleton";

const defines = [
  ['angle', 0, Math.PI ],
  ['color', 'color'],
  ['decay', 0, 10],
  ['distance', 0, 15],
  ['groundColor', 'color'],
  ['intensity', 0, 500],
  ['penumbra', 0, 1],
  ['power', 0, 10 * Math.PI],
  ['width', 0, 50],
  ['height', 0, 50],

];

/**
 * Add a gui controller to a light.
 * @param {string} name
 * @param {THREE.Light} light
 * @todo castShadow, target
 * @returns {GUI} Returns the folder created for the light
 */
export const addLight = function(name, light) {
  const folder = this.addFolder(name);
  folder.addObject3D(null, light, { inner: true });

  defines.forEach(parameter => {
    if (!light.hasOwnProperty(parameter[0])) return;
    if (parameter[1] === 'color') {
      manageColor(light, folder, parameter);
    } else {
      folder.add(light, parameter[0], parameter[1], parameter[2])
          .onChange((e) => {

        RenderingValidationSingleton.getInstance().invalidateOnce();

      });;
    }
  });

  return folder;
};
