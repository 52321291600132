import React, {useState} from "react";
import ListOfVideosLists from "./ts/ListOfVideosLists";
import VideosDB from "../../client-data/ts/VideosDB";
import {thisIsMobile} from "../../client-data/GlobalConstants";
import VideosDisplayerComponent from "./VideosDisplayerComponent";
import VideoFullScreenModal from "./VideoFullScreenModal";


export default function VideosListFactoryComponent(props) {

    // console.log("VideosListFactoryComponent is called!");

    const [showFlag, setShowFlag] = useState(true);
    const [showVideoFullScreenModal, setShowVideoFullScreenModal] = useState(false);
    const [currentVideoData, setCurrentVideoData] = useState(null);
    const [constructorHasRun, setConstructorHasRun] = useState(false);
    const [videosData, setVideosData] = useState(null);

    function constructor() {
        if (constructorHasRun) {
            return;
        }
        // console.log("[VideosListFactoryComponent]: constructor code is running!");
        setConstructorHasRun(true);
        let videosMeshBtnObj = null;
        const videosListWithMeshButtonIDs = new ListOfVideosLists(VideosDB);
        if (videosListWithMeshButtonIDs) {
            videosMeshBtnObj = videosListWithMeshButtonIDs.getVideosListByMeshBtnId(props.meshBtnId);
            if (videosMeshBtnObj) {
                // console.log("[VideosListFactoryComponent]", videosMeshBtnObj.videosList);
                setVideosData(videosMeshBtnObj.videosList);

            }
        }
    }

    const hideVideoDisplayModal = () => {
        setShowFlag(false);
        props.hideVideoDisplayModal();
    }

    const onClickVideoThumbnail = (videoDataObj) => {
        if (thisIsMobile) {
            // console.log("[VideosListFactoryComponent] not mobile");
            setShowFlag(false);
            setShowVideoFullScreenModal(true);
            setCurrentVideoData(videoDataObj);
        } else {
            setShowFlag(false);
            setShowVideoFullScreenModal(false);
            setCurrentVideoData(null);
        }
        props.onClickThumbnail(videoDataObj, props.meshBtnId);
    }


    const onHideVideoFullScreenModal = () => {
        // console.log("[VideosListFactoryComponent is closing!")
        setShowFlag(false);
        setShowVideoFullScreenModal(false);
        props.hideVideoFullScreen();
    }

    const videoFullScreenComponent = <VideoFullScreenModal show={showVideoFullScreenModal}
                                                           onHideModal={onHideVideoFullScreenModal}
                                                           videoData={currentVideoData}
                                                           modelType="lg"
    />

    constructor();

    return (
        <div>
            {videoFullScreenComponent}

            <VideosDisplayerComponent show={showFlag}
                                      close={hideVideoDisplayModal}
                                      videosData={videosData}
                                      modelType="md"
                                      onClickThumbnail={onClickVideoThumbnail}/>

        </div>
    )
}