import {EnvironmentMapsType} from "../../components/Viewer3DModal/types/ViewerTypes";


export const environmentData: Record<string, EnvironmentMapsType> = {
    'none': {
        name: 'None',
        path: '',
        format: 'none',
        images: []
    },

    'white_cliff_top_02k.hdr': {
        name: 'white_cliff_top_02k.hdr',
        path: '/assets/environment/white_cliff_top_02k.hdr',
        format: 'hdr',
        images: []
    },

    'greenwich_park_03_02k.hdr': {
        name: 'greenwich_park_03_02k.hdr',
        path: '/assets/environment/greenwich_park_03_02k.hdr',
        format: 'hdr',
        images: []
    },

    'venice_sunset_4k.hdr': {
        name: 'venice_sunset_4k.hdr',
        path: '/assets/environment/venice_sunset_4k.hdr',
        format: 'hdr',
        images: []
    },

    'hansaplatz_02k.hdr': {
        name: 'hansaplatz_02k.hdr',
        path: '/assets/environment/hansaplatz_02k.hdr',
        format: 'hdr',
        images: []
    },

    'vignaioli_night_02k.hdr': {
        name: 'vignaioli_night_02k.hdr',
        path: '/assets/environment/vignaioli_night_02k.hdr',
        format: 'hdr',
        images: []
    },

    'adams_place_bridge_02k.hdr': {
        name: 'adams_place_bridge_02k.hdr',
        path: '/assets/environment/adams_place_bridge_02k.hdr',
        format: 'hdr',
        images: []
    },

    'kiara_4_mid-morning_04k.hdr': {
        name: 'kiara_4_mid-morning_04k.hdr',
        path: '/assets/environment/kiara_4_mid-morning_04k.hdr',
        format: 'hdr',
        images: []
    },

    'rainbow_1K_X1000_02.hdr': {
        name: 'rainbow_1K_X1000_02.hdr',
        path: '/assets/environment/wave_grey_white_1K_X1000_08.hdr',
        format: 'hdr',
        images: []
    },

    'canary_wharf': {
        name: 'canary_wharf',
        path: '/assets/environment/canary_wharf/',
        format: 'cubemap',
        images: ['px.png', 'nx.png', 'py.png', 'ny.png', 'pz.png', 'nz.png'],
    },

    'photoStudio': {
        name: 'photoStudio',
        path: '/assets/environment/photoStudio/',
        format: 'cubemap',
        images: ['px.png', 'nx.png', 'py.png', 'ny.png', 'pz.png', 'nz.png'],
    },

    'canary_wharf_4k.hdr': {
        name: 'Houser Wirth (HDR)',
        path: '/assets/environment/canary_wharf_4k.hdr',
        format: 'hdr',
        images: []
    },

    'spiaggia_di_mondello_4k': {
        name: 'spiaggia_di_mondello_4k',
        path: '/assets/environment/spiaggia_di_mondello_4k/',
        format: 'cubemap',
        images: ['px.png', 'nx.png', 'py.png', 'ny.png', 'pz.png', 'nz.png'],
    },


    /*{
      id: 'surgery_4k.hdr',
      name: 'surgery_4k.hdr',
      path: '/assets/environment/surgery_4k.hdr',
      format: 'hdr'
    },*/


};
