import * as THREE from "three";
import {ColliderX} from "./ColliderX";
import {ColliderTriggerBase} from "./ColliderTriggerBase";
import {COLLIDER_STATUS, ColliderIntersectPointType} from "./ColliderTypes";

const PLAYER_HEIGHT = new THREE.Vector3(0,1,0);

export class ColliderTriggerContainer {

    private colliderXRec: Record<string, ColliderX> = {};
    private colliderTriggerRec: Record<string, ColliderTriggerBase> = {};
    private playerCollider: ColliderX;
    private _vec = new THREE.Vector3();

    constructor(
        private scene: THREE.Scene,
        private player: THREE.Object3D,
        private colliders: Array<THREE.Object3D>,
        private showWireframe = false,
        private _playerScale = new THREE.Vector3(1,1,1)
    ) {

        const playerObject3d = new THREE.Object3D();
        playerObject3d.userData.colliderX_id = "CameraColliderX";
        playerObject3d.scale.copy(this._playerScale);
       // playerObject3d.position.copy(this.player.position);
        playerObject3d.parent = this.player;
        this.playerCollider = new ColliderX(playerObject3d, showWireframe);

        colliders.forEach((obj) => {
            this.colliderXRec[obj.userData.colliderX_id] = new ColliderX(obj, this.showWireframe);
            if (this.showWireframe) {
                this.scene.add(new THREE.Box3Helper( this.colliderXRec[obj.userData.colliderX_id].updatedBox3, new THREE.Color(0xFFFF00)));
            }
        })

    }

    public execute = () => {
        this.updatePlayerCollider();
        //console.log("ColliderTriggerContainer execute")
           for (const key in this.colliderTriggerRec) {
               const collRes = this.colliderTriggerRec[key].execute(this.playerCollider);
           }
    }

    checkIntersectPoint(point: THREE.Vector3): ColliderIntersectPointType | null{

        for (const key in this.colliderTriggerRec) {
            const collRes = this.colliderTriggerRec[key].collideWithPoint(point);
            //console.log("checkIntersectPoint=", point.clone(),collRes);
            if (collRes === COLLIDER_STATUS.intersected)  return {colliderTrigger:this.colliderTriggerRec[key], point: point};
        }

        return null;
    }

    collideWithPathAtAllPoints(path: Array<THREE.Vector3>, interval: number = 0.5): Array<ColliderIntersectPointType> {

        path.forEach(p => p.add(PLAYER_HEIGHT));
        const pathLen = path.length - 2;
        let res: Array<ColliderIntersectPointType> = [];
        for (let index=0; index <= pathLen; index++) {
            res = [...res,...this.collideWithLineAtAllPoints(path[index], path[index+1], interval)];
        }

        return res;

    }

    collideWithLineAtAllPoints(start: THREE.Vector3, end: THREE.Vector3, interval: number = 0.5): Array<ColliderIntersectPointType>  {

        const list: Array<ColliderIntersectPointType> = [];

        const line = new THREE.Line3(start, end);
        const lineDistance = line.distance();

        if (lineDistance === 0) {
            const res = this.checkIntersectPoint(start);
            if (res) list.push(res);
            return  list;
        }
        /* else if (lineDistance < 1.0) {
             const colRes = this.checkIntersectPoint(start);
             if (colRes) {
                 return colRes;
             }
             return this.checkIntersectPoint(end);

         }*/
        else {
            const dx = interval/line.distance();
            // const colRes:  ColliderTriggerBase | null;
            const colRes = this.checkIntersectPoint(start);
            if (colRes) {
                list.push(colRes);
            }
            let t = 0;

            while (t < 1.0) {
                t += dx;
                if (t >= 1.0) {
                    const colRes = this.checkIntersectPoint(end);
                    if (colRes) list.push(colRes);
                    return list ;
                }

                const colRes = this.checkIntersectPoint(line.at(t, this._vec));
                if (colRes)  list.push(colRes);

            }
        }

        return list;



    }


    collideWithLineAtFirstPoint(start: THREE.Vector3, end: THREE.Vector3, interval: number = 0.5): ColliderIntersectPointType | null {

        const line = new THREE.Line3(start, end);
        const lineDistance = line.distance();

        if (lineDistance === 0) {

            return  this.checkIntersectPoint(start);
        }
       /* else if (lineDistance < 1.0) {
            const colRes = this.checkIntersectPoint(start);
            if (colRes) {
                return colRes;
            }
            return this.checkIntersectPoint(end);

        }*/
        else {
            const dx = interval/line.distance();
            // const colRes:  ColliderTriggerBase | null;
            const colRes = this.checkIntersectPoint(start);
            if (colRes) {
                return colRes;
            }
            let t = 0;

            while (t < 1.0) {
                t += dx;
                if (t >= 1.0) {
                    return this.checkIntersectPoint(end);
                }

                const colRes = this.checkIntersectPoint(line.at(t, this._vec));
                if (colRes) return colRes;

            }
        }

        return null;



    }

    public add(ct: ColliderTriggerBase): void {

        this.colliderTriggerRec[ct.colliderName] = ct;

    }

    public getColliderXById(id:string): ColliderX | null {
        if (id in this.colliderXRec) {
            return this.colliderXRec[id];
        }

        return null;
    }


    get playerScale(): THREE.Vector3 {
        return this._playerScale;
    }

    set playerScale(value: THREE.Vector3) {
        this._playerScale = value;
        this.updatePlayerCollider();
    }

    protected updatePlayerCollider() {
        this.playerCollider.updatedBox3.setFromCenterAndSize(this.player.position,  this._playerScale);
    }
}
