import * as THREE from "three";
import {INavMeshContainer} from "./INavMeshContainer";
import {INavMeshPlayer} from "./INavMeshPlayer";
import {CompletePlayerCallBack, MeshTransform, UpdatePlayerCallBack} from "./NavMeshUtils";
import * as YUKA from "yuka"
import {threeVector3ToYuka, yukaArrayVec3ToThree, yukaVector3ToThree} from "../pathfinding/YukaUTIL";
import * as TWEEN from "@tweenjs/tween.js";
import {Tween} from "@tweenjs/tween.js";

const METERS_PER_SECOND = 2.0 * 100;

export class YukaNavMeshPlayer extends INavMeshPlayer {

   private currentRegion: YUKA.Polygon | null = null;

    constructor(protected readonly _navMeshContainer: INavMeshContainer,
                player: THREE.Object3D,
                private pathFinder: YUKA.NavMesh,
                navMeshPos: THREE.Vector3,
                onInitCallBack: UpdatePlayerCallBack = null,
                onStartPlayerCallBack: UpdatePlayerCallBack = null,
                onUpdatePlayerCallBack: UpdatePlayerCallBack = null,
                onCompletePlayerCallBack: CompletePlayerCallBack = null) {

        super(player, navMeshPos, onInitCallBack, onStartPlayerCallBack, onUpdatePlayerCallBack, onCompletePlayerCallBack);
        this.player.updateMatrixWorld();

    }


    updateGroup(newPos: THREE.Vector3): void {
       // throw new Error("Method not implemented.");
        return ;
    }

    updateGroupIDAtPlayerPos(origin: THREE.Vector3): number | null {
        throw new Error("Method not implemented.");
    }

    clampStep(playerPos: THREE.Vector3, targetPos: THREE.Vector3, updateGroup: boolean): THREE.Vector3 | null {


        // const newPos = new YUKA.Vector3();
        const newPos = threeVector3ToYuka(targetPos);
        //const currentRegion = this.pathFinder.getClosestRegion(newPos);
        try {
            this.currentRegion = this.pathFinder.clampMovement(this.currentRegion!, threeVector3ToYuka(playerPos), threeVector3ToYuka(targetPos), newPos);
        }
        catch (e: any){
            console.log("clampStep exception raised-", e.message);
            return null;
        }


        //console.log("Yuka clampStep=", newPos);

        if (this.currentRegion) {
            const distance = this.currentRegion.distanceToPoint(newPos);
            // newPos.y -= distance * 0.2;
            newPos.y -= distance;
        }

        else  {
            console.log("Yuka clampStep no region found =", this.currentRegion);
            return null;
        }

        return yukaVector3ToThree(newPos);

    }

    navigateToTargetPos(targetPos: MeshTransform, updateGroup: boolean, bypassTargetIntersectionCheck: boolean, delay: number): boolean {

        console.log("Yuka navigateToTargetPos");

        let pointOfTargetIntersection: THREE.Vector3;

        if (bypassTargetIntersectionCheck) {
            pointOfTargetIntersection = targetPos.position;
        }
        else {

            const navMeshTargetIntersection = this.rayTraceNaveMesh(targetPos.position);
            if (navMeshTargetIntersection.length > 0) {
                pointOfTargetIntersection = navMeshTargetIntersection[0].point;
            }
            else {
                console.log("NavMeshPlayer.navigateToTargetPos can't find Target point of intersection with the Navmesh - ",targetPos.position);
                return false;
            }
        }


        this.player.updateMatrixWorld(true);
        this.playerPosition = this.player.position.clone();

        let navMeshPlayerIntersection = this.rayTraceNaveMesh(this.playerPosition);
        if (navMeshPlayerIntersection.length > 0) {

        }
        else {
            console.log("NavMeshPlayer.navigateToTargetPos can't find Player point of intersection with the Navmesh - ",this.playerPosition);
            return false;
        }

        targetPos.position.y = pointOfTargetIntersection.y;

        const pointOfPlayerIntersection = navMeshPlayerIntersection[0].point;
        this.playerPosition.y = pointOfPlayerIntersection.y;
        // console.log("playerPos=",this.playerPosition);
        //console.log("targetPos=",targetPos);

        if (updateGroup) {
            /*this.groupID = this.pathFinder.getGroup(this.zoneName, this.playerPosition);
            console.log("navigateToTargetPos GroupId=",this.groupID);*/
            this.updateGroup(targetPos.position);
        }

            //this.path = this.pathFinder.findPath(this.playerPosition, targetPos.position, this.zoneName, this.groupID);

        const yukaPath = this.pathFinder.findPath(threeVector3ToYuka(this.playerPosition), threeVector3ToYuka(targetPos.position));
        if (yukaPath && yukaPath.length > 0) {
            this.path  = yukaArrayVec3ToThree(yukaPath);

        }
        else {
            console.error("Path not found");
            return false;
        }

            if (this.path && this.path.length > 0) {
                this.path = this.removeDuplicateFromPath(this.path);
                const pathLength = this.path.length;
                let pathExist = true;
                // targetPos.position.copy(this.path[0]);
                if (this.onInitCallBack) {
                    this.onInitCallBack(pointOfPlayerIntersection,0,pathLength, this.path[0].clone(),0, targetPos);
                }

                if (pathLength === 1) {
                    const distance = this.playerPosition.distanceTo(targetPos.position);
                    const targetPathPos = this.path[0].clone();
                    targetPos.position.copy(targetPathPos);
                    new TWEEN.Tween(this.playerPosition).to({
                        x:this.path[0].x,
                        y:this.path[0].y,
                        z:this.path[0].z,
                    },distance * METERS_PER_SECOND)
                        .onStart(pos => {
                            if (this.onStartPlayerCallBack) {
                                this.onStartPlayerCallBack(targetPos.position,0,1,targetPathPos, distance, targetPos);
                            }
                        })
                        .onUpdate(pos => {
                            if (this.onUpdatePlayerCallBack) {
                                this.onUpdatePlayerCallBack(pos,0,1,targetPathPos, distance, targetPos);
                            }
                        })
                        .onComplete(() => {
                            pathExist = false;
                            if (this.onCompletePlayerCallBack) {
                                this.onCompletePlayerCallBack(targetPos, distance);
                            }
                        })
                        .delay(delay).start();

                }
                else {
                    const tweens: Array<Tween<THREE.Vector3>> = new Array<Tween<THREE.Vector3>>(this.path.length);
                    let prevPos = this.playerPosition;


                    for (let index = 0; index < pathLength; index++) {
                        const targetPathPos = this.path[index].clone();
                        const distance = prevPos.distanceTo(targetPathPos);
                        prevPos = targetPathPos;
                        targetPos.position.copy(targetPathPos);
                        if (index < (this.path.length - 1)) {
                            tweens[index] = new TWEEN.Tween(this.playerPosition).to({
                                x: this.path[index].x,
                                y: this.path[index].y,
                                z: this.path[index].z,
                            }, distance * METERS_PER_SECOND)
                                .onStart(pos => {
                                    if (this.onStartPlayerCallBack) {
                                        this.onStartPlayerCallBack(targetPathPos,index,pathLength,targetPathPos,distance, targetPos);
                                    }
                                })
                                .onUpdate(pos => {
                                    if (this.onUpdatePlayerCallBack) {
                                        this.onUpdatePlayerCallBack(pos,index,pathLength,targetPathPos,distance, targetPos);
                                    }
                                })

                        }
                        else {
                            tweens[index] = new TWEEN.Tween(this.playerPosition).to({
                                x: this.path[index].x,
                                y: this.path[index].y,
                                z: this.path[index].z,
                            }, distance * METERS_PER_SECOND)
                                .onStart(pos => {
                                    if (this.onStartPlayerCallBack) {
                                        this.onStartPlayerCallBack(targetPathPos,index,pathLength,targetPathPos,distance,targetPos);
                                    }
                                })
                                .onUpdate(pos => {
                                    if (this.onUpdatePlayerCallBack) {
                                        this.onUpdatePlayerCallBack(pos,index,pathLength,targetPathPos,distance, targetPos);
                                    }
                                })
                                .onComplete(() => {
                                    pathExist = false;
                                    if (this.onCompletePlayerCallBack) {
                                        this.onCompletePlayerCallBack(targetPos, distance);
                                    }
                                })

                        }

                    }
                    for (let index = 0; index < this.path.length -1; index++) {
                        tweens[index].chain(tweens[index+1]);
                    }
                    tweens[0].delay(delay).start();
                }
            }
            else {
                console.error("Path not found");
                return false;
            }



        return true;
    }

    getPathForTargetPos(targetPos: MeshTransform, updateGroup: boolean, bypassTargetIntersectionCheck: boolean): THREE.Vector3[] | null {

        const targetPosClone = targetPos.position.clone();

        console.log("Yuka getPathForTargetPos");

        let thePath: Array<THREE.Vector3> | null = null;

        let pointOfTargetIntersection: THREE.Vector3;

        if (bypassTargetIntersectionCheck) {
            pointOfTargetIntersection =  targetPosClone;
        } else {


            const navMeshTargetIntersection = this.rayTraceNaveMesh(targetPosClone);
            if (navMeshTargetIntersection.length > 0) {
                pointOfTargetIntersection = navMeshTargetIntersection[0].point;
            } else  {
                const clampedPos = this.clampStep(targetPosClone, targetPosClone, true);
                console.log("clampedpos=",clampedPos);
                if (clampedPos) {
                    pointOfTargetIntersection = clampedPos;
                }
                else {
                    console.log("NavMeshPlayer.getPathForTargetPos can't find Target point of intersection with the Navmesh - ", targetPosClone);
                    return thePath;
                }

            }
        }

        this.player.updateMatrixWorld(true);
        this.playerPosition = this.player.position.clone();

        let navMeshPlayerIntersection = this.rayTraceNaveMesh(this.playerPosition);
        if (navMeshPlayerIntersection.length > 0) {

        } else {
            console.log("NavMeshPlayer.getPathForTargetPos can't find Player point of intersection with the Navmesh - ", this.playerPosition);
            return thePath;
        }

        targetPosClone.y = pointOfTargetIntersection.y;

        const pointOfPlayerIntersection = navMeshPlayerIntersection[0].point;
        this.playerPosition.y = pointOfPlayerIntersection.y;

        const yukaPath = this.pathFinder.findPath(threeVector3ToYuka(this.playerPosition), threeVector3ToYuka(targetPosClone));
        if (yukaPath && yukaPath.length > 0) {
            thePath = yukaArrayVec3ToThree(yukaPath);
            //console.log("Yuka path begin- ", this.playerPosition.clone());
            //console.log("Yuka path array- ",thePath);
        }
        else {
            console.error("getPathForTargetPos Path not found");
            return thePath;
        }

        if (thePath && thePath.length > 0) {
            thePath = this.removeDuplicateFromPath(thePath);
            return thePath;

        } else {
            console.error("getPathForTargetPos Path not found");
            return thePath;
        }


    }

    update = ():void => {
        TWEEN.update();
    }



    get navMeshContainer(): INavMeshContainer {
        return this._navMeshContainer;
    }

}
