import * as THREE from "three";
import {RenderingValidationSingleton} from "../../Rendering/ts/RenderingValidationSingleton";
import {COLLIDER_STATUS} from "./ColliderTypes";


/*enum UPDATE_MODE  {
    vertices,
    transform,
    positionScaleOnly,
    none,
};*/



enum COLLIDER_CREATION_HINT {
    accurate,
    fast,
}

export class ColliderX {


    public static idNumber = 0;
    public readonly id;
    public readonly updatedBox3 = new THREE.Box3();
    public readonly colliderName:string;
    private _colliderStatus = COLLIDER_STATUS.not_intersected;


    constructor(private object3d: THREE.Object3D,private _showWireframe = false) {
        this.id = ColliderX.idNumber++;
        if (this.object3d.userData.colliderX_id) {
            this.colliderName = this.object3d.userData.colliderX_id;
        }
        else {
            this.colliderName = this.object3d.type //assume it is the camera
        }
        this.updatedBox3.setFromObject(this.object3d);

        //this.showWireframe = _showWireframe;

        const theMesh = <THREE.Mesh>this.object3d;
        if (theMesh.isMesh) {
            theMesh.geometry.dispose();
            const parent = theMesh.parent;
            parent?.remove(theMesh);
            const materials = Array.isArray(theMesh.material) ? theMesh.material : [theMesh.material];
            materials.forEach((mat) => {
                /*// @ts-ignore
                mat.wireframe = true;*/
                mat.dispose();
            })
        }

        // console.log("ColliderX created-", this.colliderName, object3d.position);

        // console.log("Colliderx -", this.colliderName, this.id, this.updatedBox3);

    }

    get colliderStatus(): COLLIDER_STATUS {
        return this._colliderStatus;
    }

    set colliderStatus(value: COLLIDER_STATUS) {
        this._colliderStatus = value;
    }

    collideWith	= (otherCollider: ColliderX): boolean => {
        return  this.updatedBox3.intersectsBox(otherCollider.updatedBox3);

    }

    collideWithPoint(point: THREE.Vector3): boolean {
        return this.updatedBox3.containsPoint(point);
    }


    set showWireframe(value: boolean) {
        this._showWireframe = value;

       // RenderingValidationSingleton.getInstance().invalidateOnce();
    }


    get showWireframe(): boolean {
        return this._showWireframe;
    }
}
