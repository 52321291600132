import {MeshButtonDBType, MeshButtonType} from "../../components/annotations/ts/ButtonsTypes";
import * as THREE from "three";

export const  MeshButtonsDB: Array<MeshButtonDBType> = [
  {
        button_id: '01',
        meshButtonType: MeshButtonType.BasicMeshButton,
        useOutliner: false,
        // outlinerColor: new THREE.Color(0x0000FF),
        outlinerColor: new THREE.Color(0xFFFFFF),
    },
    {
        button_id: '02',
        meshButtonType: MeshButtonType.BasicMeshButton,
        useOutliner: false,
        // outlinerColor: new THREE.Color(0x0000FF),
        outlinerColor: new THREE.Color(0xFFFFFF),
    },
    {
        button_id: '03',
        meshButtonType: MeshButtonType.BasicMeshButton,
        useOutliner: false,
        // outlinerColor: new THREE.Color(0x0000FF),
        outlinerColor: new THREE.Color(0xFFFFFF),
    },
    {
        button_id: '04',
        meshButtonType: MeshButtonType.BasicMeshButton,
        useOutliner: false,
        // outlinerColor: new THREE.Color(0x0000FF),
        outlinerColor: new THREE.Color(0xFFFFFF),
    },
    {
        button_id: '05',
        meshButtonType: MeshButtonType.BasicMeshButton,
        useOutliner: false,
        // outlinerColor: new THREE.Color(0x0000FF),
        outlinerColor: new THREE.Color(0xFFFFFF),
    },
    {
        button_id: '01_back',
        meshButtonType: MeshButtonType.BasicMeshButton,
        useOutliner: false,
        // outlinerColor: new THREE.Color(0x0000FF),
        outlinerColor: new THREE.Color(0xFFFFFF),
    },
    {
        button_id: '02_back',
        meshButtonType: MeshButtonType.BasicMeshButton,
        useOutliner: false,
        // outlinerColor: new THREE.Color(0x0000FF),
        outlinerColor: new THREE.Color(0xFFFFFF),
    },
    {
        button_id: '03_back',
        meshButtonType: MeshButtonType.BasicMeshButton,
        useOutliner: false,
        // outlinerColor: new THREE.Color(0x0000FF),
        outlinerColor: new THREE.Color(0xFFFFFF),
    },
    {
        button_id: '04_back',
        meshButtonType: MeshButtonType.BasicMeshButton,
        useOutliner: false,
        // outlinerColor: new THREE.Color(0x0000FF),
        outlinerColor: new THREE.Color(0xFFFFFF),
    },
    {
        button_id: '05_back',
        meshButtonType: MeshButtonType.BasicMeshButton,
        useOutliner: false,
        // outlinerColor: new THREE.Color(0x0000FF),
        outlinerColor: new THREE.Color(0xFFFFFF),
    },
]
