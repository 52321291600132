import ExternalLinkRecord from "./ExternalLinkRecord";
// @ts-ignore
import {ExternalLinksDB} from "../../../client-data/ExternalLinksDB";

export default class ExternalLinksDataController {

    private records: ExternalLinkRecord[] = [];

    constructor() {
        // console.log("[ExternalLinksDataController] is created!");

        // @ts-ignore
        for(let record of ExternalLinksDB) {
                this.records.push(new ExternalLinkRecord(record));
            }
    }


    public getExternalLinksData(): ExternalLinkRecord[] {
        return this.records;
    }


    public getExternalLinkById(id:string): any {

        const index = this.records.findIndex((item) => {
            return item.linkId === id;
        });

        if (index !== -1 ) {
            return  this.records[index];
        }
        return null;
    }


};
