import React, {Component} from "react";
import Modal from "react-bootstrap/Modal";
import {EXTERNAL_LINK_LIST_HEADER} from "../../client-data/clientOptions";
import ExternalLinkComponent from "./ExternalLinkComponent";


export default class ListDisplayerComponent extends Component {
    constructor(props) {
        super(props);
        this.hideModal = this.hideModal.bind(this);
        this.state = {
            noOfLinks: this.props.externalLinksData.length,
        }
    }

    hideModal = (evt) => {
        this.props.close(evt);
    }

    render() {
        // console.log("[ListDisplayComponent] ", this.props.modelType);
        return (
            <Modal dialogClassName={"primaryModal modal-dialog modal-dialog-centered modal-dialog-scrollable"}
                   show={this.props.show}
                   onHide={this.hideModal} size={this.props.modelType} backdrop="static"
                   className={"bsh-modal-color"} >
                <Modal.Header closeButton style={{border: 'none'}}>
                    <Modal.Title>{EXTERNAL_LINK_LIST_HEADER}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="zone_links">
                        {this.props.externalLinksData.map((externalLinkObj, index) =>
                                <ExternalLinkComponent
                                    key={`external_link_${index.toString()}`}
                                    externalLinkData={externalLinkObj}
                                    index={index}
                                    noOfLink={this.state.noOfLinks}
                                />
                        )}
                    </div>
                </Modal.Body>
            </Modal>
        )
    }
}