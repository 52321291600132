import React from "react";
import VideoComponent from "../util/VideoComponent";

const VideosComponent = (props) => {
    return (
        <div>
            {/*Videos*/}


        </div>
    )
}

export default VideosComponent;
